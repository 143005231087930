import moment from "moment";

import axiosInstance from "../config/axios/AxiosInstance";
import {
  ACTIVE_INACTIVE_USER,
  ADD_A_BRANCH,
  ADD_OR_EDIT_KYC_OR_ATTACHMENTS,
  AFFILIATE_ATTACHMENT_DELETE,
  AFFILIATE_BRANCH_LIST,
  AFFILIATE_ON_BOARD_INVITE,
  AFFILIATE_TOKEN_LIST,
  CHANGE_COMMISSION_CONFIGURATION,
  DELETE_A_BRANCH,
  EDIT_A_BRANCH,
  EDIT_COMMISSION_PARAMS,
  GET_ADDITIONAL_ATTACHMENT_DETAILS,
  GET_AFFILIATE_COMMISSION_CONFIG,
  GET_AFFILIATE_GENEARTEDLINKS,
  GET_AFFILIATE_UNIVERSITY_GENEARTEDLINKS,
  GET_AFFLIATE_BASIC_DETAILS,
  GET_COMMISSION_PARAMS,
  GET_KYC_DETAILS,
  GET_ONLY_AFFILIATE_COMMISSION_CONFIG,
  GET_PAYMENT_DETAILS_DASHBOARD,
  GET_REMARK,
  GET_TOKEN_DETAILS_DASHBOARD,
  GET_TRANSACTION_DATA_DASHBOARD,
  getSessionId,
  REQUEST_FOR_COMMISSION_APPROVE,
  REQUEST_FOR_COMMISSION_CONFIG,
  REQUEST_FOR_COMMISSION_REJECT,
  REQUEST_FOR_COMMISSION_VERIFICATION,
  SEND_REMARK,
  STUDENT_DASHBOARD_COUNT,
} from "../constants/end-points";

export const requestForAffiliateTokenList = async ({ affiliate_id, token_status, skip, limit }) => {
  try {
    const res = await axiosInstance.post(AFFILIATE_TOKEN_LIST({ skip, limit }), {
      affiliate_id,
      token_status,
    });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAffiliateBasicDetails = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_AFFLIATE_BASIC_DETAILS({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForEditAndSaveAffiliateBasicDetails = async ({ affiliate_id, body }) => {
  try {
    const bodyFormat = {
      name: body.name,
      email: body.email,
      mobile: body.contactNo,
      mcafo_portal_status: body.MCAFO,
      type: body.type,
      mode: body.mode,
      currency: body.currencyType,
      commission_type: body.commissionMode,
      commission_structure_type: "fixed",
      affiliate_subvention_load: 1,
      effective_from: "",
      is_epf: body.EPF,
      is_msme: body.MSME,
      is_esic: body.ESIC,
      sales_owner: body.owner,
      legal_name: body.legal_name,
      commission_calculation_date: body.commission_calculation_date,
    };
    const res = await axiosInstance.patch(GET_AFFLIATE_BASIC_DETAILS({ id: affiliate_id }), bodyFormat);
    return { ...res.data };
  } catch (err) {
    return err;
  }
};

export const requestForAffiliateBranchList = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(AFFILIATE_BRANCH_LIST({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForCommissionConfiguration = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.post(REQUEST_FOR_COMMISSION_CONFIG({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAffiliateKycList = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_KYC_DETAILS({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAffiliateAdditionalAttachmentList = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_ADDITIONAL_ATTACHMENT_DETAILS({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAddingAbranch = async ({ affiliate_id, data }) => {
  try {
    const body = {
      affiliate_id: affiliate_id,
      name: data.state.name,
      mobile: data.state.mobile,
      address: data.state.address,
      country: data.state.country,
      state: data.state.state,
      city: data.state.city,
      pincode: data.state.pincode,
      gst_number: data.state.gst_number ? data.state.gst_number : null,
      email: data.state.email,
      sec_mobile: data.state.sec_mobile,
    };
    const res = await axiosInstance.post(ADD_A_BRANCH({ id: affiliate_id }), body);
    return { ...res.data };
  } catch (err) {
    return err;
  }
};
export const requestForEditingAbranch = async ({ affiliate_code, data }) => {
  try {
    const body = {
      code: affiliate_code,
      name: data.state.name,
      mobile: data.state.mobile,
      address: data.state.address,
      country: data.state.country,
      state: data.state.state,
      city: data.state.city,
      pincode: data.state.pincode,
      gst_number: data.state.gst_applicable == 1 ? data.state.gst_number : null,
      email: data.state.email,
      gst_applicable: data.state.gst_applicable,
      sec_mobile: data.state.sec_mobile,
    };
    const res = await axiosInstance.patch(EDIT_A_BRANCH({ code: affiliate_code }), body);
    return { ...res.data };
  } catch (err) {
    return err;
  }
};

export const requestForAffiliateBranchDelete = async ({ code }) => {
  try {
    const res = await axiosInstance.delete(DELETE_A_BRANCH({ code: code }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAddingKycOrAttachments = async ({ attachments, branch_ref }) => {
  try {
    //   {
    //     "id":"dsad",
    //     "type":"adhaar",
    //     "affiliate_id":40,
    //     "reference_id":"324dda3333",
    //     "holder_name":"name test",
    //     "attachment_url":"http://aws.com/bucket/gif.git"
    //  }
    const body_arrangment = [
      ...attachments.map((data) => {
        let newdata = {};
        newdata.id = data?.attachment_id || "never-ever-an-id";
        newdata.type = data?.type;
        newdata.affiliate_id = data?.affiliate_id;
        newdata.holder_name = data?.holder_name;
        //newdata.reference_id = "ref[0]"
        if (data?.reference_id) {
          newdata.reference_id = data?.reference_id;
        } else {
          const ref = branch_ref.map((b) => {
            if (b.branch_type == data.affiliate_branch_type) {
              return b.code;
            }
          });
          const filteredRef = ref.filter((item) => item !== null && item !== undefined);
          newdata.reference_id = filteredRef[0] || "unknown";
        }
        newdata.attachment_url = data?.attachment_url;
        newdata.attachement_no = data?.attachement_no ? data?.attachement_no : null;
        // if (data?.start_date) {
        newdata.start_date = data?.start_date ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss") : null;
        // }
        // if (data?.end_date) {
        newdata.end_date = data?.end_date ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss") : null;
        // }
        return newdata;
      }),
    ];

    const body = {
      attachments: body_arrangment,
    };
    const res = await axiosInstance.patch(ADD_OR_EDIT_KYC_OR_ATTACHMENTS, body);
    return { ...res.data };
  } catch (err) {
    return err;
  }
};

export const requestForAffiliateAttachmentDelete = async ({ affiliate_id, attachment_id }) => {
  try {
    const res = await axiosInstance.post(AFFILIATE_ATTACHMENT_DELETE, {
      affiliate_id: affiliate_id,
      attachement_id: attachment_id,
    });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForCommissionParams = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_COMMISSION_PARAMS({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForCommissionParamsEdit = async ({ affiliate_id, params }) => {
  const body = {
    commission_id: params.id,
    commission_type: params.commission_type,
    currency: params.currency,
    affiliate_subvention_load: params.affiliate_subvention_load,
    affiliate_discount_load: params.affiliate_discount_load,
    vendor_id: params.vendor_id,
    net_suite_id: params.net_suite_id,
  };
  try {
    const res = await axiosInstance.patch(EDIT_COMMISSION_PARAMS({ id: affiliate_id }), body);
    if (res.status === 200) {
      return { ...res.data };
    }
  } catch (err) {
    return false;
  }
};

export const requestForGeneartedLinks = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_AFFILIATE_GENEARTEDLINKS({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForUniversityGeneartedLinks = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.post(GET_AFFILIATE_UNIVERSITY_GENEARTEDLINKS({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForGetAffiliateCommssionConfig = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_AFFILIATE_COMMISSION_CONFIG({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};
export const requestForGetOnlyAffiliateCommssionConfig = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.get(GET_ONLY_AFFILIATE_COMMISSION_CONFIG({ id: affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAffiliateChangingCommmissionConfig = async ({ data }) => {
  try {
    const res = await axiosInstance.post(CHANGE_COMMISSION_CONFIGURATION, data);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForCommissionVerification = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.put(
      REQUEST_FOR_COMMISSION_VERIFICATION({
        id: affiliate_id,
      })
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForCommissionApprove = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.put(
      REQUEST_FOR_COMMISSION_APPROVE({
        id: affiliate_id,
        affiliate_commission_status: "approved",
      })
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForCommissionReject = async ({ affiliate_id, remark }) => {
  try {
    let body = {
      comment: remark,
    };
    const res = await axiosInstance.put(
      REQUEST_FOR_COMMISSION_REJECT({
        id: affiliate_id,
        affiliate_commission_status: "rejected",
      }),
      body
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForActiveInactiveAffiliate = async ({ affiliate_id, active, is_expiry, expiry_date }) => {
  try {
    const dataToSend = {
      affiliate_id,
      active,
      is_expiry,
      expiry_date,
    };
    if (moment().format('YYYY-MM-DD') == moment(dataToSend.expiry_date).format('YYYY-MM-DD')) {
      dataToSend.is_expiry = 0
    }
    const res = await axiosInstance.put(
      ACTIVE_INACTIVE_USER({
        id: affiliate_id,
      }),
      dataToSend
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAffiliateInvite = async ({ affiliate_id }) => {
  try {
    const res = await axiosInstance.post(
      AFFILIATE_ON_BOARD_INVITE({
        id: affiliate_id,
      })
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const getAllRemarkApi = async ({ affiliate_id, type }) => {
  try {
    let body = {
      affiliate_id: affiliate_id,
      type: type,
    };
    const res = await axiosInstance.post(GET_REMARK, body);
    return res.data.allRemarks;
  } catch (error) {
    return false;
  }
};

export const getStudentDashboardCount = async ({ session }) => {
  try {
    const res = await axiosInstance.get(STUDENT_DASHBOARD_COUNT({ session: session }));
    return { ...res?.data };
  } catch (error) {
    return false;
  }
};
export const getSessionIds = async () => {
  try {
    const res = await axiosInstance.get(getSessionId);
    return { ...res?.data };
  } catch (error) {
    return false;
  }
};

export const getDashboardTransactionList = async ({ step, admission_session, skip, limit, search_key }) => {
  try {
    const res = await axiosInstance.get(GET_TRANSACTION_DATA_DASHBOARD({ step, admission_session, skip, limit, search_key }));
    return { ...res?.data };
  } catch (error) {
    return false;
  }
};

export const getDashboardTokenList = async ({ token_status, dashboard_token_status, session, skip, limit, search_key }) => {
  try {
    const res = await axiosInstance.get(GET_TOKEN_DETAILS_DASHBOARD({ token_status, dashboard_token_status, session, skip, limit, search_key }));
    return { ...res?.data };
  } catch (error) {
    return false;
  }
};

export const getDashboardPaymentList = async ({ admission_session, search_key, skip, limit }) => {
  try {
    const res = await axiosInstance.get(GET_PAYMENT_DETAILS_DASHBOARD({ admission_session, search_key, skip, limit }));
    return { ...res?.data };
  } catch (error) {
    return false;
  }
};
