import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

import SidePanel from "./SidePanel";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxSalesTeamList } from "../../store/apps/sales/SalesEmployeesSlice";
const SalesEmpDataFilter = ({
  designationList,
  isSidePanelOpen,
  toggleSidePanel,
  setSelectedDesignation,
  selectedDesignation,
  selectedTeam,
  setSelectedTeam,
  handleApplyFilterBtn,
  handleClear,
}) => {
  const teamsList = useSelector((state) => state?.salesDataReducer?.teamsList);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reduxSalesTeamList());
  }, []);

  return (
    <>
      <div>
        <SidePanel isOpen={isSidePanelOpen} toggle={toggleSidePanel}>
          {/* Content for the side panel goes here */}
          <Form>
            <Row className="mt-2 ms-2">
              <Col>&lt; Filter</Col>
            </Row>

            <Row className="mt-3 ms-2">
              <Col xs={12} sm={9} md={6} lg={10}>
                <FormGroup>
                  <span>
                    <b>Team</b>
                  </span>
                  <Input type="select" className="form-select mt-2" value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
                    <option value="">Select Team</option>
                    {teamsList?.map((item, index) => (
                      <option key={index}>{item.team}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3 ms-2">
              <Col xs={12} sm={9} md={6} lg={10}>
                <FormGroup>
                  <span>
                    <b>Designation</b>
                  </span>
                  <Input
                    type="select"
                    className="form-select mt-2"
                    value={selectedDesignation}
                    onChange={(e) => setSelectedDesignation(e.target.value)}
                  >
                    <option value="">Select Designation</option>
                    {designationList?.map((obj, index) => (
                      <option key={index}>{obj.designation}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-4 ms-2">
              <Col className="">
                <FormGroup>
                  <Button className="mx-2" onClick={handleApplyFilterBtn} color="primary">
                    Apply
                  </Button>
                  <Button onClick={handleClear}>Clear</Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </SidePanel>
      </div>
    </>
  );
};

export default SalesEmpDataFilter;
