import axiosInstance from "../config/axios/AxiosInstance";
import { DASHBOARD_DETAILS, DASHBOARD_PIECHART_DETAILS } from "../constants/end-points";

export const requestForDataBySession = async ({ start_at, end_at }) => {
  try {
    // console.log({ start_at, end_at });
    const res = await axiosInstance.post(DASHBOARD_DETAILS, { start_at, end_at });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForPieChardData = async ({ start_at, end_at }) => {
  try {
    // console.log({ start_at, end_at });
    const res = await axiosInstance.post(DASHBOARD_PIECHART_DETAILS, { start_at, end_at });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};
