import { CardText } from "reactstrap";

import nodataimg from "../../../assets/images/background/no-data.png";

const NoDataFound = ({from}) => {

  if(from == "dashboard"){
    return(
      <div className="wrapper">
      <div style={{paddingTop:112,paddingBottom:112}} className="d-flex flex-column align-items-center justify-content-center text-center  bg-white">
        <CardText className="h2 ">No Data Found</CardText>
        <img src={nodataimg} alt="No Data Found" height={150} />
      </div>
    </div>
    )
  }

  return (
    <div className="wrapper">
      <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
        <CardText className="h2">No Data Found</CardText>
        <img src={nodataimg} alt="No Data Found" height={150} />
      </div>
    </div>
  );
};

export default NoDataFound;
