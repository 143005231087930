import { createSlice } from "@reduxjs/toolkit";

import {
  // requestForAddGenerateBatch,
  requestForApprovedInvoices,
  requestForBatchData,
  requestForDownloadExcelnvoiceList,
  requestForInvoicesByBatch,
  requestForMarkPayment,
  requestForUploadCoverNoteDoc,
} from "../../../api/api.coverNote.request";

const initialState = {
  invoiceList: [],
  batchList: [],
  invoicePerBatch: [],
  // addBranch: [],
  uploadDocument: [],
  exportExcel: [],
  loading: false,
};
export const CoverNoteSlice = createSlice({
  name: "coverNote",
  initialState,
  reducers: {
    startDataLoading: (state) => {
      state.loading = true;
    },
    stopDataLoading: (state) => {
      state.loading = false;
    },
    getAllDataExport: (state, action) => {
      // console.log(action.payload, "action");
      state.invoiceList = action.payload;
    },
    getBatchDataExport: (state, action) => {
      state.batchList = action.payload;
    },
    getInvoiceDataExport: (state, action) => {
      // console.log(action.payload, "action");
      state.invoicePerBatch = action.payload;
    },
    // addGenerateBranchDetails: (state, action) => {
    //   state.addBranch = action.payload;
    // },
    uploadCovernoteDocumentDetails: (state, action) => {
      state.uploadDocument = action.payload;
    },
    markPaymentDetails: (state, action) => {
      state.markPayment = action.payload;
    },
    exportExcelList: (state, action) => {
      state.exportExcel = action.payload;
    },
  },
});
export const {
  getAllDataExport,
  startDataLoading,
  stopDataLoading,
  getBatchDataExport,
  getInvoiceDataExport,
  // addGenerateBranchDetails,
  uploadCovernoteDocumentDetails,
  markPaymentDetails,
  exportExcelList,
} = CoverNoteSlice.actions;

export const getAllApprovedInvoices =
  ({ searchKey, fromdate, todate, status, skip, limit }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading());
      const response = await requestForApprovedInvoices({ searchKey, fromdate, todate, status, skip, limit });
      // console.log("response", response);
      if (response) {
        // console.log("after response", response);
        dispatch(getAllDataExport(response.result));
        dispatch(stopDataLoading());
      }
    } catch (err) {
      dispatch(stopDataLoading());
      throw new Error(err);
    }
  };

export const getAllBatchList =
  ({ batchKey, fromdate, todate, skip, limit }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading);
      const response = await requestForBatchData({ batchKey, fromdate, todate, skip, limit });
      if (response) {
        dispatch(getBatchDataExport(response.results));
        dispatch(stopDataLoading);
      }
    } catch (error) {
      dispatch(stopDataLoading);
      throw new Error(error);
    }
  };

export const getAllInvoicesByBatch =
  ({ batch, skip, limit }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading);
      const response = await requestForInvoicesByBatch({ batch: batch, skip, limit });
      const result = await {
        invoiceList: response.invoices,
        total_records: response.totalCount,
      };
      // console.log("response", response);
      if (response) {
        // console.log("after response", response);
        dispatch(getInvoiceDataExport(result));
        dispatch(stopDataLoading);
      }
    } catch (error) {
      dispatch(stopDataLoading);
      throw new Error(error);
    }
  };

// export const addGenerateBranch =
//   ({ tokens }) =>
//   async (dispatch) => {
//     try {
//       dispatch(startDataLoading);
//       const response = await requestForAddGenerateBatch({ tokens: tokens });
//       // console.log("response", response);
//       if (response) {
//         // console.log("after response", response);
//         dispatch(addGenerateBranchDetails(response));
//         dispatch(stopDataLoading);
//       }
//     } catch (error) {
//       dispatch(stopDataLoading);
//       throw new Error(error);
//     }
//   };

export const uploadCovernoteDocument =
  ({ batch_id, description, files }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading);
      const response = await requestForUploadCoverNoteDoc({ batch_id: batch_id, description: description, files: files });
      // console.log("response", response);
      if (response) {
        // console.log("after response", response);
        dispatch(uploadCovernoteDocumentDetails(response));
        dispatch(stopDataLoading);
      }
    } catch (error) {
      dispatch(stopDataLoading);
      throw new Error(error);
    }
  };

export const markPayment =
  ({ utr, remark, invoice_id, payment_date, files }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading);
      const response = await requestForMarkPayment({
        utr: utr,
        remark: remark,
        invoice_id: invoice_id,
        payment_date: payment_date,
        files: files,
      });
      // console.log("response", response);
      if (response) {
        // console.log("after response", response);
        dispatch(markPaymentDetails(response));
        dispatch(stopDataLoading);
      }
    } catch (error) {
      dispatch(stopDataLoading);
      throw new Error(error);
    }
  };

export const exportExcelInvoiceList =
  ({ invoices }) =>
  async (dispatch) => {
    try {
      const response = await requestForDownloadExcelnvoiceList({ invoices });
      // console.log("response excel", response);
      if (response) {
        dispatch(exportExcelList(response.Link));
      }
    } catch (err) {
      throw new Error(err);
    }
  };

export default CoverNoteSlice.reducer;
