import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { requestForS3FileFromUrl } from '../../api/api.common.request';

const ExcelViewerComponent = () => {
  const [excelData, setExcelData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setExcelData(parsedData);
    };

    reader.readAsBinaryString(file);
  };
//https://afms-storage.s3.ap-south-1.amazonaws.com/uploads/AFF_dymmyname_Type_INVOICE_Token-No_1234-fgghh-65544
  useEffect(()=>{
    getAblobFile()
  },[])

  const getAblobFile = async () => {
    let res = await requestForS3FileFromUrl({url:"https://afms-storage.s3.ap-south-1.amazonaws.com/uploads/file_example_XLS_10.xls", type:"xl"})
    const data = new Uint8Array(res.data);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    setExcelData(parsedData);
  }

  return (
    <div>
      {/* <h1>Excel Viewer</h1>
      <input type="file" onChange={handleFileUpload} /> */}
      {excelData.length > 0 && (
        <table>
          <tbody>
            {excelData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExcelViewerComponent;
