import { Input} from "reactstrap";
const CheckboxComponent = ({ id, onChange, checked }) => {
  return (
    <>
      <Input 
        type="checkbox" 
        id={id} 
        onChange={(e) => {onChange(e,id)}} 
        checked={checked} 
        value={checked ? false : true}/>
    </>
  );
};
export default CheckboxComponent;
