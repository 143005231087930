import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { roles } from "../../constants/roles";
import useToast from "../../hooks/use-toast";
import { requestForApproveCriteria, requestForRejectCriteria } from "../../api/api.sales.request";
import { getIncentivePlanByCriteriaId } from "../../store/apps/sales/SalesEmployeesSlice";
import IncentiveCriteriaPreviewTable from "../../views/form-steps/IncentiveCriteriaPreview-grid";

const CriteriaDetails = () => {
  const userData = useSelector((state) => state.user.userDetails);
  const incentivePlanByCriteriaId = useSelector((state) => state?.salesDataReducer?.incentivePlanByCriteriaId);

  const { id, from_date, to_date, sales_channel_id, name, plan_type, plan_json, attachment_url, status, is_active, plan_creator, team_name } =
    incentivePlanByCriteriaId?.[0] ?? {};

  const { agent, assisstance_team_lead, team_lead, sales_head, cluster_head } = plan_json ?? {};
  const { capping, category_target_mapping, monthly_multiplier, payout_mapping, semester_multiplier, teams_target, incentive_structure } = agent
    ? agent
    : assisstance_team_lead
    ? assisstance_team_lead
    : cluster_head
    ? cluster_head
    : sales_head
    ? sales_head
    : team_lead
    ? team_lead
    : "";

  //Nav Link toggle
  const [activeTab, setActiveTab] = useState("1");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reason, setReason] = useState("");
  const { successToast, errorToast } = useToast();
  const [reload, setRelaod] = useState(false);

  const urlId = useParams().id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIncentivePlanByCriteriaId({ criteria_id: urlId }));
  }, []);

  useEffect(() => {
    dispatch(getIncentivePlanByCriteriaId({ criteria_id: urlId }));
  }, [reload]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const approveToggle = () => {
    setApproveModal(!approveModal);
  };

  const rejectToggle = () => {
    setRejectModal(!rejectModal);
  };

  const handleAcceptPopup = () => {
    approveToggle();
  };

  const handleRejectPopup = () => {
    rejectToggle();
  };

  const handleReject = async () => {
    try {
      if (!reason) {
        return errorToast("Please fill the reason");
      }

      const res = await requestForRejectCriteria({ criteria_id: id, remark: reason, team_lsq_id: sales_channel_id });
      if (res?.status == 200 || res?.status == 201) {
        successToast("Successfully rejected configuration");
        setRelaod((reload) => !reload);
      } else {
        errorToast("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    }
    rejectToggle();
  };

  const handleApprove = async () => {
    try {
      if (!reason) {
        return errorToast("Please fill the reason");
      }
      const res = await requestForApproveCriteria({ criteria_id: id, remark: reason, team_lsq_id: sales_channel_id });
      if (res?.status == 200 || res?.status == 201) {
        successToast("Successfully approved configuration");
        setRelaod((reload) => !reload);
      } else {
        errorToast("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    }
    approveToggle();
  };

  function capitalizeName(name) {
    if (name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  const handleViewIcon = (e, url) => {
    e.preventDefault();
    if (url) {
      window.open(`/pdf/view?url=${url}`, "_blank");
    } else {
      console.log("Nothing to view");
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              &lt;
              <Link to="/sis/incentive-criteria">
                <Label>Incentive Criteria</Label>
              </Link>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <Button color="primary" size="md" disabled>
                Criteria Details
              </Button>
              {is_active === 0 ? (
                <Button color="danger" disabled size="md" className="ms-2">
                  InActive
                </Button>
              ) : (
                <Button color="success" disabled size="md" className="ms-2">
                  Active
                </Button>
              )}

              {userData.role === roles?.FINANCE_HEAD && status === 2 && (
                <>
                  <Button color="success" size="md" className="float-end ms-2" onClick={handleAcceptPopup}>
                    Approve
                  </Button>
                  <Button color="danger" size="md" className="float-end" onClick={handleRejectPopup}>
                    Reject
                  </Button>
                </>
              )}
            </Col>
          </Row>

          <Modal isOpen={approveModal} size="md">
            <ModalHeader toggle={approveToggle}>
              <Label className="text-success">Are you sure you want to approve the request?</Label>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleText">
                  Reason<span className="text-danger">*</span>
                </Label>
                <Input
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  id="exampleText"
                  name="text"
                  type="textarea"
                  placeholder="Type your reason"
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleApprove}>
                Yes
              </Button>
              <Button color="danger" onClick={approveToggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={rejectModal} size="md">
            <ModalHeader toggle={rejectToggle}>
              <Label className="text-danger">Are you sure you want to reject the request?</Label>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleText">
                  Reason<span className="text-danger">*</span>
                </Label>
                <Input
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  id="exampleText"
                  name="text"
                  type="textarea"
                  placeholder="Type your reason"
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleReject}>
                Yes
              </Button>
              <Button color="danger" onClick={rejectToggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>

          <Card className="mt-4">
            <CardBody className="bg-light" style={{ borderRadius: "15px" }}>
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Criteria ID : </Label>
                      <Label className="ms-2">{id ? id : "NA"} </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Plan Name : </Label>
                      <Label className="ms-2">{capitalizeName(name ? name : "NA")} </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Team : </Label>
                      <Label className="ms-2">{capitalizeName(team_name)} </Label>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Incentive Category : </Label>
                      <Label className="ms-2">{plan_type === 1 ? "Regular" : plan_type === 2 ? "Regular" : plan_type === 3 ? "Addon" : "NA"}</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Incentive Type : </Label>
                      <Label className="ms-2">{plan_type === 1 ? "Config" : plan_type === 2 ? "Slab" : "NA"}</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Plan Creator : </Label>
                      <Label className="ms-2">{plan_creator ? plan_creator : "NA"}</Label>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Effective Month : </Label>
                      <Label className="ms-2">{from_date ? from_date : "NA"}</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Effective Till : </Label>
                      <Label className="ms-2">{to_date ? to_date : "NA"}</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label className="fw-bold text-success">Documents : </Label>
                      <Label className="ms-2">
                        {attachment_url ? (
                          <Link>
                            <Icon.Eye size="20" onClick={(e) => handleViewIcon(e, attachment_url)} />
                          </Link>
                        ) : (
                          "NA"
                        )}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Nav tabs className="cursor-pointer">
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  toggle("1");
                }}
              >
                Counseller
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  toggle("2");
                }}
              >
                ATL
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  toggle("3");
                }}
              >
                TL
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "4" ? "active" : ""}
                onClick={() => {
                  toggle("4");
                }}
              >
                Cluster Head
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "5" ? "active" : ""}
                onClick={() => {
                  toggle("5");
                }}
              >
                Sales Head
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">{agent && <IncentiveCriteriaPreviewTable agent={agent} />}</TabPane>
            <TabPane tabId="2">{assisstance_team_lead && <IncentiveCriteriaPreviewTable assisstance_team_lead={assisstance_team_lead} />}</TabPane>
            <TabPane tabId="3">{team_lead && <IncentiveCriteriaPreviewTable team_lead={team_lead} />}</TabPane>
            <TabPane tabId="4">{cluster_head && <IncentiveCriteriaPreviewTable cluster_head={cluster_head} />}</TabPane>
            <TabPane tabId="5">{sales_head && <IncentiveCriteriaPreviewTable sales_head={sales_head} />}</TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

export default CriteriaDetails;
