import React from 'react';
import {useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const AlertModal = (props)=>{
    const {isOpen, messageBody, handleCancel}=props;
    const [modalOpen, setModalOpen] = useState(isOpen);
    const onCancel = ()=>{
        setModalOpen(false)
        handleCancel();
    }
    return (
        <Modal
          isOpen={modalOpen}
          toggle={onCancel}
          backdrop
        >
          <ModalBody>{messageBody}</ModalBody>
          <ModalFooter>
            <Button color="danger" outline onClick={onCancel}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
    )
}

export default AlertModal;