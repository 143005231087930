import { createSlice } from "@reduxjs/toolkit";

import {
  requestForAffiliateEnrollmentList,
  requestForStudentLoanDetails,
  requestForStudentProgramList,
  requestForStudentSessionList,
  requestForStudentUniversityList,
} from "../../../api/api.enrolment.request";

const initialState = {
  enrollmentList: [],
  universityList: [],
  sessionList: [],
  programList: [],
  loanDetails: {},
  loading: false,
};
export const EnrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    startEnrollmentLoading: (state) => {
      state.loading = true;
    },
    stopEnrollmentLoading: (state) => {
      state.loading = false;
    },
    getAllEnrollmentExport: (state, action) => {
      // console.log(action.payload, "action");
      state.enrollmentList = action.payload;
    },
    getAllUniversityExport: (state, action) => {
      state.universityList = action.payload;
    },
    getAllSessionByUniversityExport: (state, action) => {
      state.sessionList = action.payload;
    },
    getAllProgramByUniversityAndSessionExport: (state, action) => {
      state.programList = action.payload;
    },
    getLoanDetailsExport: (state, action) => {
      state.loanDetails = action.payload;
    },
  },
});
export const {
  getAllEnrollmentExport,
  startEnrollmentLoading,
  stopEnrollmentLoading,
  getAllUniversityExport,
  getAllSessionByUniversityExport,
  getAllProgramByUniversityAndSessionExport,
  getLoanDetailsExport,
} = EnrollmentSlice.actions;

export const reduxEnrollmentList =
  ({ from_date, to_date, university, session, program, payment_status, search_key, skip, limit }) =>
    async (dispatch) => {
      try {
        dispatch(startEnrollmentLoading());
        const response = await requestForAffiliateEnrollmentList({
          from_date,
          to_date,
          university,
          session,
          program,
          payment_status,
          search_key,
          skip,
          limit,
        });
        // console.log(response);
        if (response) {
          // console.log("after response", response);
          dispatch(getAllEnrollmentExport(response.result));
          dispatch(stopEnrollmentLoading());
        }
      } catch (err) {
        dispatch(stopEnrollmentLoading());
        throw new Error(err);
      }
    };

export const getUniversityList = () => async (dispatch) => {
  try {
    const response = await requestForStudentUniversityList();
    // console.log(response);
    if (response) {
      // console.log("after response", response);
      dispatch(getAllUniversityExport(response.result));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getSessionListByUniversity = (university_id) => async (dispatch) => {
  try {
    const response = await requestForStudentSessionList({ university_id: university_id });
    console.log(response);
    if (response) {
      dispatch(getAllSessionByUniversityExport(response.result));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getProgramListByUniversityAndSession = (university_id, session_id) => async (dispatch) => {
  try {
    const response = await requestForStudentProgramList({ university_id: university_id, session_id: session_id });
    console.log(response);
    if (response) {
      console.log("after response", response);
      dispatch(getAllProgramByUniversityAndSessionExport(response.result));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getLoanDetails = (transaction_id) => async (dispatch) => {
  try {
    const response = await requestForStudentLoanDetails({ transaction_id: transaction_id });
    console.log(response);
    if (response) {
      dispatch(getLoanDetailsExport(response));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export default EnrollmentSlice.reducer;
