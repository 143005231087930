export const removeUnderScoreFromString = (originalString) => originalString.replace(/_/g, " ");

export const hasDuplicateObjectKey = (array, key) => {
  let newArray = array.filter((data) => data[key] == "")
  const encounteredValues = new Set();
  for (const obj of newArray) {
    const value = obj[key];
    if (encounteredValues.has(value)) {
      return true; // Found a duplicate value for the specified key
    }
    encounteredValues.add(value);
  }
  return false; // No duplicates found for the specified key
};

export const removeDuplicatesInArrayOfObjects = (array) => {
  const uniqueObjects = [];
  const uniqueKeys = new Set();

  array.forEach(obj => {
    const keys = Object.keys(obj).map(key => `${key}:${obj[key]}`).join('|');
    if (!uniqueKeys.has(keys)) {
      uniqueKeys.add(keys);
      uniqueObjects.push(obj);
    }
  });

  return uniqueObjects;
}

export const formatNumberForIndia = (number) => {
  // Use toLocaleString with 'en-IN' locale to format number for India
  return number?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
