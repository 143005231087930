import { lazy } from "react";
import { Navigate } from "react-router-dom";

import ExcelViewerComponent from "../components/excel/ExcelViewerComponent";
import PdfViewerComp from "../components/pdf/PdfViewerComp";
import Loadable from "../layouts/loader/Loadable";
import CriteriaDetails from "../SIS/components/CriteriaDetails"; // imp
import SalesEmpTable from "../SIS/components/SalesEmpTable";
import Tokens from "../views/apps/Tokens";
import UserManagmentTab from "../views/apps/UserManagement/UserManagmentTab";

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
/***** Pages ****/

const Dashboard1 = Loadable(lazy(() => import("../views/dashboards/Dashboard1")));
const Dashboard2 = Loadable(lazy(() => import("../views/dashboards/Dashboard2")));
const Dashboard = Loadable(lazy(() => import("../views/dashboards/Dashboard")));
const StudentDashboard = Loadable(lazy(() => import("../../src/components/dashboard/StudentDashboard")));
const SemesterPayments = Loadable(lazy(() => import("../components/dashboard/StudentDashboard/SemesterPayments/Index")));
const RegistrationsPayments = Loadable(lazy(() => import("../components/dashboard/StudentDashboard/RegistrationsPayments/Index")));
const CheckRegistrations = Loadable(lazy(() => import("../components/dashboard/StudentDashboard/CheckRegistrations")));
const ClaimTable = Loadable(lazy(() => import("../components/dashboard/StudentDashboard/ClaimTable/Index")));
const TotalPaymentCard = Loadable(lazy(() => import("../components/dashboard/StudentDashboard/TotalPaymentCard")));

const CommissionConfiguration = Loadable(lazy(() => import("../views/apps/commitionConfiguration/commission-configuration")));
const AffiliateDetails = Loadable(lazy(() => import("../views/apps/commitionConfiguration/affiliate-details")));
const TokenDetails = Loadable(lazy(() => import("../components/dashboard/dashboard2/TokenDetails")));
const AddAffiliate = Loadable(lazy(() => import("../components/dashboard/dashboard2/AddAffiliate")));
const InvoiceApproved = Loadable(lazy(() => import("../components/dashboard/dashboard2/InvoiceApproved")));
const CreateBatch = Loadable(lazy(() => import("../components/dashboard/dashboard2/CreateBatch")));
const Reports = Loadable(lazy(() => import("../components/dashboard/dashboard2/Reports")));
const SalesDetails = Loadable(lazy(() => import("../components/dashboard/SIS/SalesDetails")));
const SalesCriteria = Loadable(lazy(() => import("../components/dashboard/incentiveCriteria/Ctiterian-form")));
const SalesIncentiveData = Loadable(lazy(() => import("../components/dashboard/salesIncentiveData/IncentiveData-form")));
const SalesIncentiveDetails = Loadable(lazy(() => import("../components/dashboard/salesIncentiveData/salesIncentiveDetails/Index")));
const SalesIncentiveReport = Loadable(lazy(() => import("../components/dashboard/salesIncentiveReport/index")));
/***** Apps ****/

const UserManagement = Loadable(lazy(() => import("../views/apps/UserManagement/UserManagement")));
// const AFMS = Loadable(lazy(() => import("../views/apps/UserManagement/AFMS")));
const TicketList = Loadable(lazy(() => import("../views/apps/ticket/TicketList")));
const TicketDetail = Loadable(lazy(() => import("../views/apps/ticket/TicketDetail")));
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));
const RegisterFormik = Loadable(lazy(() => import("../views/auth/RegisterFormik")));
const LoginFormik = Loadable(lazy(() => import("../views/auth/LoginFormik")));
const Maintanance = Loadable(lazy(() => import("../views/auth/Maintanance")));
const LockScreen = Loadable(lazy(() => import("../views/auth/LockScreen")));
const RecoverPassword = Loadable(lazy(() => import("../views/auth/RecoverPassword")));
const VerifyOtp = Loadable(lazy(() => import("../views/auth/VerifyOtp")));
const ResetPassword = Loadable(lazy(() => import("../views/auth/ResetPassword")));
const FormSteps = Loadable(lazy(() => import("../views/form-steps/Steps")));

/*****Routes******/

const ThemeRoutes = (isLoggedIn, user) => {
  // console.log(user, "user")
  return [
    {
      path: "/",
      element: isLoggedIn ? <FullLayout /> : <Navigate to={"/auth/login"} />,
      children: [
        {
          path: "/",
          element: <UserManagement />,
        },
        {
          path: "/home",
          name: "Home",
          element: <Navigate to="/" />,
        },
        {
          path: "/afms/students-details",
          name: "Dashboard 1",
          exact: true,
          element: <Dashboard1 />,
        },
        {
          path: "/afms/invoice",
          name: "Invoice",
          exact: true,
          element: <InvoiceApproved />,
        },
        {
          path: "/afms/batch",
          name: "Batch",
          exact: true,
          element: <CreateBatch />,
        },
        {
          path: "/afms/reports",
          name: "Reports",
          exact: true,
          element: <Reports />,
        },
        {
          path: "/afms/affiliates-dashboard",
          name: "Dashboard 2",
          exact: true,
          element: <Dashboard2 />,
        },
        {
          path: "/afms/dashboard",
          name: "Dashboard",
          exact: true,
          element: <Dashboard />,
        },
        {
          path: "/afms/student_dashboard",
          name: "Student Dashboard",
          exact: true,
          element: <StudentDashboard />,
        },
        {
          path: "/afms/semester_payments",
          name: "Semester Payments",
          exact: true,
          element: <SemesterPayments />,
        },
        {
          path: "/afms/registrations_payments",
          name: "Registrations Payments",
          exact: true,
          element: <RegistrationsPayments />,
        },
        {
          path: "/afms/CheckRegistrations",
          name: "Registrations Payments",
          exact: true,
          element: <CheckRegistrations />,
        },
        {
          path: "/afms/claim_details",
          name: "Claim Details",
          exact: true,
          element: <ClaimTable />,
        },
        {
          path: "/afms/total_payment_card",
          name: "Total Payment Card",
          exact: true,
          element: <TotalPaymentCard />,
        },
        {
          path: "/affiliate/:id",
          name: "token details",
          exact: true,
          element: user.role == "finance_executive" ? <TokenDetails /> : user.role == "affiliate" ? <Tokens /> : "",
        },
        {
          path: "/affiliate/token",
          name: "Tokens",
          exact: true,
          element: user.role == "affiliate" ? <Tokens /> : "",
        },

        // {
        //   path: "/afms/tokens/:id",
        //   name: "Aff token",
        //   exact: true,
        //   element: <Tokens />,
        // },
        // {
        //   path: "/affiliate/:id",
        //   name: "token details",
        //   exact: true,
        //   element: <Tokens />,
        // },

        {
          path: "/afms/affiliate/add/:id",
          name: "Add Affiliate",
          exact: true,
          element: <AddAffiliate />,
        },
        {
          path: "/user-management",
          name: "User Management",
          exact: true,
          // element: <AFMS />,
          element: <UserManagmentTab />,
        },
        {
          path: "/afms/commission-configuration",
          name: "Commission Configuration",
          exact: true,
          element: <CommissionConfiguration />,
        },
        {
          path: "/afms/affiliate-details",
          name: "Affiliate Details",
          exact: true,
          element: <AffiliateDetails />,
        },
        {
          path: "/afms/tokens",
          name: "Token",
          exact: true,
          element: <Tokens />,
        },
        {
          path: "/tickt/ticket-list",
          name: "ticket list",
          exact: true,
          element: <TicketList />,
        },
        {
          path: "/my-profile/:id",
          name: "ticket detail",
          exact: true,
          element: <TicketDetail />,
        },
        {
          path: "/incentive-criteria/:id",
          name: "form-step",
          exact: true,
          element: <FormSteps />,
        },
        {
          path: "/sis/incentive-criteria",
          name: "Incentive Criteria",
          exact: true,
          element: <SalesCriteria />,
        },
        {
          path: "/sis/employee-details",
          name: "Sales Employee Data",
          exact: true,
          element: <SalesEmpTable />,
        },
        {
          path: "/sis/incentive-data",
          name: "Sales Incentive Data",
          exact: true,
          element: <SalesIncentiveData />,
        },
        {
          path: "/sis/incentive-report",
          name: "Sales Incentive Report",
          exact: true,
          element: <SalesIncentiveReport />,
        },
        {
          path: "/sis/sales-details/:id",
          name: "Sales Employee Details",
          exact: true,
          element: <SalesDetails />,
        },
        {
          path: "/sis/sales/incentive/:id",
          name: "Sales Incentive Details",
          exact: true,
          element: <SalesIncentiveDetails />,
        },
        {
          path: "/sis/criteria/details/:id",
          name: "Sales Criteria Details",
          exact: true,
          element: <CriteriaDetails />,
        },
        { path: "*", element: <Navigate to="/auth/404" /> },
      ],
    },
    {
      path: "/auth",
      element: !isLoggedIn ? <BlankLayout /> : <Navigate to={"/"} />,
      children: [
        { path: "404", element: <Error /> },
        { path: "*", element: <Navigate to="/auth/404" /> },
        { path: "registerformik", element: <RegisterFormik /> },
        { path: "login", element: <LoginFormik /> },
        { path: "maintanance", element: <Maintanance /> },
        { path: "lockscreen", element: <LockScreen /> },
        { path: "recoverpwd", element: <RecoverPassword /> },
        { path: "verifyotp", element: <VerifyOtp /> },
        { path: "resetpassword", element: <ResetPassword /> },
      ],
    },
    {
      path: "/pdf",
      element: <BlankLayout />,
      children: [{ path: "view", element: <PdfViewerComp /> }],
    },
    {
      path: "/excel",
      element: <BlankLayout />,
      children: [{ path: "view", element: <ExcelViewerComponent /> }],
    },
  ];
};

export default ThemeRoutes;
