import toast from "react-hot-toast";
const useToast = () => {
  const successToast = (message) => {
    toast.success(message, {
      duration: 3000,
      position: "top-right",
      className: "bg-success text-white",
      iconTheme: {
        primary: "#fff",
        secondary: "#000",
      },
    });
  };
  const errorToast = (message) => {
    toast.error(message, {
      duration: 3000,
      position: "top-right",
      className: "bg-danger text-white",
      iconTheme: {
        primary: "#fff",
        secondary: "#000",
      },
    });
  };
  const accessToast = (message) => {
    toast.error(message, {
      duration: 3000,
      position: "top-right",
      className: "bg-warning text-white",
      iconTheme: {
        primary: "#fff",
        secondary: "#000",
      },
    });
  };

  return { successToast, errorToast, accessToast };
};

export default useToast;
