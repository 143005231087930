import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";

import { getCookie } from "./helpers/cookie-helper";
import Loader from "./layouts/loader/Loader";
import ThemeSelector from "./layouts/theme/ThemeSelector";
import Themeroutes from "./routes/Router";
import { reduxIsUserLoggedIn } from "./store/apps/user/UserSlice";

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.auth);
  const user = useSelector((state) => state.user.userDetails)

  useEffect(() => {
    dispatch(reduxIsUserLoggedIn());
  }, []);

  const isLoggedin = getCookie("x-univo-api-token") === null && !auth ? false : true;
  const routing = useRoutes(Themeroutes(isLoggedin, user));

  const isMode = useSelector((state) => state.customizer.isDark);

  return (
    <Suspense fallback={<Loader />}>
      <div className={`ltr ${isMode ? "dark" : ""}`}>
        <ThemeSelector />
        {routing}
        <Toaster />
      </div>
    </Suspense>
  );
};

export default App;
