import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { requestForFinanceApprovalOrRejection } from "../../../api/api.auth.request";
import { requestForS3FileFromUrl } from "../../../api/api.common.request";
import { requestForApprovedOrRejectedInvoiceByFinance, requestForDownloadOrUploadTokenByAffiliate } from "../../../api/api.token.request";
import NoDataFound from "../../../components/dashboard/common/NoDataFound";
import PaginationComponent from "../../../components/dashboard/common/PaginationComponent";
import { ConfirmationModal } from "../../../components/modal";
import AlertModal from "../../../components/modal/AlertModal";
import { roles } from "../../../constants/roles";
import { AffiliateTokenStatus } from "../../../constants/StatusConstant";
import { formatNumberForIndia } from "../../../helpers/common";
import useToast from "../../../hooks/use-toast";
import { getUniversityList } from "../../../store/apps/enrollment/EnrollmentSlice";
import {
  ApprovedRejectedInvoiceByFinance,
  // downloadUploadTokenInvoiceByAffiliate,
  // reduxAffiliateTokenList,
  reduxAllAffiliateTokenList,
} from "../../../store/apps/token/TokenSlice";
import GenerateTokenScreen from "./GenerateTokenScreen";
import { requestForAllAffiliateTokenList } from "../../../api/api.allToken.request";
//comment
// import { requestForS3FileFromUrl } from "../../../api/api.common.request";
// import { requestForDownloadOrUploadTokenByAffiliate } from "../../../api/api.token.request";
// import { roles } from "../../../constants/roles";
// import { formatNumberForIndia } from "../../../helpers/common";
// import useToast from "../../../hooks/use-toast";
// import {
//     ApprovedRejectedInvoiceByFinance,
//     downloadUploadTokenInvoiceByAffiliate,
//     reduxAffiliateTokenList,
// } from "../../../store/apps/token/TokenSlice";
// import GenerateTokenScreenTable from "../../../views/apps/token/GenerateTokenScreenTable";
// import AlertModal from "../../modal/AlertModal";
// import ConfirmationModal from "../../modal/confirmation-modal";
// import NoDataFound from "../common/NoDataFound";
// import PaginationComponent from "../common/PaginationComponent";

const Tokens = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const tokenList = useSelector((state) => state?.affiliateToken?.tokenList?.result || []);
  const all_status_count = useSelector((state) => state?.affiliateToken?.tokenList?.all_status_count || []);
  const total_records = useSelector((state) => state?.affiliateToken?.tokenList?.total_records);
  // const commission_structure_type = useSelector((state) => state?.affiliateToken?.tokenList?.commission_structure_type || "");
  // const is_generate_token = useSelector((state) => state?.affiliateToken?.tokenList?.is_generate_token);
  const universityList = useSelector((state) => state?.enrollmentReducer?.universityList);

  const { successToast, errorToast } = useToast();

  const userData = useSelector((state) => state?.user?.userDetails);
  const affiliateId = userData?.affiliate_id;

  // const affiliateinternalID = Number(affiliateId?.split(".")?.[1]);
  const affiliateinternalID = 1052;
  const [tableData, setTableData] = useState([...tokenList]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [showReject, setShowReject] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [generateTokenModal, setGenerateTokenModal] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSuccessfully, setIsSuccessFully] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [modalTokenScreen, setModalTokenScreen] = useState(false);
  const [saveGenerateToken, setSaveGenerateToken] = useState(false);
  const [files, setFiles] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const recordsPerPage = 10;
  // const nPage = Math.ceil(tableData?.length / recordsPerPage);

  const [selectedDownload, setSelectedDownload] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [tokenNumber, setTokenNumber] = useState("");
  const [netPayableAmount, setNetPayableAmount] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = total_records ? Math.ceil(total_records / recordsPerPage) : "";
  const [uploadInvoiceNumber, setUploadInvoiceNumber] = useState("");
  const [remark, setRemark] = useState("");
  const [uploadInvoiceDate, setUploadInvoiceDate] = useState("");
  const [updateTokenList, setUpdateTokenList] = useState(false);
  // const [refreshAfterTokenDownload, setRefreshAfterTokenDownload] = useState(false);
  // const [refreshUploadInvoice, setRefreshUploadInvoice] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [search, setSearch] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [tokenRejectModal, setTokenRejectModal] = useState(false);
  const [reason, setReason] = useState("");
  const [tokenRejectReason, setTokenRejectReason] = useState("");
  const [token, setToken] = useState("");
  const [loader, setLoader] = useState(false);
  const [editToken, seEditToken] = useState("");
  const [tokenFromDate, setTokenFromDate] = useState("");
  const [tokenToDate, setTokenToDate] = useState("");
  const initialFilterCriteria = {
    university: "",
    status: "",
    search_key: "",
  };
  // const initialfilterData = () => {
  //   const currentDate = moment(new Date()).format("YYYY-MM-DD");
  //   const firstDayOfMonth = moment(new Date()).startOf("month").format("YYYY-MM-DD");

  //   // Set the initial fromDate and toDate
  //   setFromDate(firstDayOfMonth);
  //   setToDate(currentDate);

  //   setShowDetails(true); //Show Student data based on Applied Filter
  //   setCurrentPage(1);
  // };
  const initialfilterData = () => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const firstDayOfMonth = moment(new Date()).startOf("month").format("YYYY-MM-DD");

    // Set the initial fromDate and toDate
    setFromDate(tokenFromDate);
    setToDate(tokenToDate);

    setShowDetails(true); //Show Student data based on Applied Filter
    setCurrentPage(1);
    console.log("TKK", tokenFromDate, tokenFromDate);
  };

  const [filterCriteria, setFilterCriteria] = useState({
    university: "",
    status: "",
    search_key: "",
  });
  useEffect(() => {
    initialData();
  }, [editToken]);

  // useEffect(() => {
  //     console.log(affiliateId, "affiliate")
  //     if (affiliateId) dispatch(
  //         reduxAllAffiliateTokenList({
  //             // affiliate_id: affiliateinternalID,
  //             status: selectedValue,
  //             skip: (currentPage - 1) * recordsPerPage,
  //             limit: recordsPerPage,
  //             affiliate_id: affiliateId
  //         })
  //     );
  // }, [affiliateId
  // tokenList
  // currentPage, tokenNumber, updateTokenList, currentIndex, refreshAfterTokenDownload, refreshUploadInvoice
  // ]); //[currentPage, tokenNumber]); //updateTokenList

  useEffect(() => {
    dispatchRequest();
    dispatch(getUniversityList());
  }, [search, currentPage, affiliateId, filterCriteria.status, updateTokenList]);

  useEffect(() => {
    if (tokenList?.length > 0 || tokenList?.length === 0) {
      setTableData(tokenList);
    }
  }, [tokenList]);

  const toggle1 = () => {
    // setIsSaved(true);
    setRejectModal(!rejectModal);
  };
  const toggleReject = () => {
    // setIsSaved(true);
    setTokenRejectModal(!tokenRejectModal);
  };

  //mgfhjkk
  const dispatchRequest = () => {
    dispatch(
      reduxAllAffiliateTokenList({
        from_date: fromDate,
        to_date: toDate,
        university: filterCriteria?.university,
        status: filterCriteria?.status,
        search_key: filterCriteria?.search_key,
        skip: (currentPage - 1) * recordsPerPage,
        limit: recordsPerPage,
        affiliate_id: affiliateId,
      })
    );
  };

  const handleFilter = () => {
    dispatchRequest();
  };

  const handleReset = () => {
    setFilterCriteria(initialFilterCriteria);
    initialfilterData();
    setSearch((search) => !search);
  };
  // Affiliate Code Start here

  const handleTokenApproveBtn = (index, tokenNumber) => {
    setShowApprove(true);
    setCurrentIndex(tokenNumber);
  };

  const handleTokenRejectBtn = (index, tokenNumber) => {
    setTokenRejectModal(true);
    setCurrentIndex(tokenNumber);
  };

  const handleTokenSaveBtn = (index) => {
    const updatedObject = {
      ...tableData[index],
      status: "Invoice send for verification",
    };
    const newArray = [...tableData];
    newArray[index] = updatedObject;
    setTableData(newArray);
    // Save the updated data to localStorage
    // saveTableDataToLocalStorage(newArray);
    setCurrentIndex(index);
    // alert("Invoice file successfully saved");
    setShowAlert(true);
  };

  // const getSelectedFile = (file) => {
  //   setSelectedFile(file);
  // };

  const handleConfirmDeleteApprove = () => {
    if (currentIndex) {
      dispatch(
        ApprovedRejectedInvoiceByFinance({
          token_no: currentIndex,
          new_status: "3",
          remarks: "",
        })
      ).then(() => {
        successToast("Successfully approved.");
        setCurrentIndex((currentIndex) => !currentIndex);
        dispatchRequest();
      });
    }
    setShowApprove(false);
  };
  const handleFinanceApprove = () => {
    if (currentIndex) {
      dispatch(
        ApprovedRejectedInvoiceByFinance({
          token_no: currentIndex,
          new_status: "6",
          remarks: "",
        })
      ).then(() => {
        successToast("Successfully approved.");
        setCurrentIndex((currentIndex) => !currentIndex);
      });
    }
    setShowApprove(false);
    dispatchRequest();
  };

  const handleCancelDeleteApprove = () => {
    setShowApprove(false);
  };
  const handleCancelAlert = () => {
    setShowAlert(false);
  };

  const handleConfirmDeleteReject = async () => {
    // setTokenRejectModal(true);
    if (currentIndex) {
      // dispatch(
      //     ApprovedRejectedInvoiceByFinance({
      //         token_no: currentIndex,
      //         new_status: "4",
      //         remarks: "Rejected by affiliate"
      //     })
      // ).then(() => {
      //     errorToast("Token rejected");
      //     setCurrentIndex((currentIndex) => !currentIndex);
      // });
      const res = await requestForApprovedOrRejectedInvoiceByFinance({
        token_no: currentIndex,
        new_status: "4",
        remarks: tokenRejectReason,
      });
    }
    dispatchRequest();
    setTokenRejectModal(false);
  };
  const handleFinanceReject = async () => {
    // setTokenRejectModal(true);
    if (currentIndex) {
      // dispatch(
      //     ApprovedRejectedInvoiceByFinance({
      //         token_no: currentIndex,
      //         new_status: "4",
      //         remarks: "Rejected by affiliate"
      //     })
      // ).then(() => {
      //     errorToast("Token rejected");
      //     setCurrentIndex((currentIndex) => !currentIndex);
      // });
      const res = await requestForApprovedOrRejectedInvoiceByFinance({
        token_no: currentIndex,
        new_status: "7",
        remarks: tokenRejectReason,
      });
    }
    dispatchRequest();
    setTokenRejectModal(false);
  };
  const handleCancelDeleteReject = () => {
    setShowReject(false);
  };
  // Affiliate code code end here

  const isAnyInvoiceApproved = tableData?.some(
    (row) => row?.status === "Sent for Affiliate Verification" || row?.status === "Token Rejected by Affiliate"
  );
  const isAnyPaymentApproved = tableData?.some(
    (row) =>
      row?.status === "send_for_affiliate_verification" ||
      row?.status === "pending_invoice_approval" ||
      row?.status === "approved_by_affiliate" ||
      row?.status === "token_downloaded_by_affiliate"
  );
  const handleInvoiceApproveBtn = async (e, index, tokenNumber) => {
    // console.log(tokenNumber, index);
    try {
      await requestForFinanceApprovalOrRejection({
        selectedToken: [`${tokenNumber}`],
        notSelectedToken: [],
        selectAll: true,
        type: "approve",
        remarks: "",
      });
      dispatchRequest();
      successToast("Successfully approved.");
    } catch {
      errorToast("Something went wrong.");
    }

    // if (tokenNumber) {
    //     dispatch(
    //         ApprovedRejectedInvoiceByFinance({
    //             token_no: tokenNumber,
    //             new_status: "invoice_approved",
    //         })
    //     ).then(() => {
    //         successToast("Successfully approved.");
    //         setTokenNumber((tokenNumber) => !tokenNumber);
    //     });
    // }
  };

  const handleCancle = async () => {
    if (reason.length > 0) {
      try {
        await requestForFinanceApprovalOrRejection({
          selectedToken: [`${token}`],
          notSelectedToken: [],
          selectAll: true,
          type: "reject",
          remarks: reason,
        });
        errorToast("Token Cancelled successfully.");
        dispatchRequest();
      } catch {
        errorToast("Something went wrong.");
      }
      setRejectModal(false);
    } else {
      errorToast("Please enter reason.");
    }
  };

  const handleCancleBtn = (index, tokenNumber) => {
    console.log("clickBtn");
    toggle1();
    setToken(tokenNumber);
  };

  const handleInvoiceRejectBtn = () => { };
  const handleInvoiceRejectFinance = () => { };

  // Function to save tableData to localStorage
  const saveTableDataToLocalStorage = (data) => {
    // localStorage.setItem("tableData", JSON.stringify(data));
  };

  // Utility function to format the date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const initialData = () => {
    // Get the "ToDate" of the last row in tableData
    // const lastToDate = tokenList?.length > 0 ? new Date(tokenList[tokenList?.length - 1].to_date) : null;

    // Calculate the next day by adding one day to the lastToDate
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Calculate the current date
    const currentDate = new Date();
    currentDate?.setDate(currentDate?.getDate() + 1);

    // Format the dates as strings
    const formattedFromDate = formatDate(firstDayOfMonth);
    const formattedToDate = formatDate(currentDate);

    setFromDate(formattedFromDate);
    setToDate(formattedToDate);
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    const today = new Date();
    const formattedToday = formatDate(today);

    // Check if "ToDate" is greater than the current date
    if (toDate >= formattedToday) {
      errorToast("The toDate cannot be greater than the current date or equal to it.");
    } else {
      setModalTokenScreen(!modalTokenScreen);
      toggle2();
    }
  };
  useEffect(() => {
    // Load tableData from localStorage when the component mounts
    const storedTableData = localStorage.getItem("tableData");
    if (storedTableData !== null) {
      // setTableData(JSON.parse(storedTableData));
    } else {
      // Initialize tableData as an empty array
      setTableData([]);
    }
  }, []);

  //Payment PopUp Modal
  const [paymentModal, setPaymentModal] = useState(false);
  const [isUploadInvoice, setIsUploadInvoice] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [firstDownloadUrl, setFirstDownloadUrl] = useState();
  const [firstDownloadRow, setFirstDownloadRow] = useState();

  const toggle = () => {
    setPaymentModal(!paymentModal);
  };
  const toggle2 = () => {
    setGenerateTokenModal(!generateTokenModal);
  };
  const handleViewPaymentDetails = () => {
    toggle();
  };
  const toggle8 = () => {
    setModalTokenScreen(!modalTokenScreen);
  };
  const handleSubmit = (e, index) => {
    e.preventDefault();
    if (invoiceAmount !== "11800") {
      errorToast("The payment amount does not match the total commission paid amount");
      return;
    }
    const updatedObject = {
      ...tableData[index],
      status: "Payment Successful",
    };
    const newArray = [...tableData];
    newArray[index] = updatedObject;
    successToast("Payment done successfully");
    setTableData(newArray);
    saveTableDataToLocalStorage(newArray);
    toggle();
  };

  const uploadToggle = () => {
    setIsUploadInvoice(!isUploadInvoice);
  };

  const downloadToggle = (url, row) => {
    setFirstDownloadUrl(url);
    setFirstDownloadRow(row);
    setIsDownload(!isDownload);
  };

  const handleUpload = (row) => {
    console.log("row", row);
    uploadToggle();
    setNetPayableAmount(row.token_data.affiliate_commission_with_gst);
    setSelectedToken(row.token_data.token_no.replace("T", ""));
    setFiles([]);
  };

  const handleDownloadTokenAffiliate = async (url, row, status) => {
    // downloadToggle();
    setSelectedDownload(url);
    setIsUpload(false);
    setTokenNumber(row?.token_no);
    setNetPayableAmount(row?.affiliate_commission_with_gst);
    // setCurrentStatus(row?.status);

    //open Popup for download token only first time
    if (status === "Sent for Affiliate Verification" || status === "Invoice Rejected by Finance") {
      downloadToggle(url, row);
    } else {
      const handleDownloadMultiple = await Promise.all(
        url.map(async (url, index) => {
          const pathname = new URL(url).pathname;
          const fileName = pathname?.split("/")?.pop();
          let res = await requestForS3FileFromUrl({ url: url });
          const blob = new Blob([res.data], { type: "application/octet-stream" });
          const objectUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = objectUrl;
          downloadLink.download = fileName; // Specify the desired file name
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          // const pathname = new URL(url).pathname;
          // const fileName = pathname?.split("/")?.pop();
          // let res = await requestForS3FileFromUrl({ url: url });
          // const objectUrl = URL.createObjectURL(res.data);
          // const downloadLink = document.createElement("a");
          // downloadLink.href = objectUrl;
          // downloadLink.download = fileName; // Specify the desired file name
          // downloadLink.style.display = "none";
          // document.body.appendChild(downloadLink);
          // downloadLink.click();
        })
      );
      setCurrentIndex((currentIndex) => !currentIndex);
    }
  };

  const handleSuccessfullyUploaded = async (e, i) => {
    e.preventDefault();
    if (invoiceAmount != netPayableAmount) {
      errorToast("Invoice amount does not match the total commission paid amount");
      return;
    }
    if (files.length > 0) {
      setLoader(true);
      try {
        const res = await requestForDownloadOrUploadTokenByAffiliate({
          invoice_no: uploadInvoiceNumber,
          invoice_date: uploadInvoiceDate,
          is_upload: true,
          token_no: selectedToken,
          img: files,
          affiliate_id: userData?.affiliate_id,
          status: "pending_invoice_approval",
        });
        if (res?.status === 200) {
          successToast("Document uploaded successfully");
          setCurrentIndex((currentIndex) => !currentIndex);
          uploadToggle();
          dispatchRequest();
          setLoader(false);
        } else {
          setLoader(false);
          errorToast(res?.message);
        }
      } catch (err) {
        setLoader(false);
        console.log("err:", err);
      }
    } else {
      errorToast("Please upload attachment");
    }
  };

  const handleInvoice = (e) => {
    const val = e.target.value;
    setInvoiceAmount(val);
  };

  const handleDownloadFirst = async (token_no) => {
    const response = await requestForAllAffiliateTokenList({
      from_date: fromDate,
      to_date: toDate,
      university: filterCriteria?.university,
      status: filterCriteria?.status,
      search_key: filterCriteria?.search_key,
      skip: (currentPage - 1) * recordsPerPage,
      limit: recordsPerPage,
      affiliate_id: affiliateId,
    });
    const result = await response.result?.find((item) => item.token_data.token_no === token_no)?.token_data;
    const mappedUrl = [result.anexure_pdf_url, result.csv_url];
    await Promise.all(
      mappedUrl.map(async (url, index) => {
        const pathname = new URL(url).pathname;
        const fileName = pathname?.split("/")?.pop();
        let res = await requestForS3FileFromUrl({ url: url });
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        const objectUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = objectUrl;
        downloadLink.download = fileName; // Specify the desired file name
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    );
    setCurrentIndex((currentIndex) => !currentIndex);
    dispatchRequest();
    setLoader(false);
    downloadToggle();
  };

  const handleDownloadSubmitBtn = async (e, url, row, status) => {
    setLoader(false);
    // console.log(invoiceNumber, invoiceDate, invoiceAmount, tokenNumber, "selectedDownload :", selectedDownload);
    e.preventDefault();
    // setSelectedDownload(url);
    // setIsUpload(false);
    setTokenNumber(row?.token_no);
    // setNetPayableAmount(row?.affiliate_commission_with_gst);
    // console.log("Token", url, row)
    try {
      setLoader(true);
      if (invoiceAmount != netPayableAmount) {
        setLoader(false);
        errorToast("Invoice amount is not matching with total commission paid amount.");
        return;
      }
      // dispatch(
      //     downloadUploadTokenInvoiceByAffiliate({
      //         invoice_no: invoiceNumber,
      //         invoice_date: invoiceDate,
      //         is_upload: false,
      //         token_no: tokenNumber,
      //         affiliate_id: userData?.affiliate_id,
      //         status: "8",
      //     })
      // );

      const handledToken = await requestForDownloadOrUploadTokenByAffiliate({
        invoice_no: invoiceNumber,
        invoice_date: invoiceDate,
        is_upload: false,
        token_no: tokenNumber.replace("T", ""),
      });
      if (handledToken) {
        dispatchRequest();
        handleDownloadFirst(row?.token_no);
      } else {
        errorToast("Duplicate Invioce Number.")
      }
      setLoader(false);
      // const handleDownloadMultiple = await Promise.all(url.map(async (url, index) => {
      //     const pathname = new URL(url).pathname;
      //     const fileName = pathname?.split("/")?.pop();
      //     let res = await requestForS3FileFromUrl({ url: url });
      //     const blob = new Blob([res.data], { type: "application/octet-stream" });
      //     const objectUrl = URL.createObjectURL(blob);
      //     const downloadLink = document.createElement("a");
      //     downloadLink.href = objectUrl;
      //     downloadLink.download = fileName; // Specify the desired file name
      //     downloadLink.style.display = "none";
      //     document.body.appendChild(downloadLink);
      //     downloadLink.click();
      // }))
      // setCurrentIndex((currentIndex) => !currentIndex);
      // dispatchRequest();
      // setLoader(false);
      // downloadToggle();
    } catch (err) {
      setLoader(false);
      console.log("err", err);
    }
  };

  const addNewAttachment = (event) => {
    let uploadedFile = event.target.files[0];
    if (uploadedFile) {
      if (uploadedFile === "application/pdf" || uploadedFile?.name?.endsWith(".pdf")) {
        setFiles((file) => [...file, uploadedFile]);
      } else {
        errorToast("Please select a PDF file.");
        uploadedFile = null;
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleRemoveFile = (id, ind) => {
    setFiles((preFile) =>
      preFile.filter((file, index) => {
        return index !== ind;
      })
    );
  };
  const handleStatusChange = async (e) => {
    // console.log("e.target.value :", e.target.value, "affiliate_id:", userData?.affiliate_id, "affiliateinternalID :", affiliateinternalID, "tokenList :", tokenList);
    const selectedValue = e.target.value;
    // const affiliateId = userData?.affiliate_id || affiliateinternalID;
    setSelectedValue(selectedValue);
    dispatch(
      reduxAllAffiliateTokenList({
        ...filterCriteria,
        // from_date: fromDate,
        // to_date: toDate,
        university: filterCriteria?.university || "",
        status: filterCriteria?.status || "",
        // status: filterCriteria?.status,
        // search_key: searchInput,
        // skip: (currentPage - 1) * recordsPerPage,
        // limit: recordsPerPage,
      })
    );
  };

  const handleEditToken = (e, token, fromDate, toDate) => {
    e.preventDefault();
    seEditToken(token);
    setTokenFromDate(moment(fromDate).format("YYYY-MM-DD"));
    setTokenToDate(moment(toDate).format("YYYY-MM-DD"));
    // toggle2();
    setModalTokenScreen(!modalTokenScreen);
    // toggle2();
  };
  console.log("XX", tokenFromDate, tokenToDate);
  //for Finance and Finance Head screen
  const handleViewToken = (e, url) => {
    e.preventDefault();
    window.open(`/pdf/view?url=${url}&type=svg`, "_blank");
  };
  //for affiliate screen
  const handleViewTokenAffiliate = (e, url) => {
    e.preventDefault();
    window.open(`/pdf/view?url=${url}&type=svg`, "_blank");
  };

  const handleTokenDownloadFinance = async (url) => {
    const handleDownloadMultiple = await Promise.all(
      url.map(async (url, index) => {
        const pathname = new URL(url).pathname;
        const fileName = pathname?.split("/")?.pop();
        let res = await requestForS3FileFromUrl({ url: url });
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        const objectUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = objectUrl;
        downloadLink.download = fileName; // Specify the desired file name
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    );
  };
  const handleOnchangePagination = (e, pageNumber) => {
    // console.log("pageNumber inside function :",pageNumber);
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  const handleInvoiceViewByFinance = (e, url) => {
    e.preventDefault();
    // navigate(`/pdf/view`, { state: { url: url } });
    window.open(`/pdf/view?url=${url}&type=svg`, "_blank");
  };
  // console.log("pageNumber outside function :",pageNumber);

  const handleInvoiceDownloadByFinance = async (e, url) => {
    const pathname = new URL(url).pathname;
    const fileName = pathname?.split("/")?.pop();
    let res = await requestForS3FileFromUrl({ url: url });
    const blob = new Blob([res.data], { type: "application/octet-stream" });
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = objectUrl;
    downloadLink.download = fileName; // Specify the desired file name
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  const handleInvoiceViewAffiliate = (e, invoiceUrl) => {
    e.preventDefault();
    window.open(`/pdf/view?url=${invoiceUrl}&type=svg`, "_blank");
  };

  const handleInvoiceDownloadAffiliate = async (e, invoiceUrl) => {
    const pathname = new URL(invoiceUrl).pathname;
    const fileName = pathname?.split("/")?.pop();
    let res = await requestForS3FileFromUrl({ url: invoiceUrl });
    const blob = new Blob([res.data], { type: "application/octet-stream" });
    const objectUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = objectUrl;
    downloadLink.download = fileName; // Specify the desired file name
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };
  const getCountByStatus = (targetStatus) => {
    const statusObject = _.find(all_status_count, { status: targetStatus });
    console.log(statusObject, "object");
    return statusObject ? statusObject.status_count : 0;
  };
  return (
    <>
      <Modal isOpen={rejectModal} size="md">
        <ModalHeader toggle={toggle1}>
          <Label className="text-danger">Are you sure you want to reject the request?</Label>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="exampleText">
              Reason<span className="text-danger">*</span>
            </Label>
            <Input
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              id="exampleText"
              name="text"
              type="textarea"
              placeholder="Type your reason"
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCancle}>
            Yes
          </Button>
          <Button color="danger" onClick={toggle1}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardBody>
          <Modal isOpen={generateTokenModal} size="md">
            <ModalHeader toggle={toggle2}>
              <Label>Select Date Range to Generate Token</Label>
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={(e) => handleAddRow(e)}>
                <Row>
                  <Col>
                    <FormGroup>
                      <span>From Date</span>
                      <Input
                        type="date"
                        className="form-control"
                        value={fromDate || ""}
                        onChange={(e) => setFromDate(e.target.value)}
                      // disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <span>To Date</span>
                      <Input type="date" className="form-control" value={toDate || ""} onChange={(e) => setToDate(e.target.value)} required />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex align-items-center justify-content-end">
                  <Button color="primary" type="submit" className="me-3">
                    Submit
                  </Button>
                  <Button color="danger" onClick={toggle2}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
          {userData.role === roles.AFFILIATE && (
            <>
              <Row>
                <Col xs={12} sm={9} md={6} lg={3}>
                  <div className="input-group mb-3">
                    <span className="input-group-text border-0" id="basic-addon1">
                      Affiliate ID :
                    </span>
                    <span className="input-group-text border-0" id="basic-addon1">
                      {affiliateId}
                    </span>
                  </div>
                </Col>
                <Col xs={12} sm={9} md={6} lg={3}>
                  <FormGroup>
                    <Input
                      type="select"
                      className="form-select"
                      value={filterCriteria?.status}
                      onChange={(e) => {
                        setFilterCriteria({ ...filterCriteria, status: e.target.value });
                      }}
                    >
                      <option value="">Status</option>
                      {AffiliateTokenStatus.map((status) => {
                        return <option value={status.code}>{status.type}</option>;
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col xs={12} md={6} lg={3} sm={9}>
                  <FormGroup>
                    <Button
                      color="primary"
                      //    onClick={handleFilter}
                      type="button"
                    >
                      Apply
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      //   onClick={handleReset}
                      className="ms-2"
                    >
                      Reset
                    </Button>
                  </FormGroup>
                </Col> */}
                {/* <Col>
                  {userData.role === roles.FINANCE_EXECUTIVE && (
                    <>
                      {!isAnyPaymentApproved && tableData?.length > 0 && (
                        <Button className="btn float-end" color="danger" onClick={handleGenerateToken}>
                          Generate New Token
                        </Button>
                      )}
                    </>
                  )}
                </Col> */}
              </Row>
              <div className="table-responsive table-container">
                <Table className="text-nowrap mt-3 mb-2 align-middle custom-table" bordered>
                  <thead className="bg-primary text-white">
                    <tr>
                      <th className="thcol00">S.No.</th>
                      <th className="thcol01">Token Number</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>No. Of Students</th>
                      <th>Fees Deposited Amount</th>
                      <th>Commission Amount</th>
                      <th>Subvention Cost Share %</th>
                      <th>Subvention Cost </th>
                      <th>CGST(9%)</th>
                      <th>IGST(18%)</th>
                      <th>SGST(9%)</th>
                      <th>GST (18%)</th>
                      <th>TDS</th>
                      <th>Total Amount With GST</th>
                      <th>Token</th>
                      <th>Invoice</th>
                      <th>Status</th>
                      <th>Actions</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.length > 0 ? (
                      <>
                        {tableData?.map((row, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td className="thcol00">{row?.token_data?.serial_no ? row.serial_no : (currentPage - 1) * recordsPerPage + i + 1}</td>
                                <td className="thcol01">{row?.token_data?.token_no}</td>
                                <td className="text-end">{moment(row?.token_data?.token_from_date).format("YYYY-MM-DD")}</td>
                                <td className="text-end">{moment(row?.token_data?.token_to_date).format("YYYY-MM-DD")}</td>
                                <td className="text-end">{row?.token_data?.students_under_this_token}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.total_fee_deposited)}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.affiliate_commission)}</td>
                                <td className="text-end">{row?.token_data?.subvention_percenatge}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.subvention_cost)}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.c_gst_amount || "NA")}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.i_gst_amount || "NA")}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.s_gst_amount || "NA")}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.gst_amount)}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.tds_amount || "NA")}</td>
                                <td className="text-end">{formatNumberForIndia(row?.token_data?.affiliate_commission_with_gst)}</td>
                                <td>
                                  {row?.token_data?.token_status !== "2" ? (
                                    <Link className="me-4" onClick={(e) => handleViewTokenAffiliate(e, row?.token_data?.anexure_pdf_url)}>
                                      <Icon.Eye size={20} />
                                    </Link>
                                  ) : (
                                    <span className="me-4">
                                      <Icon.EyeOff size={20} />
                                    </span>
                                  )}
                                  <Link>
                                    <Icon.Download
                                      size={20}
                                      onClick={() => {
                                        handleDownloadTokenAffiliate(
                                          [row?.token_data?.csv_url, row?.token_data?.anexure_pdf_url],
                                          row?.token_data,
                                          _.find(AffiliateTokenStatus, { code: row?.token_data?.token_status })?.type
                                        );
                                      }}
                                    />
                                  </Link>
                                  <Modal isOpen={isDownload} size="md">
                                    <ModalHeader toggle={downloadToggle}>
                                      <b>Download Token </b>
                                    </ModalHeader>
                                    <ModalBody>
                                      {/* <Form > */}
                                      <FormGroup>
                                        <Label>Invioce Number</Label>
                                        <Input
                                          type="text"
                                          className="mb-3"
                                          placeholder="Please Enter Invioce Number"
                                          maxLength={50}
                                          required
                                          onChange={(e) => {
                                            setInvoiceNumber(e.target.value);
                                          }}
                                        />
                                        <Label>Invioce Date</Label>
                                        <Input
                                          type="date"
                                          className="mb-3"
                                          // placeholder="Please Enter Invioce Number"
                                          maxLength={12}
                                          required
                                          onChange={(e) => {
                                            setInvoiceDate(e.target.value);
                                          }}
                                        />
                                        <Label>Invioce Amount</Label>
                                        <Input
                                          name="invoiceAmount"
                                          type="number"
                                          className="mb-3"
                                          placeholder="Please Enter Invioce Amount"
                                          maxLength={50}
                                          onChange={(e) => handleInvoice(e)}
                                          required
                                        />

                                        <div className="d-flex align-items-center justify-content-end">
                                          {/* <a className="btn btn-primary float-right"> */}
                                          <button type="submit" className="btn btn-primary float-right">
                                            <Icon.Download
                                              onClick={(e) => {
                                                handleDownloadSubmitBtn(e, firstDownloadUrl, firstDownloadRow);
                                              }}
                                              // onClick={(e) => handleDownloadSubmitBtn(e, i)}
                                              size={20}
                                            />
                                          </button>
                                        </div>
                                      </FormGroup>
                                      {/* </Form> */}
                                      {loader && (
                                        <div className="position-relative text-primary" style={{ left: "50%", bottom: "48%" }}>
                                          <Spinner className="m-auto" />
                                        </div>
                                      )}
                                    </ModalBody>
                                  </Modal>
                                </td>
                                <td className="text-center">
                                  {row?.token_data?.token_status == "8" ? (
                                    <>
                                      <Link>
                                        <Icon.Eye
                                          size={20}
                                          className="me-4"
                                          onClick={(e) => handleInvoiceViewAffiliate(e, row?.token_data?.invoice_url)}
                                        />
                                      </Link>
                                      <Link>
                                        <Icon.Download
                                          className="me-4"
                                          size={20}
                                          onClick={(e) => handleInvoiceDownloadAffiliate(e, row?.token_data?.invoice_url)}
                                        />
                                      </Link>

                                      <Modal isOpen={isDownload} size="md">
                                        <ModalHeader toggle={downloadToggle}>
                                          <b>Download Token </b>
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form onSubmit={(e) => handleDownloadSubmitBtn(e, i)}>
                                            <FormGroup>
                                              <Label>Invioce Number</Label>
                                              <Input
                                                type="text"
                                                className="mb-3"
                                                placeholder="Please Enter Invioce Number"
                                                maxLength={50}
                                                required
                                                onChange={(e) => {
                                                  setInvoiceNumber(e.target.value);
                                                }}
                                              />
                                              <Label>Invioce Date</Label>
                                              <Input
                                                type="date"
                                                className="mb-3"
                                                // placeholder="Please Enter Invioce Number"
                                                maxLength={12}
                                                required
                                                onChange={(e) => {
                                                  setInvoiceDate(e.target.value);
                                                }}
                                              />
                                              <Label>Invioce Amount</Label>
                                              <Input
                                                name="invoiceAmount"
                                                type="number"
                                                className="mb-3"
                                                placeholder="Please Enter Invioce Amount"
                                                maxLength={50}
                                                onChange={(e) => handleInvoice(e)}
                                                required
                                              />

                                              <div className="d-flex align-items-center justify-content-end">
                                                {/* <a className="btn btn-primary float-right"> */}
                                                <button type="submit" className="btn btn-primary float-right">
                                                  <Icon.Download onClick={(e) => handleDownloadSubmitBtn(e, i)} size={20} />
                                                </button>
                                              </div>
                                            </FormGroup>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.status !== "token_downloaded_by_affiliate" ? (
                                    row?.token_data?.token_status !== "6" ? (
                                      row?.token_data?.token_status !== "4" ? (
                                        row?.token_data?.token_status !== "9" ? (
                                          row?.token_data?.token_status !== "2" ? (
                                            <Icon.Upload
                                              size={20}
                                              className="text-primary cursor-pointer"
                                              onClick={() => handleUpload(row)}
                                              hidden={row?.token_data?.token_status == "8"}
                                            ></Icon.Upload>
                                          ) : (
                                            "NA"
                                          )
                                        ) : (
                                          "NA "
                                        )
                                      ) : (
                                        "NA "
                                      )
                                    ) : (
                                      "NA"
                                    )
                                  ) : (
                                    "NA"
                                  )}
                                  {row?.status !== "Payment Successful" && <div className="text-success">{`${isSuccessfully}`}</div>}
                                  <Modal isOpen={isUploadInvoice} size="md">
                                    <ModalHeader toggle={uploadToggle}>
                                      <b>Invoice Details </b>
                                    </ModalHeader>
                                    <ModalBody>
                                      <Form onSubmit={(e) => handleSuccessfullyUploaded(e, i)}>
                                        <FormGroup>
                                          <Label>Invioce Number</Label>
                                          <Input
                                            type="text"
                                            className="mb-3"
                                            placeholder="Please Enter Invioce Number"
                                            maxLength={50}
                                            onChange={(e) => {
                                              setUploadInvoiceNumber(e.target.value);
                                            }}
                                            required
                                          />
                                          <Label>Invioce Date</Label>
                                          <Input
                                            type="date"
                                            className="mb-3"
                                            placeholder="Please Enter Invioce Number"
                                            maxLength={12}
                                            onChange={(e) => {
                                              setUploadInvoiceDate(e.target.value);
                                            }}
                                            required
                                          />
                                          <Label>Invioce Amount</Label>
                                          <Input
                                            name="invoiceAmount"
                                            type="text"
                                            className="mb-3"
                                            placeholder="Please Enter Invioce Amount"
                                            value={invoiceAmount}
                                            // maxLength={12}
                                            onChange={(e) => {
                                              let val = e.target.value;
                                              console.log("Input Value:", val); // Log input value
                                              let newValue = val.replace(/,/g, "");
                                              console.log("New Value:", newValue); // Log new value without commas
                                              setInvoiceAmount(newValue);
                                            }}
                                            // onChange={(e) => handleInvoice(e)}
                                            required
                                          />
                                          {/* <Label>Upload</Label> */}
                                          {/* <Input id="exampleFile" className="mb-3" name="file" type="file" accept=".pdf,.doc" required /> */}
                                          {files?.map((file, index) => {
                                            return (
                                              <Row key={index} title={file.name.length > 15 ? file.title : null}>
                                                <Col className="text-success">
                                                  file {index} : {file.name}
                                                </Col>
                                                <Col>
                                                  <Button color="danger" className="mt-2" onClick={() => handleRemoveFile(file.id, index)}>
                                                    Remove
                                                  </Button>
                                                </Col>
                                              </Row>
                                            );
                                          })}
                                          <div className="">
                                            <input
                                              ref={fileInputRef}
                                              style={{ display: "none" }}
                                              type="file"
                                              onChange={addNewAttachment}
                                              // required
                                              accept=".pdf"
                                            />
                                            <Link onClick={handleButtonClick} color="primary">
                                              +Add Attachment
                                              <span style={{ width: "30px", color: "red" }}>*</span>
                                            </Link>
                                          </div>
                                        </FormGroup>
                                        <div className="d-flex align-items-center justify-content-end">
                                          <Button color="primary" type="submit" className="float-right">
                                            Submit
                                          </Button>
                                        </div>
                                      </Form>
                                      {loader && (
                                        <div className="position-relative text-primary" style={{ left: "50%", bottom: "48%" }}>
                                          <Spinner className="m-auto" />
                                        </div>
                                      )}
                                    </ModalBody>
                                  </Modal>
                                  {/* <UploadFile index={i} status={row.status} getSelectedFile={getSelectedFile} setDataURL={setDataURL} /> */}
                                  {/* {files?.map((file, index) => {
                                    if (row?.token_no === selectedToken) {
                                      return (
                                        <Row key={index} title={file?.name?.length > 15 ? file?.title : null}>
                                          <Col className="text-success">{file?.name}</Col>
                                        </Row>
                                      );
                                    }
                                  })} */}
                                </td>

                                <td className={`text-start text-${_.find(AffiliateTokenStatus, { code: row?.token_data?.token_status })?.color}`}>
                                  {_.find(AffiliateTokenStatus, { code: row?.token_data?.token_status })?.type}
                                </td>
                                <td>
                                  <Button
                                    className="me-2"
                                    color="primary"
                                    size="sm"
                                    onClick={() => {
                                      handleTokenApproveBtn(i, row?.token_data?.token_no?.replace("T", ""));
                                    }}
                                    disabled={
                                      // row?.token_data?.token_status == "6" ||
                                      // row?.status == "invoice_approved" ||
                                      // row?.status == "rejected_by_affiliate" ||
                                      // row?.status == "approved_by_affiliate" ||
                                      // row?.status == "send_for_affiliate_verification" ||
                                      // row?.status == "pending_invoice_approval" ||
                                      row?.token_data?.token_status === "6" ||
                                      row?.token_data?.token_status === "7" ||
                                      row?.token_data?.token_status === "3" ||
                                      row?.token_data?.token_status === "2" ||
                                      row?.token_data?.token_status === "8" ||
                                      row?.token_data?.token_status === "4"
                                    }
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() => handleTokenRejectBtn(i, row?.token_data?.token_no.replace("T", ""))}
                                    disabled={
                                      // row?.status == "invoice_rejected" ||
                                      // row?.status == "invoice_approved" ||
                                      // row?.status == "rejected_by_affiliate" ||
                                      // row?.status == "approved_by_affiliate" ||
                                      // row?.status == "send_for_affiliate_verification" ||
                                      // row?.status == "pending_invoice_approval" ||
                                      row?.token_data?.token_status === "6" ||
                                      row?.token_data?.token_status === "7" ||
                                      row?.token_data?.token_status === "3" ||
                                      row?.token_data?.token_status === "2" ||
                                      row?.token_data?.token_status === "8" ||
                                      row?.token_data?.token_status === "4"
                                    }
                                  >
                                    Reject
                                  </Button>
                                  <Button
                                    className="ms-2"
                                    color="primary"
                                    size="sm"
                                    onClick={() => handleTokenSaveBtn(i)}
                                    hidden={!selectedFile || row?.status === "Payment Successful"}
                                    disabled={row?.status === "Payment Successful" || row?.status !== "Sent for Affiliate Verification"}
                                  >
                                    Save
                                  </Button>
                                </td>
                                {/* {console.log(row?.token_data?.remarks[0]?.remarks === "undefined", "gggg")} */}
                                <td>
                                  {row?.token_data?.token_status === "4" || row?.token_data?.token_status === "7"
                                    ? row?.token_data?.remarks[0]?.remarks === "undefined"
                                      ? "NA"
                                      : row?.token_data?.remarks[0]?.remarks || "NA"
                                    : "NA"}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={19}>
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                    {/* <Modal isOpen={rejectModal} size="md">
                      <ModalHeader toggle={toggle1}>
                        <b>Rejection Remark </b>
                      </ModalHeader>
                      <ModalBody>
                        <Form onSubmit={(e) => handleSuccessfullyUploaded(e)}>
                          <FormGroup>
                            <Label>Please enter remark </Label>
                            <Input
                              type="text"
                              className="mb-3"
                              placeholder="Please enter remark"
                              maxLength={12}
                              onChange={(e) => {
                                setRemark(e.target.value);
                              }}
                              required
                            />
                          </FormGroup>
                          <ModalFooter>
                            <Button color="danger" onClick={handleConfirmDeleteReject}>
                              Yes
                            </Button>{" "}
                            <Button color="secondary" onClick={handleCancelDeleteReject}>
                              No
                            </Button>
                          </ModalFooter>
                        </Form>
                      </ModalBody>
                    </Modal> */}
                    <Modal isOpen={tokenRejectModal} size="md">
                      <ModalHeader toggle={toggleReject}>
                        <Label className="text-danger">Are you sure you want to reject the request?</Label>
                      </ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <Label for="exampleText">
                            Reason<span className="text-danger">*</span>
                          </Label>
                          <Input
                            value={tokenRejectReason}
                            onChange={(e) => setTokenRejectReason(e.target.value)}
                            id="exampleText"
                            name="text"
                            type="textarea"
                            placeholder="Type your reason"
                            required
                          />
                        </FormGroup>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={handleConfirmDeleteReject}>
                          Yes
                        </Button>
                        <Button color="danger" onClick={handleCancelDeleteReject}>
                          No
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* <ConfirmationModal
                      isOpen={showReject}
                      messageBody={"Are you sure, You want to Reject?"}
                      handleConfirm={handleConfirmDeleteReject}
                      handleCancel={handleCancelDeleteReject}
                    /> */}

                    <ConfirmationModal
                      isOpen={showApprove}
                      messageBody={"Are you sure, You want to Approve?"}
                      handleConfirm={handleConfirmDeleteApprove}
                      handleCancel={handleCancelDeleteApprove}
                    />
                    {showAlert && (
                      <AlertModal isOpen={showAlert} messageBody={"Invoice Data Successfully Stored."} handleCancel={handleCancelAlert} />
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}
          {userData.role !== roles.AFFILIATE && (
            <>
              <Form>
                <>
                  <Row>
                    <Col lg={3}>
                      <FormGroup>
                        <span>From Date</span>
                        <Input type="date" className="form-control" value={fromDate || ""} onChange={(e) => setFromDate(e.target.value)} required />
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="9" md="6" lg="3">
                      <FormGroup>
                        <span>To Date</span>
                        <Input type="date" className="form-control" value={toDate || ""} onChange={(e) => setToDate(e.target.value)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <FormGroup>
                        <Input
                          type="select"
                          className="form-select bg-white"
                          value={filterCriteria?.university}
                          onChange={(e) => {
                            console.log(filterCriteria);
                            setFilterCriteria({ ...filterCriteria, university: e.target.value });
                          }}
                        >
                          {/* <option value="">Select Session</option> */}
                          <option value="">Select University</option>
                          {universityList?.map((university) => (
                            <option key={university?.id} value={university?.id}>
                              {university?.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup>
                        <Input
                          type="select"
                          className="form-select"
                          value={filterCriteria?.status}
                          onChange={(e) => {
                            setFilterCriteria({ ...filterCriteria, status: e.target.value });
                          }}
                        >
                          <option value="">Status</option>
                          {AffiliateTokenStatus.map((status) => {
                            return <option value={status.code}>{status.type}</option>;
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center">
                    <Col lg={6}>
                      <FormGroup>
                        <Input
                          type="text"
                          className="custom-input lh-lg"
                          placeholder="Search by Token Number/  Affiliate Name / Affiliate Mobile / Company Name / Affiliate Email / NetSuite Internal ID/ NetSuite Vendor id"
                          value={filterCriteria?.search_key}
                          onChange={(e) => {
                            console.log(filterCriteria);
                            setFilterCriteria({ ...filterCriteria, search_key: e.target.value });
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={8} md={4} lg={3} className="order-3 order-sm-3 order-md-2 order-lg-2">
                      <FormGroup>
                        <Button color="primary" onClick={handleFilter}>
                          Search
                        </Button>
                        <Button color="primary" className="mx-2" onClick={handleReset}>
                          Clear
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-5 mb-5">
                    <Col lg="3">
                      <CardText className="d-flex justify-content-center align-items-center h-100">
                        Pending - Finance Verification: <span className="text-warning">&nbsp; {getCountByStatus("0")}</span>
                      </CardText>
                    </Col>
                    <Col lg="3">
                      <CardText className="d-flex justify-content-center align-items-center h-100">
                        Sent for Affiliate Verification: <span className="text-success">&nbsp; {getCountByStatus("2")}</span>
                      </CardText>
                    </Col>
                    <Col lg="3">
                      <CardText className="d-flex justify-content-center align-items-center h-100">
                        Cancelled by Finance: <span className="text-danger">&nbsp; {getCountByStatus("1")}</span>
                      </CardText>
                    </Col>
                    {/* <Col>
                      {userData.role === roles.FINANCE_EXECUTIVE && (
                        <>
                          {!isAnyPaymentApproved && tableData?.length > 0 && (
                            <Button
                              className="btn float-end"
                              color="danger"
                              onClick={handleGenerateToken}
                              disabled={is_generate_token === 0 || commission_structure_type === "slab"}
                            >
                              Generate New Token
                            </Button>
                          )}
                        </>
                      )}
                    </Col> */}
                  </Row>
                </>
              </Form>
              <Card>
                <CardBody>
                  <div className="table-responsive table-container">
                    <Table className="text-nowrap mt-3 mb-2 align-middle custom-table" bordered>
                      <thead className="bg-primary text-white">
                        <tr>
                          <th className="thcol00">S.No.</th>
                          <th className="thcol01">Token Number</th>
                          <th className="thcol02">Affiliate ID</th>
                          <th>Affiliate Name</th>
                          <th>Company Name</th>
                          <th>University</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>No. Of Students</th>
                          <th>NetSuite Internal ID</th>
                          <th>NetSuite Vendor id</th>
                          <th>Mobile Number</th>
                          <th>Token generated Date</th>
                          <th>Fees Deposited Amount</th>
                          <th>Actual Amount for Commission Calculation</th>
                          {/* <th>Fees Deposited Amount</th> */}
                          <th>Affiliate Commission</th>
                          {/* <th>Commission Amount</th> */}
                          <th>GST Amount(18%)</th>
                          <th>Total Amount With GST</th>
                          <th>GST No.</th>
                          <th>Invoice No.</th>
                          <th>Invoice Date</th>
                          {/* <th>Subvention Cost Share %...</th>
                          <th>Subvention Cost </th> */}
                          {/* <th>CGST(9%)</th>
                          <th>IGST(18%)</th>
                          <th>SGST(9%)</th> */}
                          {/* <th>TDS</th> */}
                          <th>Token</th>
                          <th>Invoice</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Actions</th>
                          <th>Action Date</th>
                          <th className="text-center">Action By</th>
                          <th>Remark</th>
                          {/* <th>Edit</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {tableData?.length > 0 ? (
                            <>
                              {tableData?.map((row, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td className="text-start thcol00">
                                        {row?.token_data?.serial_no ? row.serial_no : (currentPage - 1) * recordsPerPage + i + 1}
                                      </td>
                                      <td className="text-start thcol01">{row?.token_data?.token_no}</td>
                                      <td className="text-start thcol02">{row?.token_data?.affiliate_id}</td>
                                      <td className="text-start">{row?.token_data?.affiliate_details?.affiliate_name}</td>
                                      <td className="text-start">{row?.token_data?.affiliate_details?.affiliate_company_name}</td>
                                      <td className="text-start">{row?.token_data?.university_details?.university_name || "NA"}</td>
                                      <td className="text-start">{moment(row?.token_data?.token_from_date).format("YYYY-MM-DD") || "NA"}</td>
                                      <td className="text-start">{moment(row?.token_data?.token_to_date).format("YYYY-MM-DD") || "NA"}</td>
                                      <td className="text-end">{row?.token_data?.students_under_this_token || "NA"}</td>
                                      <td className="text-start">{row?.token_data?.affiliate_details?.affiliate_net_suite_id || "NA"}</td>
                                      <td className="text-start">{row?.token_data?.affiliate_details?.affiliate_vendor_id || "NA"}</td>
                                      <td className="text-end">{row?.token_data?.affiliate_details?.affiliate_mobile}</td>
                                      <td className="text-start">{moment(row?.token_data?.token_generated_date).format("DD-MM-YYYY")}</td>
                                      <td className="text-end">{formatNumberForIndia(row?.token_data?.total_fee_deposited)}</td>
                                      <td className="text-end">
                                        {formatNumberForIndia(row?.token_data?.actual_amount_for_commission_calculation) || "NA"}
                                      </td>
                                      <td className="text-end">{formatNumberForIndia(row?.token_data?.affiliate_commission) || "NA"}</td>
                                      <td className="text-end">{formatNumberForIndia(row?.token_data?.gst_amount) || "NA"}</td>


                                      <td className="text-end">{formatNumberForIndia(row?.token_data?.affiliate_commission_with_gst) || "NA"}</td>
                                      <td>{row?.token_data?.affiliate_details?.gst_number || "NA"}</td>
                                      <td>{row?.token_data?.invoice_no || "NA"}</td>
                                      <td>{row?.token_data?.invoice_date || "NA"}</td>
                                      {/* <td className="text-end">{row?.token_data?.subvention_percenatge || 0}</td>
                                      <td className="text-end">{formatNumberForIndia(row?.token_data?.subvention_cost) || "NA"}</td> */}


                                      {/* <td className="text-end">{row.no_of_students}</td>
                                      <td className="text-end">{formatNumberForIndia(row.fees_deposited_amount)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.commission_amount)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.subvention_perct)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.subvention_amount)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.cgst)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.igst)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.sgst)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.gst_amount)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.tds)}</td>
                                      <td className="text-end">{formatNumberForIndia(row.total_commission_paid_amount)}</td> */}
                                      <td>
                                        {row?.status !== "Sent for Affiliate Verification" ? (
                                          <>
                                            <Link className="me-4">
                                              <Icon.Eye
                                                size={20}
                                                onClick={(e) => {
                                                  handleViewToken(e, row?.token_data?.anexure_pdf_url);
                                                }}
                                              />
                                            </Link>

                                            <Link
                                              onClick={() => handleTokenDownloadFinance([row?.token_data?.csv_url, row?.token_data?.anexure_pdf_url])}
                                            >
                                              <Icon.Download size={20} />
                                            </Link>
                                          </>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                      <td>
                                        {row?.token_data?.token_status !== "0" ? (
                                          row?.token_data?.token_status !== "3" ? (
                                            row?.token_data?.token_status !== "4" ? (
                                              row?.token_data?.token_status !== "1" ? (
                                                row?.token_data?.token_status !== "2" ? (
                                                  row?.token_data?.token_status !== "9" ? (
                                                    <>
                                                      <Link className="me-4 ms-4">
                                                        <Icon.Eye
                                                          size={20}
                                                          onClick={(e) => handleInvoiceViewByFinance(e, row?.token_data?.invoice_url)}
                                                        />
                                                      </Link>
                                                      <Link>
                                                        <Icon.Download
                                                          size={20}
                                                          onClick={(e) => handleInvoiceDownloadAffiliate(e, row?.token_data?.invoice_url)}
                                                        />
                                                      </Link>
                                                    </>
                                                  ) : (
                                                    <Label className="display-flex">NA</Label>
                                                  )
                                                ) : (
                                                  "NA"
                                                )
                                              ) : (
                                                "NA"
                                              )
                                            ) : (
                                              "NA"
                                            )
                                          ) : (
                                            "NA"
                                          )
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                      <td
                                        className={`text-start text-${_.find(AffiliateTokenStatus, { code: row?.token_data?.token_status })?.color}`}
                                      >
                                        {_.find(AffiliateTokenStatus, { code: row?.token_data?.token_status })?.type}
                                      </td>
                                      <td>
                                        {row?.token_data?.token_status === "0" ||
                                          row?.token_data?.token_status === "1" ||
                                          row?.token_data?.token_status === "4" ? (
                                          <>
                                            {/* {row?.status !== "Invoice Verified" || row?.status !== "Invoice Verified" ? ( */}
                                            <Button
                                              className="me-2"
                                              color="primary"
                                              size="sm"
                                              onClick={(e) => {
                                                handleInvoiceApproveBtn(e, i, row?.token_data?.token_no.replace("T", ""));
                                              }}
                                              disabled={row?.token_data?.token_status !== "0" || userData.role === "finance_head"}
                                            >
                                              Verify
                                            </Button>
                                            <Button
                                              color="danger"
                                              className="me-2"
                                              size="sm"
                                              onClick={() => handleCancleBtn(i, row?.token_data?.token_no.replace("T", ""))}
                                              disabled={row?.token_data?.token_status !== "0" || userData.role === "finance_head"}
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              color="secondary"
                                              size="sm"
                                              onClick={(e) => handleEditToken(e, row?.token_data?.token_no.replace("T", ""), row?.token_data?.token_from_date, row?.token_data?.token_to_date)}
                                              disabled={userData.role === "finance_head"}
                                            >
                                              Edit
                                            </Button>

                                            {/* <Button color="primary" size="sm" onClick={handleViewPaymentDetails}>
                                              Mark Payment
                                            </Button> */}
                                          </>
                                        ) : (
                                          <>
                                            <Button
                                              className="me-2"
                                              color="primary"
                                              size="sm"
                                              style={{ width: "55%" }}
                                              onClick={() => {
                                                handleTokenApproveBtn(i, row?.token_data?.token_no?.replace("T", ""));
                                              }}
                                              disabled={
                                                userData.role === "finance_head" ||
                                                row?.token_data?.token_status === "6" ||
                                                row?.token_data?.token_status === "2" ||
                                                row?.token_data?.token_status === "4" ||
                                                row?.token_data?.token_status === "7" ||
                                                row?.token_data?.token_status === "9" ||
                                                row?.token_data?.token_status === "3"
                                              }
                                            >
                                              Approve
                                            </Button>
                                            <Button
                                              color="danger"
                                              size="sm"
                                              onClick={() => handleTokenRejectBtn(i, row?.token_data?.token_no.replace("T", ""))}
                                              disabled={
                                                userData.role === "finance_head" ||
                                                row?.token_data?.token_status === "6" ||
                                                row?.token_data?.token_status === "2" ||
                                                row?.token_data?.token_status === "4" ||
                                                row?.token_data?.token_status === "7" ||
                                                row?.token_data?.token_status === "9" ||
                                                row?.token_data?.token_status === "3"
                                              }
                                            >
                                              Reject
                                            </Button>

                                            <ConfirmationModal
                                              isOpen={showApprove}
                                              messageBody={"Are you sure, You want to Approve?"}
                                              handleConfirm={handleFinanceApprove}
                                              handleCancel={handleCancelDeleteApprove}
                                            />
                                            <Modal isOpen={tokenRejectModal} size="md">
                                              <ModalHeader toggle={toggleReject}>
                                                <Label className="text-danger">Are you sure you want to reject the request?</Label>
                                              </ModalHeader>
                                              <ModalBody>
                                                <FormGroup>
                                                  <Label for="exampleText">
                                                    Reason<span className="text-danger">*</span>
                                                  </Label>
                                                  <Input
                                                    value={tokenRejectReason}
                                                    onChange={(e) => setTokenRejectReason(e.target.value)}
                                                    id="exampleText"
                                                    name="text"
                                                    type="textarea"
                                                    placeholder="Type your reason"
                                                    required
                                                  />
                                                </FormGroup>
                                              </ModalBody>
                                              <ModalFooter>
                                                <Button color="primary" onClick={handleFinanceReject}>
                                                  Yes
                                                </Button>
                                                <Button color="danger" onClick={handleCancelDeleteReject}>
                                                  No
                                                </Button>
                                              </ModalFooter>
                                            </Modal>
                                          </>
                                        )}
                                      </td>
                                      <td className="text-start">{row?.token_data?.remarks[0] ? moment(row?.token_data?.remarks[0]?.remark_date).format("DD-MM-YYYY") : "NA"}</td>
                                      <td className="text-start">{row?.token_data?.remarks[0]?.remark_by?.remarker_name || "NA"}</td>
                                      <td className="text-start">
                                        {row?.token_data?.remarks[0]?.remarks === "undefined" ? "NA" : row?.token_data?.remarks[0]?.remarks || "NA"}
                                      </td>
                                      <Modal isOpen={paymentModal} size="md">
                                        <ModalHeader toggle={toggle}>
                                          <b>Payment </b>
                                        </ModalHeader>
                                        <ModalBody>
                                          <Form onSubmit={(e) => handleSubmit(e, i)}>
                                            <FormGroup>
                                              <Label>UTR Number</Label>
                                              <Input type="text" placeholder="Please Enter UTR Number" maxLength={12} required />
                                              <Label>Payment Date</Label>
                                              <Input type="date" className="mb-2" placeholder="Please Enter Invioce Number" maxLength={50} required />
                                              <Label>Payment Amount(Including GST)</Label>
                                              <Input
                                                type="text"
                                                className="mb-2"
                                                placeholder="Please Enter Invioce Amount"
                                                maxLength={50}
                                                required
                                                onChange={handleInvoice}
                                              />
                                            </FormGroup>
                                            <div className="d-flex align-items-center justify-content-end">
                                              <Button color="primary" type="submit" className="float-right">
                                                Submit
                                              </Button>
                                            </div>
                                          </Form>
                                        </ModalBody>
                                      </Modal>
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <tr>
                              <td colSpan={28}>
                                <div style={{ width: "35%" }}>
                                  <NoDataFound />
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardFooter>
                  {/* Pagination for token details here.................*/}

                  <div className="d-flex justify-content-between ">
                    {/* </div> */}
                    {/* <div className="d-flex justify-content-start"> */}
                    {/* <div className="position-absolute"> */}
                    <nav className="position-relative">
                      <Label>
                        {total_records <= currentPage * recordsPerPage ? (
                          <>{`Total ${total_records} out ${total_records}`}</>
                        ) : (
                          <>{`Total ${total_records >= 10 ? currentPage * recordsPerPage : total_records} out ${total_records}`}</>
                        )}
                      </Label>
                    </nav>
                    <div className="">
                      <nav className="position-relative">
                        <PaginationComponent currentPage={currentPage} totalPages={lastPage} onPageChange={handleOnchangePagination} />
                      </nav>
                    </div>
                    {/* </div> */}
                  </div>
                </CardFooter>
              </Card>
            </>
          )}
        </CardBody>
      </Card>
      {/* modal for generate token screen  */}
      <Modal isOpen={modalTokenScreen} toggle={toggle8.bind(null)} fullscreen>
        <ModalHeader toggle={toggle8.bind(null)} charcode="Y">
          {/* Affiliate Table */}
        </ModalHeader>
        <ModalBody>
          <GenerateTokenScreen
            affiliateId={editToken}
            affiliateinternalID={affiliateinternalID}
            successToast={successToast}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            setTableData={setTableData}
            tableData={tableData}
            saveGenerateToke={saveGenerateToken}
            setSaveGenerateToken={setSaveGenerateToken}
            maximizeButtonVisible={false}
            setModalTokenScreen={setModalTokenScreen}
            fromDate={tokenFromDate}
            toDate={tokenToDate}
            setUpdateTokenList={setUpdateTokenList}
            updateTokenList={updateTokenList}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Tokens;
