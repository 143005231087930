import * as Icon from "react-feather";
import { Button, Collapse } from "reactstrap";

const SidePanel = ({ isOpen, toggle, children }) => {
  return (
    <Collapse isOpen={isOpen}>
      <div className="side-panel right">
        <Button className="close-btn rounded-circle p-1" onClick={toggle} color="danger">
          <Icon.XCircle />
        </Button>

        {children}
      </div>
    </Collapse>
  );
};

export default SidePanel;
