import axiosInstance from "../config/axios/AxiosInstance";
import {
  ADD_AFFILIATE,
  AGREE_ONBOARDING_TERMS,
  ALL_AFFILIATE_TOKEN_LIST,
  AUTH_FORGOT_PASSWORD,
  FINANCE_APPROVE_REJECT_TOKEN,
  GET_AFFILIATE_LIST,
  GET_AFFILIATE_OWNER_LIST,
  IS_USER_LOGGED_IN,
  LOGIN,
  ON_BOARDING_RESET_VERIFY,
  STUDENT_LIST_UNDER_SALES_EXECUTIVE,
} from "../constants/end-points";

export const requestForLogin = async ({ email, password }) => {
  try {
    const res = await axiosInstance.post(LOGIN, {
      email,
      password,
    });
    console.log("response", res);
    if (res.status === 200) {
      // Request was successful
      return { ...res.data, status: 200 }; // You can also return the response data
    } else {
      // Handle other success cases if needed
      return { status: res.status, message: "something went wrong" };
    }
  } catch (err) {
    console.log(err, "err");
    return { message: err.response.data.message, status: 400 };
    // return false;
  }
};

export const requestForIsUserLoggedIn = async () => {
  try {
    const res = await axiosInstance.get(IS_USER_LOGGED_IN);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForStudentListUnderSalesExecutiveAffiliate = async () => {
  try {
    const res = await axiosInstance.post(STUDENT_LIST_UNDER_SALES_EXECUTIVE);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};
export const requestForAllAffiliateTokenList = async ({ from_date, to_date, university, status, search_key, skip, limit, affiliate_id }) => {
  try {
    const res = await axiosInstance.get(ALL_AFFILIATE_TOKEN_LIST({ from_date, to_date, university, status, search_key, skip, limit, affiliate_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};
export const requestForFinanceApprovalOrRejection = async ({ selectedToken, notSelectedToken, selectAll, type, remarks }) => {
  try {
    const res = await axiosInstance.post(FINANCE_APPROVE_REJECT_TOKEN, { selectedToken, notSelectedToken, selectAll, type, remarks });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const getAffiliateListApi = async ({ status, skip, limit, search_key }) => {
  try {
    const res = await axiosInstance.get(GET_AFFILIATE_LIST({ status, skip, limit, search_key }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const getAffiliateOwnerListApi = async () => {
  try {
    const res = await axiosInstance.get(GET_AFFILIATE_OWNER_LIST);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const onBoardAffiliateApi = async ({ ...data }) => {
  try {
    const res = await axiosInstance.post(ADD_AFFILIATE, { ...data });
    if (res.status === 200) {
      // Request was successful
      return { status: 200, data: res.data }; // You can also return the response data
    } else {
      // Handle other success cases if needed
      return { status: res.status, message: "something went wrong" };
    }
  } catch (err) {
    if (err.response) {
      // The request was made and the server responded with an error status
      return { status: err.response.status, message: err.response.data.message };
    }
  }
};

export const requestResetPasswordOnOnboard = async ({ token, password }) => {
  try {
    const res = await axiosInstance.post(ON_BOARDING_RESET_VERIFY, {
      token,
      password,
    });
    return { ...res.data };
  } catch (err) {
    return err;
  }
};

export const requestAgreeOnBoardingTerms = async ({ user_id }) => {
  try {
    const res = await axiosInstance.put(AGREE_ONBOARDING_TERMS({ id: user_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForForgotPassword = async ({ email }) => {
  try {
    const res = await axiosInstance.post(AUTH_FORGOT_PASSWORD, { email });
    // console.log("response", res);
    if (res.status === 202) {
      // Request was successful
      return { ...res.data, status: 202 }; // You can also return the response data
    } else {
      // Handle other success cases if needed
      return { status: res.status, message: "something went wrong" };
    }
  } catch (err) {
    console.log(err, "err");
    return { message: err.response.data.message, status: 400 };
    // return false;
  }
};
