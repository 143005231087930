import { useState } from "react";
import AFMSDataGrid from "./afms-data-grid";
import { AFMS_DATA } from "./mockApi";
import ComponentCard from "../../../../components/ComponentCard";
import AfmsFilter from "./afms-filter";
import AfmsUpdateRole from "./afms-update-role";

const AFMS = () => {
  const [afmsData, setAfmsData] = useState(AFMS_DATA);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    empId: "",
    department: "",
    profile: "",
    manager: "",
  });

  const HandleFilterChange = (filter) => {
    setFilterOptions(filter);
  };

  const HandleSearch = () => {
    if (
      filterOptions.empId !== "" &&
      filterOptions.department !== "" &&
      filterOptions.profile !== "" &&
      filterOptions.manager !== ""
    ) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  };

  const handeleRowSelected = (value) => {
    setSelectedRows(value);
  };

  const handleSubmiteRole = (selectedRole) => {
    if (selectedRows.length) {
      const result = afmsData.map((item) => {
        if (selectedRows.some((el) => el.empId === item.empId)) {
          item.role = selectedRole.map((el) => el.value).join(", ");
        }
        return item;
      });
      setAfmsData(result);
    }
  };
  return (
    <div>
      {/* <ComponentCard title="User Managment"> */}
      <AfmsFilter
        filterOptions={filterOptions}
        onFilterChange={HandleFilterChange}
        onSearchClick={HandleSearch}
      />
      <AfmsUpdateRole items={afmsData} onSubmitRoles={handleSubmiteRole} />
      <AFMSDataGrid
        items={afmsData}
        filterOptions={filterOptions}
        isSearch={isSearch}
        onRowSelected={handeleRowSelected}
      />
      {/* </ComponentCard> */}
    </div>
  );
};
export default AFMS;
