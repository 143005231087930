import { useState } from "react";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import ComponentCard from "../../../components/ComponentCard";
import AFMS from "./AFMS";
import SIS from "./SIS";
import Module from "./Module";

const UserManagmentTab = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <ComponentCard title="User Managment">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`(${activeTab === '1'} ? 'active' : '') text-white bg-primary`}
              onClick={() => {
                toggle('1');
              }}
            >
              AFMS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`(${activeTab === '2' ? 'active' : ''}) text-white bg-warning`}
              onClick={() => {
                toggle('2');
              }}
            >
              SIS
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={`(${activeTab === '3' ? 'active' : ''}) text-white bg-info`}
              onClick={() => {
                toggle('3');
              }}
            >
              Module
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="p-4" activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <AFMS />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <SIS />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Module />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ComponentCard>
    </>
  );
}

export default UserManagmentTab;