import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { requestForForgotPassword, requestForIsUserLoggedIn, requestForLogin } from "../../../api/api.auth.request";

const initialState = {
  userRole: {},
  asignee: {},
  userDetails: {},
  auth: false,
  error: "",
  forgotPwd: {},
  loading: false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setAsignee: (state, action) => {
      state.asignee = action.payload;
    },
    startAuthLoading: (state) => {
      state.loading = true;
    },
    stopAuthLoading: (state) => {
      state.loading = false;
    },
    loginUser: (state, action) => {
      state.userDetails = action.payload;
      state.auth = true;
    },
    logoutUser: (state) => {
      state.auth = false;
    },
    getError: (state, action) => {
      state.error = action.payload;
    },
    forgotPassword: (state, action) => {
      state.forgotPwd = action.payload;
    },
  },
});

export const { setUserRole, setAsignee, loginUser, startAuthLoading, stopAuthLoading, logoutUser, getError, forgotPassword } = UserSlice.actions;

export const reduxUserLogin =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(startAuthLoading());
      const response = await requestForLogin({
        email,
        password,
      });
      console.log(response, "ress");
      if (response.status == 200) {
        Cookies.set("x-univo-api-token", response.token, {
          expires: 365, // Expires in 7 days
          path: "/", // Available on the root path
          // domain: '.example.com', // Available across subdomains of example.com
          // secure: true, // Sent only over HTTPS
          // sameSite: 'strict', // Send the cookie only if the request originates from the same site
          // httpOnly: true // Accessible only through HTTP
        });
        dispatch(loginUser(response.user));
        window.location.reload();
        dispatch(stopAuthLoading());
      } else {
        dispatch(stopAuthLoading());
        dispatch(logoutUser());
        dispatch(getError(response));
      }
    } catch (err) {
      dispatch(stopAuthLoading());
      dispatch(logoutUser());
      // dispatch(getError(err));
      throw new Error(err);
    }
  };

export const reduxIsUserLoggedIn = () => async (dispatch) => {
  try {
    dispatch(startAuthLoading());
    const response = await requestForIsUserLoggedIn();
    if (response) {
      dispatch(loginUser(response.data));
      dispatch(stopAuthLoading());
    } else {
      dispatch(stopAuthLoading());
      dispatch(logoutUser());
    }
  } catch (err) {
    dispatch(stopAuthLoading());
    window.document.cookie.dispatch(logoutUser());
    throw new Error(err);
  }
};

export const reduxUserForgotPwd =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch(startAuthLoading());
      const response = await requestForForgotPassword({ email });
      // console.log(response, "ress");
      if (response) {
        // Cookies.set("x-univo-api-token", response.token, {
        //   expires: 365, // Expires in 7 days
        //   path: "/", // Available on the root path
        //   // domain: '.example.com', // Available across subdomains of example.com
        //   // secure: true, // Sent only over HTTPS
        //   // sameSite: 'strict', // Send the cookie only if the request originates from the same site
        //   // httpOnly: true // Accessible only through HTTP
        // });
        dispatch(forgotPassword(response));
        dispatch(stopAuthLoading());
      } else {
        dispatch(stopAuthLoading());
        // dispatch(logoutUser());
        dispatch(getError(response));
      }
    } catch (err) {
      dispatch(stopAuthLoading());
      // dispatch(logoutUser());
      // dispatch(getError(err));
      throw new Error(err);
    }
  };

export default UserSlice.reducer;
