import { createSlice } from "@reduxjs/toolkit";

import { requestForDataBySession, requestForPieChardData } from "../../../api/api.dashboard.request";

const initialState = {
  data: [],
  piechartData: [],
  loading: false,
};
export const UserDashboardSlice = createSlice({
  name: "userDashboard",
  initialState,
  reducers: {
    startDataLoading: (state) => {
      state.loading = true;
    },
    stopDataLoading: (state) => {
      state.loading = false;
    },
    getAllDataExport: (state, action) => {
      // console.log(action.payload, "action");
      state.data = action.payload;
    },
    getPieChartDataExport: (state, action) => {
      state.piechartData = action.payload;
    },
  },
});
export const { getAllDataExport, startDataLoading, stopDataLoading, getPieChartDataExport } = UserDashboardSlice.actions;

export const getDataBySelectedSession =
  ({ start_at, end_at }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading());
      const response = await requestForDataBySession({ start_at, end_at });
      // console.log("response", response);
      if (response) {
        // console.log("after response", response);
        dispatch(getAllDataExport(response));
        dispatch(stopDataLoading());
      }
    } catch (err) {
      dispatch(stopDataLoading());
      throw new Error(err);
    }
  };

export const getPieChartDataForSelectedSession =
  ({ start_at, end_at }) =>
  async (dispatch) => {
    try {
      dispatch(startDataLoading);
      const response = await requestForPieChardData({ start_at, end_at });
      if (response) {
        dispatch(getPieChartDataExport(response));
        dispatch(stopDataLoading);
      }
    } catch (error) {
      dispatch(stopDataLoading);
      throw new Error(error);
    }
  };

export default UserDashboardSlice.reducer;
