import axiosInstance from "../config/axios/AxiosInstance";
import {
  GET_S3_DATA_FROM_UNIVO,
  REQUEST_FOR_ALL_PROGRAM_UNDER_SESSION_IN_UNIVERSITY,
  REQUEST_FOR_ALL_SESSION_UNDER_UNIVERSITY,
  REQUEST_FOR_ALL_UNIVERSITY,
  UPLOAD_SINGLE_FILE,
} from "../constants/end-points";

export const requestForFileUpload = async () => {
  try {
    const res = await axiosInstance.post(UPLOAD_SINGLE_FILE);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAllUniversity = async () => {
  try {
    const res = await axiosInstance.get(REQUEST_FOR_ALL_UNIVERSITY);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAllSessionUnderUniversity = async ({ id }) => {
  try {
    const res = await axiosInstance.get(REQUEST_FOR_ALL_SESSION_UNDER_UNIVERSITY({ id: id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAllProgramSessionUnderUniversity = async ({ s_id, u_id }) => {
  try {
    const res = await axiosInstance.get(REQUEST_FOR_ALL_PROGRAM_UNDER_SESSION_IN_UNIVERSITY({ s_id, u_id }));
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForS3FileFromUrl = async ({ url, type }) => {
  try {
    const res = await axiosInstance.post(
      GET_S3_DATA_FROM_UNIVO,
      { url },
      {
        responseType: type == "xl" ? "arraybuffer" : "blob", // This ensures that the response is treated as a blob
      }
    );
    return res;
  } catch (err) {
    return false;
  }
};
