import axios from "axios";

import { BASE_URL } from "../../constants/url-config";
import { getCookie } from "../../helpers/cookie-helper";
import Cookies from "js-cookie";

const token = Cookies.get("x-univo-api-token")
// Create a new Axios instance with custom headers
const axiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token ? token : ""}`, // Use environment variables for security
    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJwdGVqYUB1bml2b2VkdGVjaC5jb20iLCJpYXQiOjE2OTU4OTgwMTAsImV4cCI6MTcyNzQzNDAxMH0.TC7xdbgqFnM40S9J9r_DcSsCuKCJBeSsylwXoua0unY`
  },
});

export default axiosInstance;
