
import axiosInstance from "../config/axios/AxiosInstance";
import { ALL_AFFILIATE_TOKEN_LIST } from "../constants/end-points";

export const requestForAllAffiliateTokenList = async ({
    from_date,
    to_date,
    university,
    status,
    search_key,
    skip,
    limit,
    affiliate_id
}) => {
    try {
        const res = await axiosInstance.get(
            ALL_AFFILIATE_TOKEN_LIST({ from_date, to_date, university, status, search_key, skip, limit, affiliate_id })
        );
        return { ...res.data };
    } catch (err) {
        return false;
    }
};