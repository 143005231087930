import { Button, FormGroup, Input } from "reactstrap";

const AfmsFilter = (props) => {
  const { filterOption, onFilterChange, onSearchClick } = props;
  const handleChange = (e) => {
    onFilterChange({
      ...filterOption,
      [e.target.name]: e.target.value,
    });
  };
  const handleClick = () => {
    onSearchClick();
  };
  return (
    <div className="d-flex flex-wrap align-items-center gap-3 border-bottom px-0 py-3 mb-0">
      <FormGroup>
        <Input
          type="text"
          name="empId"
          value={filterOption?.empId}
          onChange={handleChange}
          placeholder="Search By Id..."
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          name="profile"
          value={filterOption?.profile}
          onChange={handleChange}
          placeholder="Search By Name..."
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          name="department"
          value={filterOption?.department}
          onChange={handleChange}
          placeholder="Search By Department..."
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          name="manager"
          value={filterOption?.manager}
          onChange={handleChange}
          placeholder="Search By Manager..."
        />
      </FormGroup>
      <FormGroup>
        <Button className="btn" color="success" onClick={handleClick}>
          Search
        </Button>
      </FormGroup>
    </div>
  );
};
export default AfmsFilter;
