export const LOGIN = `/auth/login`;

export const IS_USER_LOGGED_IN = `/me/logged-in-user-details`;

export const AUTH_FORGOT_PASSWORD = `/auth/forgot-password`;

export const AUTH_VERIFY_OTP = `/auth/verifyOtp`;

export const AUTH_RESET_PASSWORD = `/auth/setpassword`;

export const STUDENT_LIST_UNDER_SALES_EXECUTIVE = ({ from_date, to_date, university, session, program, payment_status, search_key, skip, limit }) => {
  return `/afms/affiliate/list-all-enrollments?${from_date ? `from_date=${from_date}` : ""}${to_date ? `&to_date=${to_date}` : ""}${
    university ? `&university=${university}` : ""
  }${session ? `&session=${session}` : ""}${program ? `&program=${program}` : ""}${payment_status ? `&payment_status=${payment_status}` : ""}${
    search_key ? `&search_key=${search_key}` : ""
  }${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}
   `.trim();
};

export const ENROLLMENT_LOAN_DETAILS = `/afms/common/get-student-load-details`;

//affiliate

export const GET_AFFILIATE_LIST = ({ status, skip, limit, search_key }) => {
  let url = `/afms/affiliate/list-all-affiliates?${status ? `status=${status}` : ""}${
    search_key ? `&search_key=${search_key}` : ""
  }&skip=${skip}&limit=${limit}`;
  return url;
};

export const ON_BOARDING_RESET_VERIFY = "/auth/onboarding/reset-password";

export const GET_AFFILIATE_OWNER_LIST = "/afms/affiliate/list-all-users";

export const ADD_AFFILIATE = "/afms/affiliate/register";

export const AFFILIATE_TOKEN_LIST = ({ skip, limit }) => {
  let url = `/afms/affiliate/list-all-tokens-by-affiliateid?${skip ? `skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const ALL_AFFILIATE_TOKEN_LIST = ({ from_date, to_date, university, status, search_key, skip, limit, affiliate_id }) => {
  return `/afms/affiliate/all-affiliate-list-token?${`fromDate=${from_date || ""}`}${`&toDate=${to_date || ""}`}${`&university=${
    university || ""
  }`}${`&status=${status || ""}`}${`&searchKey=${search_key || ""}${`${affiliate_id ? `&affiliate_id=${affiliate_id || ""}` : ""}`}`}${`&skip=${
    skip || 0
  }`}${`&limit=${limit || 10}`}
   `.trim();
};

export const FINANCE_APPROVE_REJECT_TOKEN = "afms/affiliate/finance-approve-or-reject-token-list";

export const ENROLLMENT_UNIVERSITY_LIST = `/afms/affiliate/list-all-university`;

export const ENROLLMENT_SESSION_LIST = `/afms/affiliate/list-available-sessions-by-university`;

export const ENROLLMENT_PROGRAM_LIST = `/afms/affiliate/list-all-program-by-university-and-session`;

export const GET_AFFLIATE_BASIC_DETAILS = ({ id }) => `/afms/affiliate/${id}/basic-details`;

export const AFFILIATE_BRANCH_LIST = ({ id }) => `/afms/affiliate/${id}/branch/list`;

export const GET_KYC_DETAILS = ({ id }) => `/afms/affiliate/${id}/get-kyc-attachment`;

export const GET_ADDITIONAL_ATTACHMENT_DETAILS = ({ id }) => `/afms/affiliate/${id}/get-kyc-attachment?kyc=false`;

export const REQUEST_FOR_COMMISSION_CONFIG = ({ id }) => `/afms/affiliate/${id}/request-for-commission-configuration`;

export const ADD_A_BRANCH = ({ id }) => `/afms/affiliate/${id}/branch/add`;

export const EDIT_A_BRANCH = ({ code }) => `/afms/affiliate/${code}/branch/edit`;

export const DELETE_A_BRANCH = ({ code }) => `/afms/affiliate/${code}/branch/delete`;

export const ADD_OR_EDIT_KYC_OR_ATTACHMENTS = `/afms/affiliate/kyc-or-additional/add-or-edit`;

export const AFFILIATE_ATTACHMENT_DELETE = `/afms/affiliate/kyc-or-additional/delete`;

export const GET_COMMISSION_PARAMS = ({ id }) => `/afms/affiliate/${id}/get-commission-configuration-params`;

export const EDIT_COMMISSION_PARAMS = ({ id }) => `/afms/affiliate/${id}/edit-commission-configuration-params`;

export const GET_AFFILIATE_GENEARTEDLINKS = ({ id }) => `/afms/affiliate/${id || 86}/get-affiliate-university-links`;

export const GET_AFFILIATE_UNIVERSITY_GENEARTEDLINKS = ({ id }) => `/afms/affiliate/${id}/generate-affiliate-university-links`;

export const DASHBOARD_DETAILS = `/afms/common/stats-affiliate-month`;

export const UPLOAD_SINGLE_FILE = `/afms/common/upload/single-file`;

export const GET_AFFILIATE_COMMISSION_CONFIG = ({ id }) => `/afms/affiliate/${id}/get-commission-configuration`;

export const GET_ONLY_AFFILIATE_COMMISSION_CONFIG = ({ id }) => `/afms/affiliate/${id}/approved-affiliate-configuration`;

export const REQUEST_FOR_ALL_UNIVERSITY = `/afms/common/get-all-university`;

export const REQUEST_FOR_ALL_SESSION_UNDER_UNIVERSITY = ({ id }) => `/afms/common/${id}/get-all-sessions-of-university`;

export const REQUEST_FOR_ALL_PROGRAM_UNDER_SESSION_IN_UNIVERSITY = ({ s_id, u_id }) =>
  `/afms/common/get-all-programs-of-university-under-a-session?session_id=${s_id}&university_id=${u_id}`;

export const CHANGE_COMMISSION_CONFIGURATION = `/afms/affiliate/change-commission-configuration`;

export const REQUEST_FOR_COMMISSION_VERIFICATION = ({ id }) => `/afms/affiliate/${id}/request-for-commission-verification`;

export const REQUEST_FOR_COMMISSION_APPROVE = ({ id }) => `afms/affiliate/${id}/approve-commission-configuration`;

export const REQUEST_FOR_COMMISSION_REJECT = ({ id }) => `afms/affiliate/${id}/reject-commission-configuration`;

export const DASHBOARD_PIECHART_DETAILS = `/afms/common/stats-university-affiliate`;

export const GENERATE_TOKEN_PREVIEW_LIST = ({ skip, limit }) => {
  let url = `/afms/affiliate/generate-token-preview?${skip ? `skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};
export const EDIT_TOKEN_PREVIEW_LIST = ({ skip, limit, id }) => {
  let url = `/afms/affiliate/get-students-under-a-token?${`token=${id}`} ${skip ? `&skip=${skip || 0}` : ""}${limit ? `&limit=${limit || 10}` : ""}`;
  // let url = `/afms/affiliate/generate-token-preview?${skip ? `skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const EXPORT_EXCEL_TOKEN_LIST = `afms/affiliate/generate-annexure-and-excel`; //gvbh

export const EXPORT_EDIT_EXCEL_TOKEN_LIST = `afms/affiliate/update-students-under-a-token`;

export const GET_S3_DATA_FROM_UNIVO = `/afms/affiliate/file/get-blob-from-s3`;

export const ACTIVE_INACTIVE_USER = ({ id }) => `afms/affiliate/${id}/make-affiliate-inactive`;

export const AFFILIATE_ON_BOARD_INVITE = ({ id }) => `/afms/affiliate/${id}/onboarding/mail-reset-password-link`;

export const AGREE_ONBOARDING_TERMS = ({ id }) => `/afms/affiliate/${id}/onboarding/agree-affiliate-details-and-comission-configuration`;
export const DOWNLOAD_OR_UPLOAD_TOKEN_BY_AFFILIATE = `afms/affiliate/verify-invoice-details-from-affiliate`;
export const APPROVED_INVOICES_LIST = ({ searchKey, fromdate, todate, status, skip, limit }) => {
  let url = `/finance-head/get-all-invoice?${searchKey ? `searchKey=${searchKey}` : ""}${fromdate ? `&fromdate=${fromdate}` : ""}${
    todate ? `&todate=${todate}` : ""
  }${status ? `&status=${status}` : ""}${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const GET_ALL_BATCHES = ({ batchKey, fromdate, todate, skip, limit }) => {
  let url = `/finance-head/get-batch-list?${batchKey ? `batchKey=${batchKey}` : ""}${fromdate ? `&fromdate=${fromdate}` : ""}${
    todate ? `&todate=${todate}` : ""
  }${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const GET_ALL_INVOICES_UNDER_BATCH = ({ skip, limit }) =>
  `/finance-head/get-invoice-list-by-batch?${skip ? `skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;

export const ADD_GENERATE_BRANCH = `/finance-head/generate-batch`;

export const UPLOAD_COVER_NOTE_DOCUMENT = `/finance-head/upload-support-doc-batch`;

export const MARK_PAYMENT = `/finance-head/update-payment`;

export const EXPORT_EXCEL_INVOICE_LIST = `/finance-head/export-invoice`;

export const APPROVED_OR_REJECTED_INVOICE_BY_FINANCE = `afms/affiliate/update-token-status`;

export const UPLOAD_CSV_FILE = `/finance-head/upload-csv`;

export const GET_REMARK = `/afms/affiliate/get-remark`;

export const STUDENT_DASHBOARD_COUNT = ({ session }) => {
  return `/afms/affiliate/get-count-for-student-dashboard?session=${session}`;
};

export const GET_ALL_AFFILIATE_TRANSACTION_LIST = ({ step, admission_session, search_key }) => {
  let url = `/afms/affiliate/download-transaction-data-dashboard?${step ? `step=${step}` : ""}${
    admission_session ? `&admission_session='${admission_session}'` : ""
  }${search_key ? `&search_key=${search_key}` : ""}`;
  return url;
};

export const getSessionId = `/afms/affiliate/list-all-sessions`;

export const GET_TRANSACTION_DATA_DASHBOARD = ({ step, admission_session, skip, limit, search_key }) => {
  let url = `/afms/affiliate/get-transaction-data-dashboard?${step ? `step=${step}` : ""}${
    admission_session ? `&admission_session='${admission_session}'` : ""
  }${search_key ? `&search_key=${search_key}` : ""}${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const GET_TOKEN_DETAILS_DASHBOARD = ({ token_status, dashboard_token_status, session, skip, limit, search_key }) => {
  let url = `/afms/affiliate/get-token-details-dashboard?${token_status ? `token_status=${token_status}` : ""}${
    dashboard_token_status ? `&dashboard_token_status=${dashboard_token_status}` : ""
  }${session ? `&session=${session}` : ""}${search_key ? `&search_key=${search_key}` : ""}${skip ? `&skip=${skip}` : ""}${
    limit ? `&limit=${limit}` : ""
  }`;
  return url;
};

export const GET_PAYMENT_DETAILS_DASHBOARD = ({ admission_session, search_key, skip, limit }) => {
  let url = `/afms/affiliate/total-payment-done-dashboard?${admission_session ? `admission_session=${admission_session}` : ""}${
    search_key ? `&search_key=${search_key}` : ""
  }${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const DOWNLOAD_REPORT = (
  reportType,
  university,
  token_no,
  invoice_no,
  batch_no,
  form_no,
  token_status,
  batch_status,
  payment_status,
  status
) => {
  // university=2&token_no=50021&token_verify_date=&invoice_no=&cover_note=&form_id=10530758
  // token_status=2&batch_status=&payment_status=processing
  let url;
  if (reportType === "report-4") {
    url = `/finance-head/${reportType}?${university ? `university=${university}` : ""}${token_no ? `&token_no=${token_no}` : ""}${
      invoice_no ? `&invoice_no=${invoice_no}` : ""
    }${batch_no ? `&cover_note=${batch_no}` : ""}${form_no ? `&form_id=${form_no}` : ""}${token_status ? `&token_status=${token_status}` : ""}${
      batch_status ? `&batch_status=${batch_status}` : ""
    }${payment_status ? `&payment_status=${payment_status}` : ""}`;
  } else if (reportType.toLowerCase() === "report-1") {
    url = `/finance-head/${reportType}?`;
    // ${token_status != null ? `&token_status=${token_status}` : ""}
  } else {
    url = `/finance-head/${reportType}?${status ? `status=${status}` : ""}`;
  }
  return url;
};

export const GET_ALL_SALES_EMPLOYEES_LIST = ({ download, status, searchKey, skip, limit, designation, team_name, team_lsq_id }) => {
  let url = `${download ? "/sis/download-all-employees" : "/sis/get-all-employees"}?${status ? `status=${status}` : ""}${
    searchKey ? `&searchKey=${searchKey}` : ""
  }${designation ? `&designation=${designation}` : ""}${team_name ? `&team_name=${team_name}` : ""}${
    team_lsq_id ? `&team_lsq_id=${team_lsq_id}` : ""
  }${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const GET_ALL_SALES_TRANSCTION = ({ download, searchKey, from_date, to_date, limit, skip, designation, team_name, team_lsq_id }) => {
  let url = `${download ? "/sis/download-all-sales-transaction" : "/sis/get-all-sales-transaction"}?${from_date ? `from_date=${from_date}` : ""}${
    to_date ? `&to_date=${to_date}` : ""
  }${searchKey ? `&searchKey=${searchKey}` : ""}${designation ? `&designation=${designation}` : ""}${team_name ? `&team_name=${team_name}` : ""}${
    team_lsq_id ? `&team_lsq_id=${team_lsq_id}` : ""
  }${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const GET_ALL_TEAMS_INCENTIVE_CRITERIA = ({ searchKey, skip, limit, incentive_status }) => {
  let url = `/sis/get-all-teams-incentive-criteria?${searchKey ? `searchKey=${searchKey}` : ""}${
    incentive_status ? `&incentive_status=${incentive_status}` : ""
  }${skip ? `&skip=${skip}` : ""}${limit ? `&limit=${limit}` : ""}`;
  return url;
};

export const GET_INCENTIVE_PLAN_BY_CRITERIA_ID = `/sis/get-incentive-plan-by-criteria-id`;

export const CONFIGURE_INCENTIVE_CRITERIA = `/sis/configure-incentive`;

export const GET_ALL_TEAMS_LIST = `/sis/list-all-teams`;

export const GET_LATEST_DRAFT_DETAILS = `/sis/get-latest-draft`;

export const GET_INCENTIVE_CRITERIA_PREVIEW_DETAILS = `/sis/get-incentive`;

export const LIST_ALL_DESIGNATIONS = `/sis/list-all-designations`;

export const APPROVE_CONFIGURATION = `/sis/approve-sales-incentive-criteria`;

export const REJECT_CONFIGURATION = `/sis/reject-sales-incentive-criteria`;

export const GET_SALES_INCENTIVE_DETAILS = `/sis/get-employee-incentive-structure`;

export const MARK_CRITERIA_ACTIVE = `/sis/mark-criteria-active`;

export const MARK_CRITERIA_INACTIVE = `/sis/mark-criteria-inactive`;

export const ENROLLMENT_INCENTIVE_DETAILS = ({ user_id }) => {
  let url = `/sis/get-all-sales-calculation?${user_id ? `user_id=${user_id}` : ""}`;
  return url;
};

export const EDIT_INCENTIVE_CRITERIA = `/sis/edit-incentive-criteria`;
