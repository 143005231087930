const StatusConstant = {
  draft: "draft",
  requestedForFinanceApproval: "requested_for_finance_approval",
  pendingForFinanceVerification: "pending_for_finance_verification",
  approved: "approved_by_finance",
  rejected: "rejected",
  successfullyOnboarded: "successfully_onboarded",
  defaultCommissionStatus: "pending",
};

export default StatusConstant;

export const AffiliateTokenStatus = [
  {
    code: "0",
    type: "Pending for Finance Verification ",
    color: "warning"
  },
  {
    code: "1",
    type: "Cancelled By Finance",
    color: "danger"
  },
  {
    code: "2",
    type: "Sent for Affiliate Verification",
    color: "warning"
  },
  {
    code: "3",
    type: "Token Approved by Affiliate",
    color: "success"
  },
  {
    code: "4",
    type: "Rejected by Affiliate ",
    color: "danger"
  },
  {
    code: "5",
    type: "Sent for Invoice Verification",
    color: "warning"
  },
  {
    code: "6",
    type: "Invoice Approved",
    color: "success"
  },
  {
    code: "7",
    type: "Invoice Rejected by Finance",
    color: "danger"
  },
  {
    code: "8",
    type: "Pending Invoice Approval",
    color: "danger"
  },
  {
    code: "9",
    type: "Token downloaded by Affiliate",
    color: "success"
  }
]
