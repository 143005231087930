
import axiosInstance from "../config/axios/AxiosInstance";
import { APPROVED_OR_REJECTED_INVOICE_BY_FINANCE, DOWNLOAD_OR_UPLOAD_TOKEN_BY_AFFILIATE, EDIT_TOKEN_PREVIEW_LIST, EXPORT_EDIT_EXCEL_TOKEN_LIST, EXPORT_EXCEL_TOKEN_LIST, GENERATE_TOKEN_PREVIEW_LIST } from '../constants/end-points';

export const requestForGenerateTokenPreviewList = async ({ affiliate_id, from_date, till_date, skip, limit }) => {
  try {
    const res = await axiosInstance.post(GENERATE_TOKEN_PREVIEW_LIST({ skip, limit }), {
      affiliate_id,
      from_date,
      till_date,
    });
    // console.log("====data", res.data);
    return { ...res.data }

  } catch (err) { return false }
};
export const requestForEditTokenPreviewList = async ({ id, skip, limit }) => {
  try {
    const res = await axiosInstance.get(EDIT_TOKEN_PREVIEW_LIST({ id, skip, limit }));
    // console.log("====data", res.data);
    return { ...res.data }

  } catch (err) { return false }
};

export const requestForDownloadExcelTokenList = async ({ affiliate_id, transactionIds, is_excel, is_select_all, from_date, till_date, isNotSelectedIds }) => {
  try {
    const res = await axiosInstance.post(EXPORT_EXCEL_TOKEN_LIST, {
      affiliate_id,
      transactionIds,
      is_excel,
      is_select_all,
      from_date,
      till_date,
      isNotSelectedIds
    });
    return { ...res.data }
  } catch (err) { return false }
}

export const requestForDownloadEditExcelTokenList = async ({ selectAll, selectedArray, deselectedArray, token_no, is_excel }) => {
  try {
    const res = await axiosInstance.post(EXPORT_EDIT_EXCEL_TOKEN_LIST, {
      selectAll,
      selectedArray,
      deselectedArray,
      token_no,
      is_excel
    });
    return { ...res.data }
  } catch (err) { return false }
}
export const requestForDownloadOrUploadTokenByAffiliate = async ({ invoice_no, invoice_date, is_upload, token_no, img, status, affiliate_id }) => {
  const formData = new FormData();
  formData.append("invoice_no", invoice_no);
  formData.append("invoice_date", invoice_date);
  formData.append("token_no", token_no);
  console.log("img :==================", img);
  // formData.append("img", img);
  // const formData = new FormData();

  // formData.append("status", status);
  formData.append("is_upload", is_upload);
  for (let i = 0; i < img?.length; i++) {
    formData.append('img', img[i]);
  }
  // formData.append("affiliate_id", affiliate_id);
  try {
    const res = await axiosInstance.post(DOWNLOAD_OR_UPLOAD_TOKEN_BY_AFFILIATE, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
          // You might need an authorization header here, depending on your API
        }
      }
    );
    // console.log("res of file upload -----:", res);
    return { ...res.data }
  } catch (err) { return false }
};

export const requestForApprovedOrRejectedInvoiceByFinance = async ({ token_no, new_status, remarks }) => {
  try {
    const res = await axiosInstance.post(APPROVED_OR_REJECTED_INVOICE_BY_FINANCE, {
      token_no,
      new_status,
      remarks
    });
    return { ...res.data }
  } catch (err) { return false }
};