import EmployeeIncentiveStructure from "./EmployeeIncentiveStructure";

const IncentiveCriteriaPreviewTable = ({ agent, assisstance_team_lead, cluster_head, team_lead, sales_head }) => {
  console.log(agent);
  const {
    capping,
    incentive_structure_monthly_goals,
    monthly_multiplier,
    incentive_structure_targets,
    target,
    semester_multiplier,
    teams_target,
    incentive_structure,
    teams_target_multiplier,
  } = agent
    ? agent
    : assisstance_team_lead
    ? assisstance_team_lead
    : cluster_head
    ? cluster_head
    : sales_head
    ? sales_head
    : team_lead
    ? team_lead
    : "";

  return (
    <>
      <EmployeeIncentiveStructure
        capping={capping}
        category_target_mapping={incentive_structure_monthly_goals}
        monthly_multiplier={monthly_multiplier}
        payout_mapping={incentive_structure_targets}
        addon_target={target}
        semester_multiplier={semester_multiplier}
        teams_target={teams_target ? teams_target : teams_target_multiplier}
        incentive_structure={incentive_structure}
      />
    </>
  );
};

export default IncentiveCriteriaPreviewTable;
