import axiosInstance from "../config/axios/AxiosInstance";
import {
  ENROLLMENT_LOAN_DETAILS,
  ENROLLMENT_PROGRAM_LIST,
  ENROLLMENT_SESSION_LIST,
  ENROLLMENT_UNIVERSITY_LIST,
  STUDENT_LIST_UNDER_SALES_EXECUTIVE,
} from "../constants/end-points";

export const requestForAffiliateEnrollmentList = async ({
  from_date,
  to_date,
  university,
  session,
  program,
  payment_status,
  search_key,
  skip,
  limit,
}) => {
  try {
    const res = await axiosInstance.post(
      STUDENT_LIST_UNDER_SALES_EXECUTIVE({ from_date, to_date, university, session, program, payment_status, search_key, skip, limit })
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForStudentUniversityList = async () => {
  try {
    const res = await axiosInstance.get(ENROLLMENT_UNIVERSITY_LIST);
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForStudentSessionList = async ({ university_id }) => {
  try {
    const res = await axiosInstance.post(ENROLLMENT_SESSION_LIST, { university_id: university_id });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForStudentProgramList = async ({ university_id, session_id }) => {
  try {
    const res = await axiosInstance.post(ENROLLMENT_PROGRAM_LIST, { university_id: university_id, session_id: session_id });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForStudentLoanDetails = async ({ transaction_id }) => {
  try {
    const res = await axiosInstance.post(ENROLLMENT_LOAN_DETAILS, { transaction_id: transaction_id });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};
