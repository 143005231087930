import axiosInstance from "../config/axios/AxiosInstance";
import {
  APPROVE_CONFIGURATION,
  GET_ALL_SALES_EMPLOYEES_LIST,
  GET_ALL_SALES_TRANSCTION,
  GET_ALL_TEAMS_INCENTIVE_CRITERIA,
  GET_ALL_TEAMS_LIST,
  GET_INCENTIVE_CRITERIA_PREVIEW_DETAILS,
  GET_INCENTIVE_PLAN_BY_CRITERIA_ID,
  GET_LATEST_DRAFT_DETAILS,
  GET_SALES_INCENTIVE_DETAILS,
  LIST_ALL_DESIGNATIONS,
  MARK_CRITERIA_ACTIVE,
  MARK_CRITERIA_INACTIVE,
  REJECT_CONFIGURATION,
} from "../constants/end-points";

export const requestForSalesEmployeesList = async ({ download, status, searchKey, skip, limit, designation, team_name, team_lsq_id }) => {
  try {
    const res = await axiosInstance.get(
      GET_ALL_SALES_EMPLOYEES_LIST({ download, status, searchKey, skip, limit, designation, team_name, team_lsq_id })
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForSalesTransactionList = async ({
  download,
  searchKey,
  from_date,
  to_date,
  limit,
  skip,
  designation,
  team_name,
  team_lsq_id,
}) => {
  try {
    const res = await axiosInstance.get(
      GET_ALL_SALES_TRANSCTION({ download, searchKey, from_date, to_date, limit, skip, designation, team_name, team_lsq_id })
    );
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForAllTeamsIncentiveCriteria = async ({ searchKey, skip, limit, incentive_status }) => {
  try {
    const res = await axiosInstance.get(GET_ALL_TEAMS_INCENTIVE_CRITERIA({ searchKey, skip, limit, incentive_status }));
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForIncentivePlanByCriteriaId = async ({ criteria_id }) => {
  try {
    const res = await axiosInstance.post(GET_INCENTIVE_PLAN_BY_CRITERIA_ID, { criteria_id });
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForEmployeeIncentiveDetails = async ({ email }) => {
  try {
    const res = await axiosInstance.post(GET_SALES_INCENTIVE_DETAILS, { email });
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForListAllDesignations = async () => {
  try {
    const res = await axiosInstance.get(LIST_ALL_DESIGNATIONS);
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForLatestDraftDetails = async () => {
  try {
    const res = await axiosInstance.get(GET_LATEST_DRAFT_DETAILS);
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForCriteriaPreviewDetails = async () => {
  try {
    const res = await axiosInstance.get(GET_INCENTIVE_CRITERIA_PREVIEW_DETAILS);
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForListAllTeams = async () => {
  try {
    const res = await axiosInstance.get(GET_ALL_TEAMS_LIST);
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForApproveCriteria = async ({ criteria_id, remark, team_lsq_id }) => {
  try {
    const res = await axiosInstance.post(APPROVE_CONFIGURATION, { criteria_id, remark, team_lsq_id });
    return { ...res };
  } catch (error) {
    return false;
  }
};

export const requestForRejectCriteria = async ({ criteria_id, remark, team_lsq_id }) => {
  try {
    const res = await axiosInstance.post(REJECT_CONFIGURATION, { criteria_id, remark, team_lsq_id });
    return { ...res };
  } catch (error) {
    return false;
  }
};

export const requestForMarkCriteriaActive = async ({ team_lsq_id, criteria_id }) => {
  try {
    const res = await axiosInstance.post(MARK_CRITERIA_ACTIVE, { team_lsq_id, criteria_id });
    return { ...res };
  } catch (error) {
    return error;
  }
};

export const requestForMarkCriteriaInActive = async ({ team_lsq_id, criteria_id }) => {
  try {
    const res = await axiosInstance.post(MARK_CRITERIA_INACTIVE, { team_lsq_id, criteria_id });
    return { ...res };
  } catch (error) {
    return error;
  }
};
