import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const ConfirmationModal = (props) => {
  const { isOpen, messageBody, handleConfirm, handleCancel } = props;
  const [modalOpen, setModalOpen] = useState(isOpen);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const onCancel = () => {
    setModalOpen(false);
    handleCancel();
  };

  const onConfirm = () => {
    handleConfirm();
  };
  return (
    <Modal isOpen={modalOpen} toggle={onCancel} backdrop>
      <ModalBody>{messageBody}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirm}>
          Yes
        </Button>
        <Button color="secondary" onClick={onCancel}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
