import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Label, Row, Spinner, Table } from "reactstrap";

import { requestForS3FileFromUrl } from "../../../api/api.common.request";
import PaginationComponent from "../../../components/dashboard/common/PaginationComponent";
import { formatNumberForIndia } from "../../../helpers/common";
import useToast from "../../../hooks/use-toast";
import {
  editExportExcelTokenList, editTokenListPreview,
  // exportExcelTokenList, generateTokenListPreview 
} from "../../../store/apps/token/TokenSlice";
import CheckboxComponent from "../token/CheckboxComponent";

const GenerateTokenScreen = ({ setModalTokenScreen, successToast, fromDate, toDate, affiliateId, affiliateinternalID, setUpdateTokenList }) => {
  const dispatch = useDispatch();
  const { errorToast } = useToast();
  const generateTokenList = useSelector((state) => state?.affiliateToken?.editTokenListPreviewData?.result);
  const isLoading = useSelector((state) => state?.affiliateToken?.loading);
  const total_records = useSelector((state) => state?.affiliateToken?.editTokenListPreviewData?.total_records);
  const exportExcelUrl = useSelector((state) => state?.affiliateToken?.exportEditExcelTokenListData);
  // exportEditExcelTokenListData
  const [shouldDownload, setShouldDownload] = useState(false);
  const [checkboxSelectAll, setCheckboxSelectAll] = useState(false);
  const [isLoader, setIsloader] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]); //generateTokenList?.map(item => item?.transaction_id
  const [unSelectedItems, setUnselectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastPage = total_records ? Math.ceil(total_records / recordsPerPage) : "";

  useEffect(() => {
    dispatch(
      editTokenListPreview({
        id: Number(affiliateId),
        skip: (currentPage - 1) * recordsPerPage,
        limit: recordsPerPage,
      })
    );
  }, [currentPage, checkboxSelectAll]);

  useEffect(() => {
    if (checkboxSelectAll) {
      setSelectedItems((oldItems) => {
        let newArray = generateTokenList.map((da) => da.id);
        let new_data = [...oldItems, ...newArray];
        new_data = new_data.filter((item) => !unSelectedItems.includes(item));
        return Array.from(new Set(new_data.map((obj) => obj)));
      });
    }
  }, [currentPage, checkboxSelectAll, generateTokenList]);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);

  const onSendForAffiliateVerification = () => {
    if (selectedItems?.length > 0) {
      dispatch(editExportExcelTokenList({
        selectAll: checkboxSelectAll,
        selectedArray: selectedItems,
        deselectedArray: unSelectedItems,
        token_no: affiliateId,
        is_excel: false,
      }))
        .then(() => {
          //  Add the new row to the tableData state
          // const updatedTableData = [...tableData, newRow];
          // setTableData(updatedTableData);
          successToast("Successfully generated a new token.");
          // setSaveGenerateToken(true);
          setModalTokenScreen(false);
          // setSelectedItems([]);
          setUpdateTokenList((updateTokenList) => !updateTokenList);
          setIsloader(false)
        })
        .catch((err) => {
          console.log("err:", err);
        });
    } else {
      errorToast("Select some row");
    }
  };
  // const onSendForAffiliateVerification = async () => {
  //   if (
  //     selectedItems?.length > 0
  //     // || checkboxSelectAll
  //   ) {
  //     setIsloader(true);
  //     await editExportExcelTokenList({
  //       // affiliate_id: Number(affiliateinternalID), //affiliateId,//1, //affiliateId
  //       // transactionIds: selectedItems, //["1123"], checkboxSelectAll === true ? [] :
  //       // is_excel: false,
  //       // is_select_all: checkboxSelectAll,
  //       // isNotSelectedIds: unSelectedItems,
  //       // from_date: fromDate,
  //       // till_date: toDate,

  //       selectAll: true,
  //       selectedArray: selectedItems,
  //       deselectedArray: [],
  //       token_no: { affiliateId },
  //       is_excel: "false"
  //     })
  //       .then(() => {
  //         //  Add the new row to the tableData state
  //         // const updatedTableData = [...tableData, newRow];
  //         // setTableData(updatedTableData);
  //         successToast("Successfully generated a new token.");
  //         // setSaveGenerateToken(true);
  //         setModalTokenScreen(false);
  //         // setSelectedItems([]);
  //         setUpdateTokenList((updateTokenList) => !updateTokenList);
  //         setIsloader(false)
  //       })
  //       .catch((err) => {
  //         console.log("err:", err);
  //       });
  //   } else {
  //     errorToast("Select some row");
  //   }
  //   // Add the new row to the tableData state
  //   // const updatedTableData = [...tableData, newRow];

  //   // setTableData(updatedTableData);
  //   // Save the updated tableData in localStorage
  //   // saveTableDataToLocalStorage(updatedTableData);
  // };

  useEffect(() => {
    const exportS3DataToExcel = async () => {
      let res = await requestForS3FileFromUrl({ url: exportExcelUrl });
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: "application/octet-stream" });
        const objectUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = objectUrl;
        downloadLink.download = `${exportExcelUrl.split('/')[2]}.csv`; // Specify the desired file name
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        errorToast("Something went wrong");
      }
    };
    if (shouldDownload === true) {
      exportS3DataToExcel().then(() => setShouldDownload(false));
    }
  }, [shouldDownload, exportExcelUrl]);

  const handleExportExcel = () => {
    // Dispatch the action to fetch data
    if (selectedItems && selectedItems?.length > 0) {
      dispatch(
        editExportExcelTokenList({
          selectAll: checkboxSelectAll,
          selectedArray: selectedItems,
          deselectedArray: unSelectedItems,
          token_no: affiliateId,
          is_excel: true,
        })
      ).then(() => {
        setShouldDownload(true);
        setSelectedItems([]);
        setCheckboxSelectAll(false);
      });
    } else {
      errorToast("Select a row to export.");
    }
  };
  // const handleExportExcel = () => {
  //   // Dispatch the action to fetch data
  //   if (selectedItems && selectedItems?.length > 0) {
  //     dispatch(
  //       exportExcelTokenList({
  //         affiliate_id: Number(affiliateinternalID), //affiliateId, //1
  //         transactionIds: selectedItems, //["1123"], checkboxSelectAll ? [] :
  //         is_excel: true,
  //         is_select_all: checkboxSelectAll,
  //         isNotSelectedIds: unSelectedItems,
  //         from_date: fromDate,
  //         till_date: toDate,
  //       })
  //     ).then(() => {
  //       setShouldDownload(true);
  //       setSelectedItems([]);
  //       setCheckboxSelectAll(false);
  //     });
  //   } else {
  //     errorToast("Select a row to export.");
  //   }
  // };

  const handleSelectAllToggle = (e) => {
    if (e.target.value == "false") {
      setSelectedItems([]);
      setUnselectedItems([]);
      setCheckboxSelectAll(false);
    } else {
      // setSelectedItems([]);
      // setUnselectedItems([])
      setCheckboxSelectAll(true);
    }
    // if (selectedItems?.length === generateTokenList?.length) {
    //   setSelectedItems([]);
    // } else {
    //   setSelectedItems(generateTokenList?.map((item) => item?.transaction_id));
    // }
  };
  // console.log(selectedItems,unSelectedItems,"items")
  const handleCheckboxChange = (e, transactionId) => {
    console.log("item :", transactionId, e.target.value, selectedItems, unSelectedItems);

    if (!e.target.value) {
      if (unSelectedItems.length > 0) {
        if (unSelectedItems.includes(transactionId)) {
          setSelectedItems((prev) => [...prev, transactionId]);
        }
        setUnselectedItems((prevUnSelectedItems) => {
          // console.log(prevUnSelectedItems,"items")
          if (prevUnSelectedItems?.includes(transactionId)) {
            return prevUnSelectedItems?.filter((id) => id !== transactionId);
          } else {
            return [...prevUnSelectedItems, transactionId];
          }
        });
      } else {
        setUnselectedItems([transactionId]);
      }
      // setCheckboxSelectAll(false)
    } else {
      if (selectedItems.length > 0) {
        if (!unSelectedItems.includes(transactionId)) {
          setUnselectedItems((prev) => [...prev, transactionId]);
        } else {
          setUnselectedItems((prev) => {
            const newArray = prev.filter((item) => item !== transactionId);
            return newArray;
          });
        }
        setSelectedItems((prevSelectedItems) => {
          // console.log(prevSelectedItems,"items")
          if (prevSelectedItems?.includes(transactionId)) {
            return prevSelectedItems?.filter((id) => id !== transactionId);
          } else {
            return [...prevSelectedItems, transactionId];
          }
        });
      } else {
        setSelectedItems([transactionId]);
      }
    }
  };

  //console.log("selectedItems :", selectedItems);

  const handleOnchangePagination = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  // console.log("====== checkboxSelectAll :", selectedItems, unSelectedItems);

  return (
    <>
      <Row className="mb-4">
        {/* <Col>
          <span className="fw-bold link-dark">Token ID :</span> {newTokenNumberId}
        </Col> */}
        <Col>
          <span className="fw-bold link-dark">Affiliate ID : </span>
          {affiliateId}
        </Col>
        <Col>
          <span className="fw-bold link-dark">From Date : </span>{" "}
          {/* {nextDay.toLocaleDateString() === "Invalid Date" ? "10/09/2023" : nextDay.toLocaleDateString()} */}
          {fromDate ? fromDate : "NA"}
        </Col>
        <Col>
          <span className="fw-bold link-dark">To Date: </span>
          {toDate ? toDate : "NA"}
        </Col>
      </Row>
      <div className="table-responsive table-container">
        <Table className="text-nowrap mb-2 align-middle" bordered>
          <thead className="sticky-top z-1">
            <tr>
              <th className="thcol1">
                <span style={{ marginRight: "5px" }} className="">
                  <Input
                    type="checkbox"
                    // checked={selectAll}
                    // onChange={handleSelectAllToggle}
                    onChange={(e) => {
                      handleSelectAllToggle(e);
                    }}
                    checked={checkboxSelectAll ? true : false}
                    value={checkboxSelectAll ? false : true}
                  // checked={selectedItems?.length == generateTokenList?.length}
                  />
                </span>
                Select All
              </th>
              <th>Serial Number</th>
              <th className="thcol2">Agent Name</th>
              <th>Session</th>
              <th>Company Name</th>
              <th>Provisional Enrollment Date </th>
              <th>Provisional Enrollment Number</th>
              <th>Enrollment Type Status</th>
              <th>Admission Date</th>
              <th>Mode</th>
              <th>Payment Mode</th>
              <th>Currency</th>
              <th>First Payment Date</th>
              <th>Last Payment Date</th>
              <th>Bank Ref / Transaction / Receipt No</th>
              <th>NetSuite Internal ID </th>
              <th>NetSuite Vendor id </th>
              <th>Form Number</th>
              <th>Enrollment Number</th>
              <th>Payment Type</th>
              <th>Student Name</th>
              <th>Program Name</th>
              <th>Category</th>
              <th>Last Transaction ID</th>
              <th>HSN/SAC</th>
              <th>Subvention % </th>
              <th>Subvention Share </th>
              <th>Amount Currency Wise </th>
              <th>Amount in INR </th>
              <th>No. of Transaction </th>
              <th>Semester </th>
              <th>Course Fee </th>
              <th>Project Fee </th>
              <th>Late Fee </th>
              <th>Other Fee </th>
              <th>Convenience Fee </th>
              <th>Sanction Amount </th>
              <th>Loan Tenure  </th>
              <th>Loan Lender Name/No Cost EMI  </th>
              <th>Total Deposited  </th>
              <th>Actual Course Fee for Commission Calculation  </th>
              <th>Commission % </th>
              <th>Commission Amount </th>
              <th>Status </th>

              {/* <th>Pay Mode</th>
              <th>Semester</th> */}
              {/* <th>Category Name</th> */}
              {/* <th>Payment Date</th>
              <th>Course Fee</th>
              <th>Total Deposited</th>
              <th>Actual Course Fee</th>
              <th>Subvention Cost</th>
              <th>Commission Amount</th> */}
            </tr>
          </thead>
          <tbody>{generateTokenList?.map((row, index) => {
            return (
              <tr key={index}>
                <td className="tdcol1">
                  {/* <Input type="checkbox" 
                    // checked={selectAll || selectedItems.includes(row.transaction_id)}
                    checked={selectedItems.includes(row.transaction_id)}
                    onChange={() => handleCheckboxChange(row.transaction_id)}
                    /> */}
                  <CheckboxComponent
                    id={row?.id}
                    onChange={handleCheckboxChange}
                    checked={
                      selectedItems?.includes(row?.id)
                        ? true
                        : !unSelectedItems?.includes(row?.id) && checkboxSelectAll
                          ? true
                          : false
                    }
                  />
                </td>
                <td>{row?.token_data?.serial_no ? row.serial_no : (currentPage - 1) * recordsPerPage + index + 1}</td>
                <td className="tdcol2 text-start">{row?.agent_name}</td>
                <td className="text-start">{row?.session_name}</td>
                <td className="text-start">{row?.legal_name}</td>
                <td className="text-start">{moment(row?.provisional_enrollment_date).format("DD-MM-YYYY")}</td>
                <td className="text-start">{row?.provisional_enrollment_no}</td>
                <td className="text-start">{row?.enrollment_status}</td>
                <td className="text-start">{moment(row?.final_enrollment_date).format("DD-MM-YYYY")}</td>
                <td className="text-start">{row?.university_name}</td>
                <td className="text-start">{row?.payment_mode}</td>
                <td className="text-start">{row?.currency}</td>
                <td className="text-start">{moment(row?.first_payment_date).format("DD-MM-YYYY")}</td>
                <td className="text-start">{moment(row?.last_payment_date).format("DD-MM-YYYY")}</td>
                <td className="text-start">{row?.transaction_pipe_list || "NA"}</td>
                <td className="text-start">{row?.NetSuite_Internal_ID || "NA"}</td>
                <td className="text-start">{row?.NetSuite_Vendor_id || "NA"}</td>
                <td className="text-start">{row?.form_id}</td>
                <td className="text-start">{row?.enrollment_id}</td>
                <td className="text-start">{row?.payment_type}</td>
                <td className="text-start">{row?.student_name}</td>
                <td className="text-start">{row?.program_name}</td>
                <td className="text-start">{row?.category}</td>
                <td className="text-start">{row?.last_trans_id || "NA"}</td>
                <td className="text-start">{row?.hsn || "NA"}</td>
                <td className="text-end">{row?.subvention_load_affiliate}</td>
                <td className="text-end">{formatNumberForIndia(row?.subvention_cost_affiliate_beared)}</td>
                <td className="text-start">{formatNumberForIndia(row?.amount_currency_wise) || "NA"}</td>
                <td className="text-end">{formatNumberForIndia(row?.total_deposited)}</td>
                <td className="text-end">{row?.no_of_trans || "NA"}</td>
                <td className="text-end">{row?.semester_no}</td>
                <td className="text-end">{formatNumberForIndia(row?.actual_course_fee)}</td>
                <td className="text-end">{formatNumberForIndia(row?.project) || "NA"}</td>
                <td className="text-end">{formatNumberForIndia(row?.late_fee) || "NA"}</td>
                <td className="text-end">{formatNumberForIndia(row?.other_fee) || "NA"}</td>
                <td className="text-end">{formatNumberForIndia(row?.convinence_fee) || "NA"}</td>
                <td className="text-end">{formatNumberForIndia(row?.loan_trans_amount || "NA")}</td>
                <td className="text-end">{row?.loan_tenure}</td>
                <td className="text-start">{row?.loan_partner_name}</td>
                <td className="text-end">{formatNumberForIndia(row?.total_deposited)}</td>
                <td className="text-end">{formatNumberForIndia(row?.total_course_fee)}</td>
                <td className="text-end">{row?.commission_perct}</td>
                <td className="text-end">{formatNumberForIndia(row?.commission_paid_to_affiliate)}</td>
                <td>{row?.status || "Pending"}</td>
                {/* className="tdcol3" */}
                {/* <td className="text-end">{row?.enrollment_id}</td>
                  <td className="text-start">{row?.provisional_enrollment_number ? row?.provisional_enrollment_number : "NA"}</td>
                  <td className="text-start">{row?.enrollment_type_status}</td>
                  <td className="text-start">{row?.admission_date}</td>
                  <td className="text-start">{row?.mode}</td>
                  <td className="text-start">{row?.category}</td>
                  <td className="text-start">{row?.currency}</td>
                  <td className="text-start">{row?.payment_type}</td>
                  <td className="text-start">{row?.payment_medium}</td>

                  <td>{row?.student_name}</td>
                  <td className="text-end">{row?.semester ? row?.semester : "NA"}</td>
                  <td className="text-start">{row?.program}</td>
                  {/* <td>{row?.category_name}</td>
                  <td className="text-start">{row?.transactions}</td>
                  <th className="text-start">{row?.payment_date}</th>
                  <td className="text-start">{row?.hsn}</td>
                  <td className="text-end">{formatNumberForIndia(row?.course_fee)}</td>
                  <td className="text-end">{formatNumberForIndia(row?.total_fee_deposited)}</td>
                  <td className="text-end">{formatNumberForIndia(row?.actual_course_fee)}</td>
                  <td className="text-end">{row?.commission_perct}</td>
                  <td className="text-end">{row?.subvention_perct}</td>
                  <td className="text-end">{formatNumberForIndia(row?.subvention_share)}</td>
                  <td className="text-end">{formatNumberForIndia(row?.commission_amount)}</td> */}
              </tr>
            );
          })}
          </tbody>
        </Table>
      </div>
      <div>
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center position-fixed top-0 start-0 w-100 h-100 bg-gray">
            <Spinner className="m-auto" />
          </div>
        )}
      </div>
      <div className="d-flex align-items-center  justify-content-start mt-3">
        <Row>
          <Col>
            <PaginationComponent currentPage={currentPage} totalPages={lastPage} onPageChange={handleOnchangePagination} />
          </Col>
        </Row>
        <Row className="mx-4">
          <Col>
            <Label>
              {total_records <= currentPage * recordsPerPage ? (
                <>{`Total ${total_records} out ${total_records}`}</>
              ) : (
                <>{`Total ${total_records >= 10 ? currentPage * recordsPerPage : total_records} out ${total_records}`}</>
              )}
            </Label>
          </Col>
        </Row>
      </div>

      <div className="d-flex align-items-center justify-content-end me-1 mt-2 ">
        <div className="ustify-content-end align-items-center">
          <Row>
            <Col >
              <Button color="primary" className="text-nowrap" onClick={onSendForAffiliateVerification} disabled={!generateTokenList?.length > 0 || isLoader === true}>
                Send For Finance Verification
              </Button>
              {isLoader && (
                // <div className="d-flex align-items-center justify-content-center position-fixed top-0 start-0 w-100 h-100 bg-gray">
                <div className="position-relative" style={{ left: "50%", bottom: "48%" }}>
                  <Spinner className="m-auto" />
                </div>
                // </div>
              )}
            </Col>
            <Col className="">
              <Button color="primary" onClick={handleExportExcel} disabled={!generateTokenList?.length > 0 || isLoader === true}>
                Export
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default GenerateTokenScreen;
