import React, { useState } from "react";
import { Button } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { AFMS_ROLE_MULTI_OPTIONS } from "./mockApi";

const AfmsUpdateRole = (props) => {
  const { onSubmitRoles } = props;
  const [selectedRole, setSelectedRoles] = useState([]);

  const onRemove = (value) => {
    setSelectedRoles(value);
  };
  const onSelect = (value) => {
    setSelectedRoles(value);
  };
  const handleClick = () => {
    if (selectedRole.length) {
      onSubmitRoles(selectedRole);
    }
  };
  return (
    <div className="d-flex align-items-center mt-4 mb-4 gap-3">
      <div className="w-25">
        <Multiselect
          displayValue="name"
          onRemove={onRemove}
          onSelect={onSelect}
          options={AFMS_ROLE_MULTI_OPTIONS}
          showCheckbox
        />
      </div>
      <Button className="btn" color="danger" onClick={handleClick}>
        Submit
      </Button>
    </div>
  );
};

export default AfmsUpdateRole;
