const AFMS_DATA = [
  {
    empId: "Univo01",
    department: "Sales",
    company: "Employee Profile",
    role: "Super Admin",
    manager: "Maneesh",
    profile: "Pawan",
  },
  {
    empId: "Univo02",
    department: "Account",
    company: "Employee Profile",
    role: "Account",
    manager: "Kundan",
    profile: "Rajesh",
  },
  {
    empId: "Univo03",
    department: "Sales",
    company: "Employee Profile",
    role: "Super Admin",
    manager: "Kundan",
    profile: "Rahul",
  },
  {
    empId: "Univo04",
    department: "Account",
    company: "Sales",
    role: "Super Admin",
    manager: "Maneesh",
    profile: "Aman",
  },
  {
    empId: "Univo05",
    department: "Technology",
    company: "Employee Profile",
    role: "Affiliate",
    manager: "Sandeep",
    profile: "Rak Kumar",
  },
  {
    empId: "Univo06",
    department: "Finance",
    company: "Employee Profile",
    role: "Super Admin",
    manager: "Maneesh",
    profile: "Hero",
  },
  {
    empId: "Univo07",
    department: "Sales",
    company: "Employee Profile",
    role: "Super Admin",
    manager: "Kundan",
    profile: "Jay",
  },
  {
    empId: "Univo08",
    department: "Account",
    company: "Employee Profile",
    role: "Sales",
    manager: "Maneesh",
    profile: "Shubham",
  },
  {
    empId: "Univo09",
    department: "Technology",
    company: "Employee Profile",
    role: "Affiliate",
    manager: "Ritesh",
    profile: "Aniket",
  },
  {
    empId: "Univo10",
    department: "Finance",
    company: "Employee Profile",
    role: "Affiliate",
    manager: "Suraj",
    profile: "Prashant",
  },
  {
    empId: "Univo11",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo12",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo13",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo14",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo15",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo16",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo17",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo18",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo19",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo20",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo21",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
  {
    empId: "Univo22",
    department: "Employee Department",
    company: "Employee Profile",
    role: "Assign Role",
    manager: "Reporting Manager",
    profile: "Employee Profile",
  },
];

const AFMS_ROLE_OPTIONS = ["Accounts", "Sales", "Affiliate", "Super Admin"];
const AFMS_ROLE_MULTI_OPTIONS = [
  {
    name: "Accounts",
    value: "Accounts",
  },
  {
    name: "Sales",
    value: "Sales",
  },
  {
    name: "Affiliate",
    value: "Affiliate",
  },
  {
    name: "Super Admin",
    value: "Super Admin",
  },
];

export { AFMS_DATA, AFMS_ROLE_OPTIONS, AFMS_ROLE_MULTI_OPTIONS };
