import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, Col, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";
import NoDataFound from "../../components/dashboard/common/NoDataFound";
import PaginationComponent from "../../components/dashboard/common/PaginationComponent";
import { reduxListAllDesignations, reduxSalesEmployeesList } from "../../store/apps/sales/SalesEmployeesSlice";
import SalesEmpDataFilter from "./SalesEmpDataFilter";
import { GET_ALL_SALES_EMPLOYEES_LIST } from "../../constants/end-points";
import axiosInstance from "../../config/axios/AxiosInstance";

const SalesEmpTable = () => {
  const employeesData = useSelector((state) => state?.salesDataReducer?.employeesList?.employeesList);
  const count = useSelector((state) => state?.salesDataReducer?.employeesList?.total_records);
  const { loading } = useSelector((state) => state.salesDataReducer);

  const designationList = useSelector((state) => state.salesDataReducer.designationList);
  const teamsList = useSelector((state) => state?.salesDataReducer?.teamsList);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");
  const [search, setSearch] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState([]);
  const dispatch = useDispatch();

  const team = teamsList.filter((team) => team.team === selectedTeam);

  const recordsPerPage = 10;
  const lastPage = count ? Math.ceil(count / recordsPerPage) : "";

  useEffect(() => {
    dispatchRequest();
  }, []);

  useEffect(() => {
    dispatchRequest();
    dispatch(reduxListAllDesignations());
  }, [search, currentPage]);

  const dispatchRequest = () => {
    dispatch(
      reduxSalesEmployeesList({
        status: employeeStatus,
        searchKey: searchInput,
        skip: (currentPage - 1) * recordsPerPage,
        limit: recordsPerPage,
        designation: selectedDesignation,
        team_name: selectedTeam,
        team_lsq_id: team?.[0]?.lsq_id,
      })
    );
  };

  const getSalesEmployeeRecords = async () => {
    try {
      const res = await axiosInstance.get(
        GET_ALL_SALES_EMPLOYEES_LIST({
          download: true,
          status: employeeStatus,
          searchKey: searchInput,
          designation: selectedDesignation,
          team_name: selectedTeam,
          team_lsq_id: team?.[0]?.lsq_id,
        })
      );
      const updatedData = res.data.result.map((item) => {
        const ATL_Email = item?.h?.find((d) => d.designation === "ATL")?.email || "NA";
        const TL_Email = item?.h?.find((d) => d.designation === "Assistant Manager")?.email || "NA";
        const Cluster_Head_Email_ID = item?.h.find((d) => d.designation === "Cluster Manager ")?.email || "NA";
        const AGM_Email = item?.h?.find((d) => d.designation === "AGM")?.email || "NA";
        const updatedH = item.h.map((employee) => {
          const updatedStatus = employee.status === 1 ? "Active" : "InActive";
          const updatedEmpId = employee.employee_id ? employee.employee_id : "NA";
          return { ...employee, status: updatedStatus, employee_id: updatedEmpId };
        });
        const incentiveStructure =
          item.incentive_structure == null
            ? "NA"
            : item.incentive_structure === 1
            ? "Regular-Config"
            : item?.incentive_structure === 2
            ? "Regular-Slab"
            : item?.incentive_structure === 3
            ? "Add-on"
            : "NA";

        return { ...item, h: updatedH, incentive_structure: incentiveStructure, ATL_Email, TL_Email, Cluster_Head_Email_ID, AGM_Email };
      });
      setDownloadCsv(updatedData);
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (count) {
      getSalesEmployeeRecords();
    }
  }, [count, search]);

  const handleSearch = () => {
    setSearch((search) => !search);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    setSelectedTeam("");
    setSelectedDesignation("");
    setSearch((search) => !search);
  };

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    setSearch((search) => !search);
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleApplyFilterBtn = () => {
    console.log("you clikced here...");
    setSearch((search) => !search);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setSelectedTeam("");
    setSelectedDesignation("");
    setSearch((search) => !search);
  };

  const handleStatusbtn = (val) => {
    setEmployeeStatus(val);
    setSearch((search) => !search);
    setCurrentPage(1);
  };

  const csvHeaders = [
    { label: "Email ID", key: "h[0].email" },
    { label: "Emp ID", key: "h[0].employee_id" },
    { label: "Team", key: "h[0].team" },
    { label: "Designation", key: "h[0].designation" },
    { label: "Status", key: "h[0].status" },
    { label: "DOJ", key: "date_of_joining" },
    { label: "ATL Email ID", key: "ATL_Email" },
    { label: "TL Email ID", key: "TL_Email" },
    { label: "Cluster Head Email ID", key: "Cluster_Head_Email_ID" },
    { label: "Sales Head Email ID", key: "AGM_Email" },
    { label: "Employee Category", key: "employee_category" },
    { label: "Incentive Type", key: "incentive_structure" },
  ];

  const firstCases = () => {
    return {
      1: "Regular-Config",
      2: "Regular-Slab",
      3: "Addon",
    };
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>&lt; Sales Employee Data</Col>
          </Row>
          <Row className="d-flex justify-content-end mt-5">
            <Col>
              <ButtonGroup>
                <Button color="primary" onClick={() => handleStatusbtn("")} active>
                  All
                </Button>
                <Button color="primary" onClick={() => handleStatusbtn("1")}>
                  Active
                </Button>
                <Button color="primary" onClick={() => handleStatusbtn("0")}>
                  Inactive
                </Button>
              </ButtonGroup>
            </Col>
            <Col xs={12} sm={8} md={4} lg={3}>
              <FormGroup>
                <Input type="text" placeholder="Search Email Id here.." value={searchInput} onChange={(e) => setSearchInput(e.target.value)}></Input>
              </FormGroup>
            </Col>
            <Col xs={12} sm={8} md={4} lg={2}>
              <FormGroup>
                <Button color="primary" onClick={handleSearch}>
                  Search
                </Button>
                <Button color="primary" className="mx-2" onClick={handleClearSearch}>
                  Clear
                </Button>
              </FormGroup>
            </Col>
            <Col lg={1}>
              <FormGroup>
                {downloadCsv?.length > 0 && (
                  <CSVLink data={downloadCsv} headers={csvHeaders}>
                    <Button className="text-nowrap" color="primary">
                      Download
                    </Button>
                  </CSVLink>
                )}
              </FormGroup>
            </Col>
            <Col lg={1}>
              <Icon.Filter size={40} color={"#F0AE27"} className="text-primary float-end" onClick={toggleSidePanel} />
            </Col>
          </Row>
          {loading ? (
            <Spinner className="m-auto align-middle d-flex justify-content-center" style={{ color: "#0583de" }} />
          ) : (
            <>
              <Row>
                <Col lg="12">
                  <div className="table-responsive table-container">
                    <Table className="text-nowrap mb-2 align-middle" bordered>
                      <thead className="sticky-top z-1">
                        <tr>
                          <th>Email ID</th>
                          <th>Emp ID</th>
                          {/* <th>Role</th> */}
                          <th>Team</th>
                          <th>Designation</th>
                          <th>Status</th>
                          <th>DOJ</th>
                          <th>ATL Email ID</th>
                          <th>TL Email ID</th>
                          <th>Cluster Head Email ID</th>
                          <th>Sales Head Email ID</th>
                          <th>Employee Category</th>
                          <th>Incentive Type </th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeesData?.length > 0 ? (
                          <>
                            {employeesData?.map((employee, index) => {
                              const { employee_id, team, email, designation, status } = employee?.h?.[0] ?? {};
                              return (
                                <tr key={index}>
                                  {/* <td>
                                <Link to={`/sis/sales-details/${id}`} className="me-4">
                                  {id}
                                </Link>
                              </td> */}
                                  <td>
                                    {email ? (
                                      <Link to={`/sis/sales-details/${email}`} className="me-4">
                                        {email}
                                      </Link>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td>{employee_id ? employee_id : "NA"}</td>
                                  <td>{team ? team : "NA"}</td>
                                  <td>{designation ? designation : "NA"}</td>
                                  <td className={`${status == "1" ? "text-success" : ""}  ${status == "0" ? "text-danger" : ""} `}>
                                    {status == "1" ? "Active" : "" || status == "0" ? "InActive" : ""}
                                  </td>
                                  <td>{employee.date_of_joining}</td>
                                  <td>{employee?.h?.find((data) => data.designation == "ATL")?.email || "NA"}</td>
                                  <td>{employee?.h?.find((data) => data.designation == "Assistant Manager")?.email || "NA"}</td>
                                  <td>{employee?.h?.find((data) => data.designation == "Cluster Manager")?.email || "NA"}</td>
                                  <td>{employee?.h?.find((data) => data.designation == "AGM")?.email || "NA"}</td>
                                  <td>{employee.employee_category ? employee.employee_category : "NA"}</td>
                                  <td>{employee?.incentive_structure ? firstCases()[employee?.incentive_structure] : "NA"}</td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={12}>
                              <NoDataFound />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="">
                    {count <= currentPage * recordsPerPage ? (
                      <Label>{`Total ${count} out ${count}`}</Label>
                    ) : (
                      <Label>{`Total ${count >= 10 ? currentPage * recordsPerPage : count} out ${count}`}</Label>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="d-flex align-items-end  justify-content-end">
                    <PaginationComponent currentPage={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col>
              <SalesEmpDataFilter
                isSidePanelOpen={isSidePanelOpen}
                setIsSidePanelOpen={setIsSidePanelOpen}
                toggleSidePanel={toggleSidePanel}
                selectedTeam={selectedTeam}
                setSelectedTeam={setSelectedTeam}
                selectedDesignation={selectedDesignation}
                setSelectedDesignation={setSelectedDesignation}
                handleApplyFilterBtn={handleApplyFilterBtn}
                handleClear={handleClear}
                designationList={designationList}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SalesEmpTable;
