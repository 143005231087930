import { createSlice } from "@reduxjs/toolkit";

import {
  requestForAllTeamsIncentiveCriteria,
  requestForCriteriaPreviewDetails,
  requestForEmployeeIncentiveDetails,
  requestForIncentivePlanByCriteriaId,
  requestForLatestDraftDetails,
  requestForListAllDesignations,
  requestForListAllTeams,
  requestForSalesEmployeesList,
  requestForSalesTransactionList,
} from "../../../api/api.sales.request";
import { actions } from "react-table";

const initialState = {
  employeesList: [],
  transactionList: [],
  loading: false,
  latestDraftDetails: [],
  previewData: [],
  designationList: [],
  teamsList: [],
  incentiveDetails: [],
  criteriaDetails: [],
  incentivePlanByCriteriaId: [],
};

export const SalesEmployeesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    startSalesLoading: (state) => {
      state.loading = true;
    },
    stopSalesLoading: (state) => {
      state.loading = false;
    },
    getAllSalesEmployeeExport: (state, action) => {
      // console.log(action.payload, "action");
      state.employeesList = action.payload;
    },
    getAllSalesTransctionExport: (state, action) => {
      state.transactionList = action.payload;
    },
    getLatestDraftDetailsExport: (state, action) => {
      state.latestDraftDetails = action.payload;
    },
    getPreviewDetailsExport: (state, action) => {
      state.previewData = action.payload;
    },
    getAllDesignationExport: (state, action) => {
      state.designationList = action.payload;
    },
    getAllTeamsExport: (state, action) => {
      state.teamsList = action.payload;
    },
    getAllSalesEmployeeIncenveDetailExport: (state, action) => {
      state.incentiveDetails = action.payload;
    },
    getAllTeamsIncentiveCriteriaExport: (state, action) => {
      state.criteriaDetails = action.payload;
    },
    getIncentivePlanByCriteriaIdExport: (state, action) => {
      state.incentivePlanByCriteriaId = action.payload;
    },
  },
});
export const {
  getAllSalesEmployeeExport,
  getAllSalesTransctionExport,
  startSalesLoading,
  stopSalesLoading,
  getLatestDraftDetailsExport,
  getPreviewDetailsExport,
  getAllDesignationExport,
  getAllTeamsExport,
  getAllSalesEmployeeIncenveDetailExport,
  getAllTeamsIncentiveCriteriaExport,
  getIncentivePlanByCriteriaIdExport,
} = SalesEmployeesSlice.actions;

export const reduxSalesEmployeesList =
  ({ status, searchKey, skip, limit, designation, team_name, team_lsq_id }) =>
  async (dispatch) => {
    try {
      dispatch(startSalesLoading());
      const response = await requestForSalesEmployeesList({ status, searchKey, skip, limit, designation, team_name, team_lsq_id });
      const result = await {
        employeesList: response.result,
        total_records: response.count,
      };
      // console.log(result);
      if (response) {
        // console.log("after response", response);
        dispatch(getAllSalesEmployeeExport(result));
        dispatch(stopSalesLoading());
      }
    } catch (err) {
      dispatch(stopSalesLoading());
      throw new Error(err);
    }
  };

export const reduxSalesTransctionList =
  ({ searchKey, from_date, to_date, limit, skip, designation, team_name, team_lsq_id }) =>
  async (dispatch) => {
    try {
      dispatch(startSalesLoading());
      const response = await requestForSalesTransactionList({ searchKey, from_date, to_date, limit, skip, designation, team_name, team_lsq_id });
      const result = {
        transactionList: response.getTransactionListForSales,
        total_records: response.count,
      };
      console.log(result);
      if (response) {
        // console.log("after response", response);
        dispatch(getAllSalesTransctionExport(result));
        dispatch(stopSalesLoading());
      }
    } catch (err) {
      dispatch(stopSalesLoading());
      throw new Error(err);
    }
  };

export const getAllTeamsIncentiveCriteria =
  ({ searchKey, skip, limit, incentive_status }) =>
  async (dispatch) => {
    try {
      dispatch(startSalesLoading());
      const res = await requestForAllTeamsIncentiveCriteria({ searchKey, skip, limit, incentive_status });
      const result = {
        incentive_structure: res.result,
        total_records: res.count,
      };
      if (res) {
        dispatch(getAllTeamsIncentiveCriteriaExport(result));
        dispatch(stopSalesLoading());
      }
    } catch (error) {
      dispatch(stopSalesLoading());
      throw new Error(error);
    }
  };

export const getIncentivePlanByCriteriaId =
  ({ criteria_id }) =>
  async (dispatch) => {
    try {
      const res = await requestForIncentivePlanByCriteriaId({ criteria_id });
      if (res) {
        dispatch(getIncentivePlanByCriteriaIdExport(res.result));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

export const reduxSalesEmployeeIncentiveDetails =
  ({ email }) =>
  async (dispatch) => {
    try {
      const response = await requestForEmployeeIncentiveDetails({ email });
      if (response) {
        dispatch(getAllSalesEmployeeIncenveDetailExport(response.result));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

export const reduxListAllDesignations = () => async (dispatch) => {
  try {
    const response = await requestForListAllDesignations();
    // console.log(response);
    if (response) {
      dispatch(getAllDesignationExport(response.result));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getLatestDraftDetails = () => async (dispatch) => {
  try {
    const response = await requestForLatestDraftDetails();
    if (response) {
      dispatch(getLatestDraftDetailsExport(response.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getIncentivePreviewDetails = () => async (dispatch) => {
  try {
    const response = await requestForCriteriaPreviewDetails();
    if (response) {
      dispatch(getPreviewDetailsExport(response.data));
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const reduxSalesTeamList = () => async (dispatch) => {
  try {
    const response = await requestForListAllTeams();
    if (response) {
      dispatch(getAllTeamsExport(response.result));
    }
  } catch (error) {
    throw new Error(error);
  }
};

export default SalesEmployeesSlice.reducer;
