import axiosInstance from "../config/axios/AxiosInstance";
import {
  // ADD_GENERATE_BRANCH,
  APPROVED_INVOICES_LIST,
  EXPORT_EXCEL_INVOICE_LIST,
  GET_ALL_BATCHES,
  GET_ALL_INVOICES_UNDER_BATCH,
  MARK_PAYMENT,
  UPLOAD_COVER_NOTE_DOCUMENT,
} from "../constants/end-points";

export const requestForApprovedInvoices = async ({ searchKey, fromdate, todate, status, skip, limit }) => {
  try {
    // console.log({ start_at, end_at });
    const res = await axiosInstance.get(APPROVED_INVOICES_LIST({ searchKey, fromdate, todate, status, skip, limit }));
    // console.log({ ...res.data }, res.data, "response_invoice");
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForBatchData = async ({ batchKey, fromdate, todate, skip, limit }) => {
  try {
    // console.log({  batchKey, fromdate, todate, skip, limit });
    const res = await axiosInstance.get(GET_ALL_BATCHES({ batchKey, fromdate, todate, skip, limit }));
    // console.log({ ...res.data }, "response_batch");
    return { ...res.data };
  } catch (err) {
    return false;
  }
};

export const requestForInvoicesByBatch = async ({ batch, skip, limit }) => {
  try {
    const res = await axiosInstance.post(GET_ALL_INVOICES_UNDER_BATCH({ skip, limit }), { batch: batch });
    // console.log({ ...res.data }, res.data, "response_invoice");
    return res.data;
  } catch (error) {
    return false;
  }
};

// export const requestForAddGenerateBatch = async ({ tokens }) => {
//   try {
//     const res = await axiosInstance.post(ADD_GENERATE_BRANCH, { tokens: tokens });
//     // console.log({ ...res.data }, res.data, "response_generate_batch");
//     return { ...res.data };
//   } catch (error) {
//     return false;
//   }
// };

export const requestForUploadCoverNoteDoc = async ({ batch_id, description, files }) => {
  try {
    const res = await axiosInstance.post(UPLOAD_COVER_NOTE_DOCUMENT, { batch_id: batch_id, description: description, files: files });
    console.log({ ...res.data }, "response_Upload_covernote");
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForMarkPayment = async ({ utr, remark, invoice_id, payment_date, files }) => {
  try {
    const res = await axiosInstance.post(MARK_PAYMENT, {
      utr: utr,
      remark: remark,
      invoice_id: invoice_id,
      payment_date: payment_date,
      files: files,
    });
    console.log({ ...res.data }, "response_Mark_Payment");
    return { ...res.data };
  } catch (error) {
    return false;
  }
};

export const requestForDownloadExcelnvoiceList = async ({ invoices }) => {
  try {
    const res = await axiosInstance.post(EXPORT_EXCEL_INVOICE_LIST, {
      invoices,
    });
    return { ...res.data };
  } catch (err) {
    return false;
  }
};
