import { configureStore } from "@reduxjs/toolkit";

import AffiliateSlice from "./apps/affiliate/AffiliateSlice";
import CoverNoteSlice from "./apps/coverNote/CoverNoteSlice";
import EnrollmentSlice from "./apps/enrollment/EnrollmentSlice";
import SalesEmployeesSlice from "./apps/sales/SalesEmployeesSlice";
import TokenSlice from "./apps/token/TokenSlice";
import UserReducer from "./apps/user/UserSlice";
import UserDashBoardSlice from "./apps/userDashboard/UserDashBoardSlice";
import CustomizerReducer from "./customizer/CustomizerSlice";

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    user: UserReducer,
    affiliateOnboard: AffiliateSlice,
    affiliateToken: TokenSlice,
    enrollmentReducer: EnrollmentSlice,
    dashboardReducer: UserDashBoardSlice,
    coverNoteReducer: CoverNoteSlice,
    salesDataReducer: SalesEmployeesSlice,
  },
});

export default store;
