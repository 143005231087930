import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";

import { requestForS3FileFromUrl } from "../../api/api.common.request";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = () => {
  const [pdfContent, setPdfContent] = useState(null);

  const [numPages, setNumPages] = useState();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const fullUrl = urlParams.get("url");
  const type = urlParams.get("type");

  const location = useLocation();
  const url = location?.state?.url || fullUrl;
  // || ;

  useEffect(() => {
    // fetch(url).then((r)=>{r.blob().then(d=>{
    //   var url = window.URL.createObjectURL(d);
    //   setPdfContent(url)
    // })})
    blobAnUrl(url);
  }, []);

  const blobAnUrl = async (url) => {
    try {
      // let res = await fetch(url) ;
      let res = await requestForS3FileFromUrl({ url: url });
      //  console.log(res.json())
      //  res = await res.blob()
      const blobUrl = await window.URL.createObjectURL(res.data);
      //console.log(res,blobUrl)
      setPdfContent(blobUrl);
    } catch (err) {
      console.log(err);
    }
  };

  if (!pdfContent) {
    return <div>Loading PDF...</div>;
  }

  return (
    <div>
      {/* <h1>Custom PDF Viewer</h1> */}
      <Document file={pdfContent} onLoadSuccess={onDocumentLoadSuccess} >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} size="A4" devicePixelRatio={10} />
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
