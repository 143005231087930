import { createSlice } from "@reduxjs/toolkit";

import { requestForAffiliateTokenList } from "../../../api/api.affiliate.request";
import { requestForAllAffiliateTokenList } from "../../../api/api.allToken.request";
import {
  requestForApprovedOrRejectedInvoiceByFinance,
  requestForDownloadEditExcelTokenList,
  requestForDownloadExcelTokenList,
  requestForDownloadOrUploadTokenByAffiliate,
  requestForEditTokenPreviewList,
  requestForGenerateTokenPreviewList,
} from "../../../api/api.token.request";

const initialState = {
  tokenList: [],
  generateTokenListPreviewData: [],
  editTokenListPreviewData: [],
  exportExcelTokenListData: [],
  exportEditExcelTokenListData: [],
  downloadOrUploadTokenOrInvoiceData: [],
  approveOrRejectInvoiceByFinanceStatus: [],
  loading: false,
};
export const TokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    startEnrollmentLoading: (state) => {
      state.loading = true;
    },
    stopEnrollmentLoading: (state) => {
      state.loading = false;
    },
    getAllTokenExport: (state, action) => {
      state.tokenList = action.payload;
    },
    generateTokenList: (state, action) => {
      state.generateTokenListPreviewData = action.payload;
    },
    editTokenList: (state, action) => {
      state.editTokenListPreviewData = action.payload;
    },
    exportExcelList: (state, action) => {
      state.exportExcelTokenListData = action.payload;
    },
    exportEditExcelList: (state, action) => {
      state.exportEditExcelTokenListData = action.payload;
    },
    downloadUploadTokenInvoiceByAffiliateList: (state, action) => {
      state.downloadOrUploadTokenOrInvoiceData = action.payload;
    },
    approveOrRejectInvoiceByFinanceList: (state, action) => {
      state.approveOrRejectInvoiceByFinanceStatus = action.payload;
    },
  }
});
export const {
  startEnrollmentLoading,
  stopEnrollmentLoading,
  getAllTokenExport,
  generateTokenList,
  editTokenList,
  exportExcelList,
  exportEditExcelList,
  downloadUploadTokenInvoiceByAffiliateList,
  approveOrRejectInvoiceByFinanceList,
} = TokenSlice.actions;

export const reduxAffiliateTokenList =
  ({ affiliate_id, token_status, skip, limit }) =>
    async (dispatch) => {
      try {
        const response = await requestForAffiliateTokenList({ affiliate_id, token_status, skip, limit });
        const result = await {
          result: response.result,
          total_records: response.total_records,
          commission_structure_type: response.commission_structure_type,
          is_generate_token: response.is_generate_token
        };
        if (response) {
          dispatch(getAllTokenExport(result));
        }
      } catch (err) {
        throw new Error(err);
      }
    };
export const reduxAllAffiliateTokenList =
  ({ from_date, to_date, university, status, search_key, skip, limit, affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(startEnrollmentLoading());
        const response = await requestForAllAffiliateTokenList({ from_date, to_date, university, status, search_key, skip, limit, affiliate_id });
        const result = await {
          result: response.result,
          total_records: response.count,
          all_status_count: response.all_status_count,
          // is_generate_token: response.is_generate_token
        };
        if (response) {
          dispatch(getAllTokenExport(result));
          dispatch(stopEnrollmentLoading());
        }
      } catch (err) {
        dispatch(stopEnrollmentLoading());
        throw new Error(err);
      }
    };

export const generateTokenListPreview =
  ({ affiliate_id, from_date, till_date, skip, limit }) =>
    async (dispatch) => {
      try {
        dispatch(startEnrollmentLoading());
        const response = await requestForGenerateTokenPreviewList({ affiliate_id, from_date, till_date, skip, limit });
        const result = {
          result: response.result,
          total_records: response.total_records,
        };

        // console.log("result  :===", result);
        if (response) {
          dispatch(generateTokenList(result));
          dispatch(stopEnrollmentLoading());
        }
      } catch (err) {
        dispatch(stopEnrollmentLoading());
        throw new Error(err);
      }
    };

export const editTokenListPreview =
  ({ id, skip, limit }) =>
    async (dispatch) => {
      try {
        dispatch(startEnrollmentLoading());
        const response = await requestForEditTokenPreviewList({ id, skip, limit });
        const result = {
          result: response.studentsUnderTheToken,
          total_records: response.count,
        };

        // console.log("result  :===", result);
        if (response) {
          dispatch(editTokenList(result));
          dispatch(stopEnrollmentLoading());
        }
      } catch (err) {
        dispatch(stopEnrollmentLoading());
        throw new Error(err);
      }
    };

export const exportExcelTokenList = ({ affiliate_id, transactionIds, is_excel, is_select_all, from_date, till_date, isNotSelectedIds }) => async (dispatch) => {
  try {
    const res = await requestForDownloadExcelTokenList({ affiliate_id, transactionIds, is_excel, is_select_all, from_date, till_date, isNotSelectedIds });
    if (res) {
      dispatch(exportExcelList(res.result));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const editExportExcelTokenList = ({ selectAll, selectedArray, deselectedArray, token_no, is_excel }) => async (dispatch) => {
  try {
    const res = await requestForDownloadEditExcelTokenList({ selectAll, selectedArray, deselectedArray, token_no, is_excel });
    if (res) {
      dispatch(exportEditExcelList(res.link));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const downloadUploadTokenInvoiceByAffiliate = ({ invoice_no, invoice_date, is_upload, token_no, img, status, affiliate_id, }) => async (dispatch) => {
  try {
    const res = await requestForDownloadOrUploadTokenByAffiliate({ invoice_no, invoice_date, is_upload, token_no, img, status, affiliate_id });

    console.log("response token or invoive download/upload by upload:", res);
    if (res) {
      dispatch(downloadUploadTokenInvoiceByAffiliateList(res.result));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const ApprovedRejectedInvoiceByFinance =
  ({ token_no, new_status, remarks }) =>
    async (dispatch) => {
      try {
        const res = await requestForApprovedOrRejectedInvoiceByFinance({ token_no, new_status, remarks });
        if (res) {
          dispatch(approveOrRejectInvoiceByFinanceList(res.result));
        }
      } catch (err) {
        throw new Error(err);
      }
    };
export default TokenSlice.reducer;
