import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const renderPaginationItems = () => {
    const items = [];
    const numDisplayPages = 2; // Number of page numbers to display around the current page
    const startPage = Math.max(1, currentPage - numDisplayPages);
    const endPage = Math.min(totalPages, currentPage + numDisplayPages);

    // Render Previous Button
    if (currentPage > 1) {
      items.push(
        <PaginationItem key="prev">
          <PaginationLink previous onClick={(e) => onPageChange(e, currentPage - 1)} />
        </PaginationItem>
      );
    }

    // Render Page Numbers
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={(e) => onPageChange(e, i)}>{i}</PaginationLink>
        </PaginationItem>
      );
    }

    // Render Next Button
    if (currentPage < totalPages) {
      items.push(
        <PaginationItem key="next">
          <PaginationLink next onClick={(e) => onPageChange(e, currentPage + 1)} />
        </PaginationItem>
      );
    }

    return items;
  };

  // if (totalPages <= 1) {
  //   return null;
  // }

  return <Pagination aria-label="Page navigation">{renderPaginationItems()}</Pagination>;
};

export default PaginationComponent;
