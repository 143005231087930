import { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Col, Row } from "reactstrap";
import nodataimg from "../../../../assets/images/background/no-data.png";

const AFMSDataGrid = (props) => {
  const { items, filterOptions, isSearch, onRowSelected } = props;
  const [selectedRow, setSelectedRow] = useState([]);

  const selectRowProp = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        const arr = [...selectedRow, row];
        setSelectedRow(arr);
        onRowSelected(arr);
        return;
      }
      const arr = selectedRow.filter((el) => el.empId !== row.empId);
      setSelectedRow(arr);
      onRowSelected(arr);
    },
    onSelectAll: (isSelect) => {
      if (isSelect) {
        setSelectedRow(items);
        onRowSelected(items);
        return items.map((row) => row.empId);
      }
      setSelectedRow([]);
      onRowSelected([]);
      return [];
    },
  };

  const renderData = () => {
    if (isSearch) {
      let filterData = items;
      if (filterOptions.empId) {
        filterData = filterData.filter(
          (item) =>
            item.empId
              .toLowerCase()
              .indexOf(filterOptions.empId.toLowerCase()) >= 0
        );
      }
      if (filterOptions.department) {
        filterData = filterData.filter(
          (item) =>
            item.department
              .toLowerCase()
              .indexOf(filterOptions.department.toLowerCase()) >= 0
        );
      }
      if (filterOptions.profile) {
        filterData = filterData.filter(
          (item) =>
            item.profile
              .toLowerCase()
              .indexOf(filterOptions.profile.toLowerCase()) >= 0
        );
      }
      if (filterOptions.manager) {
        filterData = filterData.filter(
          (item) =>
            item.manager
              .toLowerCase()
              .indexOf(filterOptions.manager.toLowerCase()) >= 0
        );
      }
      if (filterData.length) {
        return filterData;
      }
      return items;
    }
    return items;
  };

  const options = {
    noDataText:
      <div className="py-2">
        <div className="wrapper">
          <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
            <img
              src={nodataimg}
              alt="No Data Found"
              height={100}
            />
          </div>
          <p className="m-0">No Data Found</p>
        </div>
      </div>
  };

  const dataToRender = renderData();
  return (
    <>
      <Row>
        <Col md="12">
          {dataToRender && (
            <BootstrapTable
              hover
              condensed
              data={dataToRender}
              pagination
              selectRow={selectRowProp}
              tableHeaderClass="mb-0"
              bordered={false}
              options={options}
            >
              <TableHeaderColumn width="100" dataField="empId" isKey>
                Employee ID
              </TableHeaderColumn>
              <TableHeaderColumn width="100" dataField="profile">
                Employee Name
              </TableHeaderColumn>
              <TableHeaderColumn width="100" dataField="department">
                Employee Department
              </TableHeaderColumn>
              <TableHeaderColumn width="100" dataField="role">
                Assigned Role
              </TableHeaderColumn>
              <TableHeaderColumn width="100" dataField="manager">
                Reporting Manager
              </TableHeaderColumn>
            </BootstrapTable>
          )}
        </Col>
      </Row>
    </>
  );
};
export default AFMSDataGrid;
