import { Col, Row, Table } from "reactstrap";
import NoDataFound from "../../components/dashboard/common/NoDataFound";

const EmployeeIncentiveStructure = ({
  capping,
  category_target_mapping,
  monthly_multiplier,
  payout_mapping,
  semester_multiplier,
  teams_target,
  incentive_structure,
  addon_target,
}) => {
  return (
    <>
      {category_target_mapping?.length > 0 ||
      monthly_multiplier?.length > 0 ||
      payout_mapping?.length > 0 ||
      semester_multiplier?.length > 0 ||
      teams_target?.length > 0 ||
      incentive_structure?.length > 0 ||
      addon_target?.length > 0 ? (
        <>
          <Row>
            <Col sm="12">
              <Row className="mt-4 ms-0">
                {incentive_structure?.length > 0 && (
                  <Table className="table-responsive table-container" bordered>
                    <thead>
                      <tr>
                        <th rowSpan={2}>Category</th>
                        <th colSpan={4}>Weekly Target</th>
                        <th rowSpan={2}>Monthly Target</th>
                        <th rowSpan={2}>Pay Per Sale on Weekly terget</th>
                      </tr>
                      <tr>
                        <td style={{ backgroundColor: "#dae7f1" }} className="text-center border border-secondary p-2">
                          Week 1
                        </td>
                        <td style={{ backgroundColor: "#dae7f1" }} className="text-center border border-secondary p-2">
                          Week 2
                        </td>
                        <td style={{ backgroundColor: "#dae7f1" }} className="text-center border border-secondary p-2">
                          Week 3
                        </td>
                        <td style={{ backgroundColor: "#dae7f1" }} className="text-center border border-secondary p-2">
                          Week 4
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {incentive_structure?.map((data, index) => (
                        <tr key={index}>
                          <td className="text-start">{data?.catagory}</td>
                          <td className="text-end">{data?.week_one}</td>
                          <td className="text-end">{data?.week_two}</td>
                          <td className="text-end">{data?.week_three}</td>
                          <td className="text-end">{data?.week_four}</td>
                          <td className="text-end">{data?.monthly_goal}</td>
                          <td className="text-end">{data?.pay_per_sale}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            {category_target_mapping?.length > 0 && (
              <Col lg={6}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        Category Target Mapping
                      </th>
                    </tr>
                    <tr>
                      <th>Category</th>
                      <th>Targets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category_target_mapping?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.category}</td>
                        <td>{data?.monthly_goal}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
            {payout_mapping?.length > 0 && (
              <Col lg={6} className="m-auto">
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        Payout Mapping
                      </th>
                    </tr>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Payout Per Sale</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payout_mapping?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.from}</td>
                        <td>{data?.to}</td>
                        <td>{data?.payout_per_sale}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
            {addon_target?.length > 0 && (
              <Col lg={6} className="m-auto">
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        Payout Mapping
                      </th>
                    </tr>
                    <tr>
                      <th>From</th>
                      <th>To</th>
                      <th>Payout Per Sale</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addon_target?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.from}</td>
                        <td>{data?.to}</td>
                        <td>{data?.pay_per_Sale}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
          {monthly_multiplier?.length > 0 && (
            <Row className="mt-5">
              <Col lg={6}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        Monthly Multiplier
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Achievement in %</th>
                      <th>Multiplier in %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monthly_multiplier?.length > 0 ? (
                      <>
                        {monthly_multiplier?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.from}</td>
                            <td>{data?.to}</td>
                            <td>{data?.multiplier}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>
                            <NoDataFound />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col lg={6}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        Semester Multiplier
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Achievement in %</th>
                      <th>Multiplier in %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {semester_multiplier?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.from}</td>
                        <td>{data?.to}</td>
                        <td>{data?.multiplier}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          {teams_target?.length > 0 && (
            <Row className="mt-5">
              <Col lg={6}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        TEAMS TARGET
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2}>Achievement in %</th>
                      <th>Multiplier in %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teams_target?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.from}</td>
                        <td>{data?.to}</td>
                        <td>{data?.multiplier}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
              <Col lg={6}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th colSpan={3} className="bg-primary text-white text-center">
                        Capping
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">{capping}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};
export default EmployeeIncentiveStructure;
