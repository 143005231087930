export const getCookie = (name) => {
  const cookieValue = window?.document?.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith(name))
    ?.split('=')[1];

  return cookieValue ? decodeURIComponent(cookieValue) : null;
};

export const deleteAllCookies = () => {
  const cookies = window?.document?.cookie?.split('; ');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Set the expiration date to the past to delete the cookie
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}
