import { createSlice } from "@reduxjs/toolkit";

import {
  requestForAffiliateAdditionalAttachmentList,
  requestForAffiliateBasicDetails,
  requestForAffiliateBranchList,
  requestForAffiliateKycList,
  requestForCommissionParams,
  requestForGeneartedLinks,
  requestForGetAffiliateCommssionConfig,
  requestForGetOnlyAffiliateCommssionConfig,
  getStudentDashboardCount,
  getSessionIds,
  getDashboardTransactionList,
  getDashboardTokenList,
  getDashboardPaymentList,
} from "../../../api/api.affiliate.request";
import { getAffiliateListApi, getAffiliateOwnerListApi } from "../../../api/api.auth.request";
import StatusConstant from "../../../constants/StatusConstant";

const initialState = {
  selectedAffiliate: "",
  affiliateDetails: {},
  affiliateBasicDetails: {},
  affiliateList: [],
  affiliateOwnerList: [],
  affiliatebranchList: [],
  affiliateKycList: [],
  additionalAttachment: [],
  commissionParams: [],
  affilaiteGeneratedLinks: [],
  getStudentCount: [],
  loading: false,
  commissionConfigurationData: {},
  sessionId: [],
  tranctionDataList: [],
  dashboardTokenList: [],
  dashboardPaymentList: [],
};

const AffiliatesSlice = createSlice({
  name: "affiliates",
  initialState,
  reducers: {
    affiliatesLoading: (state) => {
      state.loading = true;
    },
    affiliatesStopLoading: (state) => {
      state.loading = false;
    },
    recievedAffiliateList: (state, action) => {
      state.affiliateList = action.payload;
    },
    affiliateOwnerList: (state, action) => {
      state.affiliateOwnerList = action.payload;
    },
    selectAffilate: (state, action) => {
      state.selectedAffiliate = action.payload;
    },
    addAffiliate: (state, action) => {
      state = {
        // affiliateDetails: {
        //   ...state.affiliateDetails,
        //   ...action.payload
        // },
        affiliateList: state?.affiliateList?.push({
          ...state.affiliateDetails,
          ...action.payload,
          basicDetailsComplete: true,
          brachAndDetailsComplete: false,
          commissionComplete: false,
          registrationComplete: false,
          branchAndKyc: {
            branchDetails: [],
            additionalDetails: [],
            kycDetails: [
              {
                id: 0,
                name: action.payload.name,
                branch: 1,
                idType: "MOU",
                idNumber: "",
                idHolderName: "",
                upload: "",
                viewID: "",
                startDate: "",
                endDate: "",
              },
              {
                id: 0,
                name: action.payload.name,
                branch: 1,
                idType: "ESIC",
                idNumber: "",
                idHolderName: "",
                upload: "",
                viewID: "",
                startDate: "",
                endDate: "",
              },
              {
                id: 0,
                name: action.payload.name,
                branch: 1,
                idType: "EPF",
                idNumber: "",
                idHolderName: "",
                upload: "",
                viewID: "",
                startDate: "",
                endDate: "",
              },
            ],
          },
          commisstionStructure: [],
        }),
      };
    },
    updateBasicDetails: (state, action) => {
      state.affiliateList = state?.affiliateList?.map((item) => {
        if (item.id === action.payload.id) {
          item = {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
    },
    reciveBranchAndKycDetails: (state, action) => {
      const { type, value, selectedAffiliateId } = action.payload;
      if (state?.affiliateList && Array.isArray(state.affiliateList)) {
        state.affiliateList.map((el) => {
          if (el.id === selectedAffiliateId) {
            el = {
              ...el,
              branchAndKyc: {
                ...el.branchAndKyc,
                [type]: value,
              },
            };
          }
          return el;
        });
      }
    },
    updateBranchAndKycStatus: (state, action) => {
      if (state?.affiliateList && Array.isArray(state.affiliateList)) {
        state.affiliateList.map((el) => {
          // state.affiliateList = state?.affiliateList?.map((el) => {
          if (el.id === action.payload.id) {
            el = {
              ...el,
              brachAndDetailsComplete: true,
              // status: "Sent for Commission Configuration",
              status: StatusConstant.pendingForFinanceVerification,
            };
          }
          return el;
        });
      }
    },
    recieveCommissionStructure: (state, action) => {
      if (state?.affiliateList && Array.isArray(state.affiliateList)) {
        state.affiliateList.map((el) => {
          if (el.id === action.payload.id) {
            el = {
              ...el,
              commissionStructure: action.payload.data,
              status: action.payload.data.some((el) => el?.status === "Approved")
                ? "Approved"
                : action.payload.data.some((ol) => ol?.status === "Rejected")
                  ? "Rejected"
                  : el?.status,
            };
          }
          return el;
        });
      }
    },
    // updateCommissionStatus: (state, action) => {
    //   state.commissionConfigurationData = action.payload;
    // },
    setCommissionVerification: (state, action) => {
      state.affiliateList = state?.affiliateList?.map((el) => {
        if (el.id === action.payload.id) {
          el = {
            ...el,
            commissionComplete: true,
            // status: "Sent for Commission Verification",
            status: StatusConstant.pendingForFinanceApproval,
          };
        }
        return el;
      });
    },
    addAffiliateBasicDetails: (state, action) => {
      state.affiliateBasicDetails = action.payload;
    },
    addBranchList: (state, action) => {
      state.affiliatebranchList = action.payload;
    },
    addKycList: (state, action) => {
      state.affiliateKycList = action.payload;
    },
    addAdditionalAttachment: (state, action) => {
      state.additionalAttachment = action.payload;
    },
    addCommissionConfigParams: (state, action) => {
      state.commissionParams = action.payload;
    },
    addAffiliateGeneratedLinks: (state, action) => {
      state.affilaiteGeneratedLinks = action.payload;
    },
    addCommissionConfig: (state, action) => {
      state.commissionConfigurationData = action.payload;
    },
    addStudentDashboardCount: (state, action) => {
      state.getStudentCount = action.payload;
    },
    addSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    addTransactionDataExport: (state, action) => {
      state.tranctionDataList = action.payload;
    },
    addTokenDataExport: (state, action) => {
      state.dashboardTokenList = action.payload;
    },
    addPaymentDataExport: (state, action) => {
      state.dashboardPaymentList = action.payload;
    },
  },
});

export const {
  affiliateOwnerList,
  recievedAffiliateList,
  selectAffilate,
  addAffiliate,
  updateBasicDetails,
  reciveBranchAndKycDetails,
  updateBranchAndKycStatus,
  recieveCommissionStructure,
  setCommissionVerification,
  addAffiliateBasicDetails,
  addBranchList,
  addKycList,
  addAdditionalAttachment,
  addCommissionConfigParams,
  addAffiliateGeneratedLinks,
  addCommissionConfig,
  addStudentDashboardCount,
  affiliatesLoading,
  affiliatesStopLoading,
  addSessionId,
  addTransactionDataExport,
  addTokenDataExport,
  addPaymentDataExport,
  // updateCommissionStatus
} = AffiliatesSlice.actions;

export const getAffiliateTable =
  ({ status, skip, limit, search_key }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await getAffiliateListApi({ status, skip, limit, search_key });
        console.log({ status, skip, limit, search_key }, "skip limit");
        // console.log("getAffiliate", response);
        if (response) {
          dispatch(recievedAffiliateList(response.result));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const getAffiliateOwnerList = () => async (dispatch) => {
  try {
    dispatch(affiliatesLoading());
    const response = await getAffiliateOwnerListApi();
    if (response) {
      dispatch(affiliateOwnerList(response.result));
      dispatch(affiliatesStopLoading());
    }
  } catch (err) {
    dispatch(affiliatesStopLoading());
    throw new Error(err);
  }
};

export const reduxGetAffiliateBasicDetails =
  ({ affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await requestForAffiliateBasicDetails({ affiliate_id });
        if (response) {
          dispatch(addAffiliateBasicDetails(response.data));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxgetBranchList =
  ({ affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await requestForAffiliateBranchList({ affiliate_id });
        if (response) {
          dispatch(addBranchList(response.data));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

//requestForAffiliateAffiliateList

export const reduxgetAffiliateKycList =
  ({ affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await requestForAffiliateKycList({ affiliate_id });
        if (response) {
          console.log(response.attachment);
          dispatch(addKycList(response.attachment));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxgetAffiliateAdditionalAttachmentList =
  ({ affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await requestForAffiliateAdditionalAttachmentList({ affiliate_id });
        if (response) {
          console.log(response.attachment);
          dispatch(addAdditionalAttachment(response.attachment));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxgetCommissionParams =
  ({ affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await requestForCommissionParams({ affiliate_id });
        if (response) {
          console.log(response.data);
          dispatch(addCommissionConfigParams(response.data));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxgetAffilategeneratedLinks =
  ({ affiliate_id }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await requestForGeneartedLinks({ affiliate_id });
        if (response) {
          console.log(response.data);
          dispatch(addAffiliateGeneratedLinks(response.data));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxGetAffiliateCommssionConfig =
  ({ affiliate_id, role }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        let response;
        if (role == "affiliate") {
          ///new request
          response = await requestForGetOnlyAffiliateCommssionConfig({ affiliate_id });
        } else {
          response = await requestForGetAffiliateCommssionConfig({ affiliate_id });
        }

        if (response) {
          dispatch(addCommissionConfig(response?.data[0]?.commission_configuration_data));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxGetStudentDashboardCount =
  ({ session }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await getStudentDashboardCount({ session });
        if (response) {
          console.log(response.result);
          dispatch(addStudentDashboardCount(response.result));
          dispatch(affiliatesStopLoading());
        }
      } catch (err) {
        dispatch(affiliatesStopLoading());
        throw new Error(err);
      }
    };

export const reduxGetSessionIds = () => async (dispatch) => {
  try {
    dispatch(affiliatesLoading());
    const response = await getSessionIds();
    if (response) {
      console.log(response?.result);
      dispatch(addSessionId(response?.result));
      dispatch(affiliatesStopLoading());
    }
  } catch (err) {
    dispatch(affiliatesStopLoading());
    throw new Error(err);
  }
};

export const getDashboardTransactionData =
  ({ step, admission_session, search_key, skip, limit }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const response = await getDashboardTransactionList({ step, admission_session, skip, limit, search_key });
        if (response) {
          dispatch(addTransactionDataExport(response.result));
          dispatch(affiliatesStopLoading());
        }
      } catch (error) {
        dispatch(affiliatesStopLoading());
        throw new Error(error);
      }
    };

export const getDashboardTokenDetails =
  ({ token_status, dashboard_token_status, session, skip, limit, search_key }) =>
    async (dispatch) => {
      try {
        const response = await getDashboardTokenList({ token_status, dashboard_token_status, session, skip, limit, search_key });
        if (response) {
          dispatch(addTokenDataExport(response.result));
        }
      } catch (error) {
        throw new Error(error);
      }
    };

export const getDashboardPaymentData =
  ({ admission_session, search_key, skip, limit }) =>
    async (dispatch) => {
      try {
        dispatch(affiliatesLoading());
        const res = await getDashboardPaymentList({ admission_session, search_key, skip, limit });
        if (res) {
          dispatch(addPaymentDataExport(res.result));
          dispatch(affiliatesStopLoading());
        }
      } catch (error) {
        dispatch(affiliatesStopLoading());
        throw new Error(error);
      }
    };

export default AffiliatesSlice.reducer;
