export const __ENV__ = "prod";

let URL;

if (__ENV__ === "local") {
  URL = "http://localhost:8000/api/";
}

if (__ENV__ === "dev") {
  URL = "http://52.66.99.239/api/";
  // URL = "http://52.66.99.239/api/";
}

if (__ENV__ === "staging") {
  URL = "http://13.200.128.192:8000/api/";
}
if (__ENV__ === "prod") {
  URL = "https://area42.univoedtech.com/api/";
}

export const BASE_URL = URL;
